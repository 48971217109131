import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { usePermission } from '@backstage/plugin-permission-react';
import { msgraphGroupsReadAllPermission } from '@internal/plugin-permissions-common';
import { provisioningRegistryApiRef } from '@provisioning/react';
import React from 'react';
import { AADSearch } from './AADSearch';
import { AADSearchMultiple } from './AADSearchMultiple';
import { getUserId } from './helpers';
import { MsGraphFormData } from './types';

export const AzureGroupSearch = ({
  title,
  onChange,
  required,
  formData,
  multiple = false,
}: {
  title: string;
  onChange: Function;
  required: boolean;
  formData: MsGraphFormData | MsGraphFormData[] | undefined;
  multiple?: boolean;
}) => {
  const provisioningApi = useApi(provisioningRegistryApiRef);
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  const { allowed: canReadAllGroups } = usePermission({
    permission: msgraphGroupsReadAllPermission,
  });

  const filter = `not(startsWith(displayName, 'argus-ws') or startsWith(displayName, 'argus-product')
                  or startsWith(displayName, 'argus-global') or startsWith(displayName, 'AdminIT')
                  or endsWith(mail, '@BASF.onmicrosoft.com'))`;

  const searchParam = (input: string | undefined) =>
    input
      ? {
          search: `"displayName:${input}"`,
        }
      : {};

  const fetchData = async (input: string | undefined) => {
    const params = searchParam(input);
    if (canReadAllGroups) {
      return provisioningApi.getMsgraphGroups({
        ...params,
        filter,
      });
    }

    const userId = await getUserId(catalogApi, identityApi);
    return provisioningApi.getTransitiveGroupMemberships({
      userId,
      query: {
        ...params,
        filter,
      },
    });
  };

  return multiple ? (
    <AADSearchMultiple
      fetchData={fetchData}
      title={title}
      onChange={onChange}
      required={required}
      formData={formData as MsGraphFormData[]}
      showDescription
    />
  ) : (
    <AADSearch
      fetchData={fetchData}
      title={title}
      onChange={onChange}
      required={required}
      formData={formData as MsGraphFormData}
      showDescription
    />
  );
};
