import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const endUserPortalPlugin = createPlugin({
  id: 'end-user-portal',
  routes: {
    root: rootRouteRef,
  },
});

export const EndUserPortalPage = endUserPortalPlugin.provide(
  createRoutableExtension({
    name: 'EndUserPortalPage',
    component: () => import('./components/FrontPage').then(m => m.FrontPage),
    mountPoint: rootRouteRef,
  }),
);
