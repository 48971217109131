import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import Grid from '@mui/material/Grid';
import {
  EntityProvisioningContent,
  isProvisioningAvailable,
} from '@provisioning/react';
import React from 'react';
import { adrs, docs, k8s, pipelines, warning } from './sections';
import { hasLinks } from './util';

export const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {warning}
        <Grid item md={6} xs={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
        <EntitySwitch>
          <EntitySwitch.Case if={hasLinks}>
            <Grid item md={6} xs={12}>
              <EntityLinksCard />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
    </EntityLayout.Route>
    {docs}

    <EntityLayout.Route
      if={isProvisioningAvailable}
      path="/configuration"
      title="Configuration"
    >
      <EntityProvisioningContent />
    </EntityLayout.Route>

    {k8s}
    {pipelines}
    {adrs}
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);
