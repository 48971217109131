export const registrySchema = {
  $defs: {
    AISearchServiceConfigBasic: {
      properties: {
        kind: {
          const: 'ai-search-basic',
          default: 'ai-search-basic',
          description: 'Module discriminator',
          enum: ['ai-search-basic'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        sku: {
          $ref: '#/$defs/AISearchSku',
          default: 'basic',
        },
        semanticRankerEnabled: {
          default: false,
          description: 'Enable semantic ranking',
          title: 'Semantic ranking',
          type: 'boolean',
        },
        externalAccess: {
          default: false,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        replicaCount: {
          default: 1,
          description: 'Number of replicas for the Azure Search service.',
          maximum: 3,
          title: 'Replica count',
          type: 'integer',
        },
        partition_count: {
          default: null,
          title: 'Partition Count',
          type: 'null',
        },
      },
      required: ['kind'],
      title: 'Azure AI Search',
      type: 'object',
    },
    AISearchServiceConfigStandard: {
      properties: {
        kind: {
          const: 'ai-search',
          default: 'ai-search',
          description: 'Module discriminator',
          enum: ['ai-search'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        sku: {
          $ref: '#/$defs/AISearchSku',
          default: 'standard',
        },
        semanticRankerEnabled: {
          default: false,
          description: 'Enable semantic ranking',
          title: 'Semantic ranking',
          type: 'boolean',
        },
        externalAccess: {
          default: false,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        replicaCount: {
          default: 1,
          description: 'Number of replicas for the Azure Search service.',
          maximum: 12,
          title: 'Replica count',
          type: 'integer',
        },
        partitionCount: {
          anyOf: [
            {
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Number of partitions for the Azure Search service.',
          title: 'Partition count',
        },
      },
      required: ['kind'],
      title: 'Azure AI Search',
      type: 'object',
    },
    AISearchSku: {
      description:
        'The Azure Search service SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/search/search-sku-tier',
      enum: ['basic', 'standard'],
      title: 'AISearchSku',
      type: 'string',
    },
    AcrResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'acr',
          default: 'acr',
          enum: ['acr'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the ACR is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        sku: {
          description: 'The SKU of the ACR.',
          title: 'SKU',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'sku', 'resourceId'],
      title: 'AcrResource',
      type: 'object',
    },
    ApplicationConfig: {
      properties: {
        requiredResourceAccess: {
          description: 'Additional permissions on the product application.',
          items: {
            discriminator: {
              mapping: {
                'microsoft-graph':
                  '#/$defs/ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_',
              },
              propertyName: 'resource',
            },
            oneOf: [
              {
                $ref: '#/$defs/ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_',
              },
            ],
          },
          title: 'Additional resource accesses',
          type: 'array',
        },
        extraRedirectUrls: {
          additionalProperties: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          description: 'Additional callback URLs for the product application.',
          examples: [
            {
              development: ['http://localhost:3000'],
            },
          ],
          title: 'Extra callback URLs',
          type: 'object',
        },
      },
      title: 'ApplicationConfig',
      type: 'object',
    },
    ApplicationInfo: {
      properties: {
        kind: {
          const: 'application',
          default: 'application',
          enum: ['application'],
          title: 'Kind',
          type: 'string',
        },
        clientId: {
          title: 'Application client ID.',
          type: 'string',
        },
        objectId: {
          title: 'Application object ID.',
          type: 'string',
        },
        displayName: {
          title: 'Application display name.',
          type: 'string',
        },
      },
      required: ['kind', 'clientId', 'objectId', 'displayName'],
      title: 'ApplicationInfo',
      type: 'object',
    },
    ArangoBackupPolicy: {
      description:
        'The BackupPolicy controls how Arango will create and retain backups.',
      properties: {
        enabled: {
          default: false,
          description:
            'Denotes if regular backups of data contained in arango are created.',
          title: 'Backups enabled.',
          type: 'boolean',
        },
        schedule: {
          default: '*/15 * * * *',
          description:
            'Defines the interval at which backups should be created.',
          pattern:
            '(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\\d+(ns|us|\u00b5s|ms|s|m|h))+)|((((\\d+,)+\\d+|(\\d+(\\/|-)\\d+)|\\d+|\\*) ?){5,7})',
          title: 'Backup schedule',
          type: 'string',
        },
        maxBackups: {
          default: 10,
          description: 'Maximum number of backups that will be kept.',
          title: 'Maximum backups',
          type: 'integer',
        },
      },
      title: 'ArangoBackupPolicy',
      type: 'object',
    },
    ArangoConfig: {
      description:
        'Scalable graph database for graph, document, and search functionality.',
      properties: {
        kind: {
          const: 'arango',
          default: 'arango',
          description: 'Module discriminator',
          enum: ['arango'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        operatorImageVersion: {
          default: '1.2.36',
          title: 'ArangoDb operator image version',
          type: 'string',
        },
        imageVersion: {
          default: '3.11.4',
          title: 'ArangoDb image version',
          type: 'string',
        },
        deploymentChartVersion: {
          default: '1.2.18',
          title: 'Arango deployment Helm chart version',
          type: 'string',
        },
        mode: {
          $ref: '#/$defs/Mode',
          default: 'Single',
          description:
            'Controls if the database is deployed as single instance or cluster.',
          title: 'Single instance or Cluster mode.',
        },
        metrics: {
          $ref: '#/$defs/ArangoMetrics',
          default: {
            enabled: false,
          },
          description:
            'Configuration on how metrics are emitted and collected.',
          title: 'Metrics configuration.',
        },
        backupPolicy: {
          $ref: '#/$defs/ArangoBackupPolicy',
          default: {
            enabled: false,
            schedule: '*/15 * * * *',
            maxBackups: 10,
          },
          description:
            'The backup policy controls creation and retention of database backups.',
          title: 'Backup policy',
        },
        resources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '1',
              memory: '1Gi',
            },
            limits: {
              cpu: '3',
              memory: '5Gi',
            },
          },
          description: 'Resource request and limit for the Arango workload.',
          title: 'Arango resources',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Arango UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'Arango DB',
      type: 'object',
    },
    ArangoMetrics: {
      description: 'Metrics configuration controls how metrics are collected.',
      properties: {
        enabled: {
          default: false,
          description:
            'Denotes if metrics for the Arango deployment are collected.',
          title: 'Metrics enabled.',
          type: 'boolean',
        },
      },
      title: 'ArangoMetrics',
      type: 'object',
    },
    ArtifactType: {
      description: 'Artifact type.',
      enum: ['python-library', 'npm-library', 'oci', 'workload', 'techdocs'],
      title: 'ArtifactType',
      type: 'string',
    },
    AzureAIServicesConfig: {
      properties: {
        kind: {
          const: 'ai-multi-service',
          default: 'ai-multi-service',
          description: 'Module discriminator',
          enum: ['ai-multi-service'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
      },
      required: ['kind'],
      title: 'Azure AI Services',
      type: 'object',
    },
    AzureDevOpsProject: {
      description:
        'Configuration for an Azure DevOps project integrated with the Argus platform.',
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        organization: {
          default: 'basf4dev',
          description:
            'The Azure DevOps organization where the project resides.',
          title: 'Azure DevOps organization',
          type: 'string',
        },
      },
      required: ['name'],
      title: 'AzureDevOpsProject',
      type: 'object',
    },
    AzureRegion: {
      enum: ['westeurope', 'swedencentral', 'francecentral'],
      title: 'AzureRegion',
      type: 'string',
    },
    BaseResourceInfo: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
      },
      required: ['resourceId'],
      title: 'BaseResourceInfo',
      type: 'object',
    },
    CellClusterReport: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        caCertificate: {
          description:
            'Kubernetes API server certificate authority (base64 encoded)',
          title: 'CA certificate',
          type: 'string',
        },
        oidcIssuerUrl: {
          title: 'OIDC issuer URL',
          type: 'string',
        },
        apiServerUrl: {
          description: 'Base URL of the Kubernetes control plane',
          title: 'API server Url',
          type: 'string',
        },
        kubernetesVersion: {
          description: 'Version of k8s running on the cluster',
          title: 'Kubernetes version',
          type: 'string',
        },
      },
      required: [
        'resourceId',
        'caCertificate',
        'oidcIssuerUrl',
        'apiServerUrl',
        'kubernetesVersion',
      ],
      title: 'CellClusterReport',
      type: 'object',
    },
    CertManagerConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/CertManagerConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_CertManagerConfigBase_',
            },
          ],
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
      },
      required: ['config'],
      title: 'CertManagerConfig',
      type: 'object',
    },
    CertManagerConfigBase: {
      properties: {
        enabled: {
          default: false,
          title: 'Enable cert manager.',
          type: 'boolean',
        },
        chart_version: {
          default: 'v1.16.1',
          title: 'The version of the cert-manager chart to install.',
          type: 'string',
        },
      },
      title: 'CertManagerConfigBase',
      type: 'object',
    },
    CloudBeaverConfig: {
      properties: {
        kind: {
          const: 'cloudbeaver',
          default: 'cloudbeaver',
          description: 'Module discriminator',
          enum: ['cloudbeaver'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/dbeaver',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        chartVersion: {
          default: '1.1.2',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Cloudbeaver UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'CloudBeaver',
      type: 'object',
    },
    ClusterSetup: {
      properties: {
        certManager: {
          $ref: '#/$defs/CertManagerConfig',
          title: 'Cert manager configuration.',
        },
        opentelemetry: {
          $ref: '#/$defs/OpenTelemetryConfig',
          title: 'OpenTelemetry configuration.',
        },
      },
      title: 'ClusterSetup',
      type: 'object',
    },
    CodeLocation: {
      properties: {
        repository: {
          description: 'Repository name.',
          title: 'Repository',
          type: 'string',
        },
        artifacts: {
          description: 'List of artifacts.',
          items: {
            $ref: '#/$defs/ArtifactType',
          },
          title: 'Artifacts',
          type: 'array',
        },
        workflow: {
          $ref: '#/$defs/Workflow',
          default: 'github-flow',
        },
        ruleset: {
          $ref: '#/$defs/RuleSet',
          default: 'base',
          description: 'Ruleset to apply to the code location.',
          title: 'Ruleset',
        },
      },
      required: ['repository', 'artifacts'],
      title: 'CodeLocation',
      type: 'object',
    },
    CognitiveAccountResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'cognitive-services',
          default: 'cognitive-services',
          enum: ['cognitive-services'],
          title: 'Kind',
          type: 'string',
        },
        accountType: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The type of the Cognitive Services account.',
          title: 'Account type',
        },
        location: {
          description: 'The location where the Cognitive Services is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        endpoint: {
          description: 'The FQDN of the Azure Cognitive Services.',
          title: 'Cognitive Services URI',
          type: 'string',
        },
      },
      required: ['resourceId', 'location', 'endpoint'],
      title: 'CognitiveAccountResource',
      type: 'object',
    },
    CognitiveDeploymentsResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'cognitive-deployment',
          default: 'cognitive-deployment',
          enum: ['cognitive-deployment'],
          title: 'Kind',
          type: 'string',
        },
        name: {
          description: 'The name of the Cognitive Services deployment.',
          title: 'Deployment name',
          type: 'string',
        },
        modelName: {
          description: 'The name of the Cognitive Services model.',
          title: 'Model name',
          type: 'string',
        },
        modelVersion: {
          description: 'The version of the Cognitive Services model.',
          title: 'Model version',
          type: 'string',
        },
      },
      required: ['resourceId', 'name', 'modelName', 'modelVersion'],
      title: 'CognitiveDeploymentsResource',
      type: 'object',
    },
    CustomDomainReport: {
      properties: {
        nodeId: {
          description: 'The ID of the node in the cluster.',
          title: 'Node ID',
          type: 'string',
        },
        cert: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Name of the certificate',
        },
        kvId: {
          anyOf: [
            {
              description: 'Canonical reference to resource.',
              title: 'Azure resource id.',
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Keyvault Resource ID',
        },
        role: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        url: {
          description: 'The URL of the domain.',
          title: 'URL',
          type: 'string',
        },
        flavour: {
          $ref: '#/$defs/Domain',
          description: 'The flavour of the domain.',
          title: 'Flavour',
        },
        product: {
          description: 'Name of the Product this URL is used for',
          title: 'Product Name',
          type: 'string',
        },
      },
      required: ['nodeId', 'role', 'url', 'flavour', 'product'],
      title: 'CustomDomainReport',
      type: 'object',
    },
    DagsterApplyLimitPerUniqueValue: {
      properties: {
        applyLimitPerUniqueValue: {
          title: 'Applylimitperuniquevalue',
          type: 'boolean',
        },
      },
      required: ['applyLimitPerUniqueValue'],
      title: 'DagsterApplyLimitPerUniqueValue',
      type: 'object',
    },
    DagsterConfig: {
      description:
        'Orchestrator for the whole development lifecycle, with integrated lineage and observability\n\nThe [Dagster] module provides an instance of the Dagster orchestrator to run\nyour data-engineering, or machine learning pipelines.\n\n[Dagster]: https://docs.dagster.io/\n\nNote:\n    This module will only provision the core orchestrator. Dagster code locations\n    where you define your pipelines are deployed separately.',
      properties: {
        kind: {
          const: 'dagster',
          default: 'dagster',
          description: 'Module discriminator',
          enum: ['dagster'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '1.7.16',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/dagster',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Dagster UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        storeComputeLogsEnabled: {
          default: false,
          description: 'Compute logs in blob storage',
          title: 'Store compute logs',
          type: 'boolean',
        },
        userDeployments: {
          default: [],
          description: 'Code location servers.',
          items: {
            $ref: '#/$defs/DagsterUserDeployment',
          },
          title: 'Code locations',
          type: 'array',
          'vsc:action': 'array',
        },
        maxConcurrentRuns: {
          default: 10,
          description: 'Maximum number of concurrent job execution runs.',
          title: 'Maximum concurrent runs',
          type: 'integer',
        },
        tagConcurrencyLimits: {
          default: [],
          description:
            'List of tags and their corresponding concurrency limits.',
          items: {
            $ref: '#/$defs/DagsterTagConcurrencyLimits',
          },
          title: 'Tag based concurrency limits',
          type: 'array',
          'vsc:action': 'array',
        },
        defaultJobResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '512Mi',
            },
            limits: {
              cpu: '1000m',
              memory: '1Gi',
            },
          },
          description: 'Default resources applied to pipeline execution runs.',
          title: 'Default job resources',
        },
      },
      required: ['kind'],
      title: 'Dagster',
      type: 'object',
    },
    DagsterTagConcurrencyLimits: {
      properties: {
        key: {
          title: 'Job tag key',
          type: 'string',
        },
        value: {
          anyOf: [
            {
              type: 'string',
            },
            {
              $ref: '#/$defs/DagsterApplyLimitPerUniqueValue',
            },
            {
              type: 'null',
            },
          ],
          title: 'Job tag value',
        },
        limit: {
          title: 'Maximum number of concurrent runs',
          type: 'integer',
        },
      },
      required: ['key', 'value', 'limit'],
      title: 'DagsterTagConcurrencyLimits',
      type: 'object',
    },
    DagsterUserDeployment: {
      properties: {
        host: {
          $ref: '#/$defs/RFC1035Name',
          description: 'Host name of the code location server',
          title: 'Code location host',
        },
        port: {
          $ref: '#/$defs/PortNumber',
          default: 3030,
          description: 'Port number of the code location server',
          title: 'Code location port',
        },
      },
      required: ['host', 'port'],
      title: 'DagsterUserDeployment',
      type: 'object',
    },
    DataDogIntegrationInfo: {
      properties: {
        agent_object_id: {
          title: 'Agent Object Id',
          type: 'string',
        },
      },
      required: ['agent_object_id'],
      title: 'DataDogIntegrationInfo',
      type: 'object',
    },
    DatabaseName: {
      maxLength: 63,
      minLength: 1,
      pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
      type: 'string',
    },
    DeployedInstanceLayout: {
      properties: {
        platformCell: {
          $ref: '#/$defs/InstanceCellRef',
          title: 'Platform cell',
        },
        productCells: {
          additionalProperties: {
            $ref: '#/$defs/InstanceCellRef',
          },
          title: 'Product cells',
          type: 'object',
        },
      },
      required: ['platformCell', 'productCells'],
      title: 'DeployedInstanceLayout',
      type: 'object',
    },
    DeploymentTarget: {
      properties: {
        serviceConnection: {
          title: 'Serviceconnection',
          type: 'string',
        },
        serviceConnectionId: {
          title: 'Serviceconnectionid',
          type: 'string',
        },
        clusterName: {
          title: 'Clustername',
          type: 'string',
        },
        clusterRg: {
          title: 'Clusterrg',
          type: 'string',
        },
      },
      required: [
        'serviceConnection',
        'serviceConnectionId',
        'clusterName',
        'clusterRg',
      ],
      title: 'DeploymentTarget',
      type: 'object',
    },
    DevOpsOutput: {
      properties: {
        projectUrl: {
          title: 'Projecturl',
          type: 'string',
        },
        targetEnvironments: {
          additionalProperties: {
            $ref: '#/$defs/DeploymentTarget',
          },
          title: 'Targetenvironments',
          type: 'object',
        },
        variableGroups: {
          items: {
            type: 'string',
          },
          title: 'Variablegroups',
          type: 'array',
        },
      },
      required: ['projectUrl', 'targetEnvironments', 'variableGroups'],
      title: 'DevOpsOutput',
      type: 'object',
    },
    Domain: {
      enum: ['data', 'app'],
      title: 'Domain',
      type: 'string',
    },
    DomainReport: {
      properties: {
        nodeId: {
          description: 'The ID of the node in the cluster.',
          title: 'Node ID',
          type: 'string',
        },
        cert: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Name of the certificate',
        },
        kvId: {
          anyOf: [
            {
              description: 'Canonical reference to resource.',
              title: 'Azure resource id.',
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Keyvault Resource ID',
        },
        role: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        url: {
          description: 'The URL of the domain.',
          title: 'URL',
          type: 'string',
        },
        flavour: {
          $ref: '#/$defs/Domain',
          description: 'The flavour of the domain.',
          title: 'Flavour',
        },
      },
      required: ['nodeId', 'role', 'url', 'flavour'],
      title: 'DomainReport',
      type: 'object',
    },
    EntityName: {
      maxLength: 253,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    ExposedServiceConfig: {
      properties: {
        name: {
          description: 'Name af the K8s service inside the product namespace.',
          examples: ['k8s-service-name'],
          maxLength: 62,
          minLength: 1,
          pattern:
            'prodai_ada|ai_store|digital_twin|prodai_nasa|competitor_intelligence|app_dev_enablement|scott_solutions|spinal_os|dob_aisol|advanced_imaging|forecasting_platform|data_and_ai_academy|ai_solutions|gb_gtu|^[a-z]+(?:-?[a-z0-9]+)+$',
          title: 'Service name.',
          type: 'string',
        },
        port: {
          $ref: '#/$defs/PortNumber',
          default: 80,
          description: 'Target port of the service.',
          title: 'Service port.',
        },
        domains: {
          default: ['app'],
          description: 'The domains this service is available at.',
          items: {
            $ref: '#/$defs/Domain',
          },
          title: 'Routing domains',
          type: 'array',
        },
        routes: {
          description: 'Routes exposed on the service.',
          items: {
            $ref: '#/$defs/RoutingRule',
          },
          title: 'Service routes',
          type: 'array',
        },
      },
      required: ['name', 'routes'],
      title: 'ExposedServiceConfig',
      type: 'object',
    },
    ExposedServiceEntry: {
      properties: {
        name: {
          description: 'Name of the service in Kubernetes.',
          title: 'Kubernetes service name',
          type: 'string',
        },
        urls: {
          description: 'URLs for the service.',
          items: {
            $ref: '#/$defs/ExposedServiceUrl',
          },
          title: 'URLs for the service.',
          type: 'array',
        },
      },
      required: ['name'],
      title: 'ExposedServiceEntry',
      type: 'object',
    },
    ExposedServiceUrl: {
      properties: {
        url: {
          description: 'URL for the service.',
          title: 'URL',
          type: 'string',
        },
        allowedGroups: {
          default: [],
          description: 'Groups that have access to this URL',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        domain: {
          $ref: '#/$defs/Domain',
          default: ['app'],
          description: 'The domain this URL is available at.',
          title: 'URL domain',
        },
      },
      required: ['url'],
      title: 'ExposedServiceUrl',
      type: 'object',
    },
    FeatureFlag: {
      enum: ['github-env-next', 'managed-report-types', 'structured-logging'],
      title: 'FeatureFlag',
      type: 'string',
    },
    GatewayEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/GatewayReport',
        },
      },
      required: ['stackVersion', 'output'],
      title: 'GatewayEntry',
      type: 'object',
    },
    GatewayReport: {
      properties: {
        kind: {
          description: 'The kind of the gateway.',
          title: 'Kind',
          type: 'string',
        },
        domains: {
          description: 'List of domains exposed by the gateway.',
          items: {
            $ref: '#/$defs/DomainReport',
          },
          title: 'Domains',
          type: 'array',
        },
        customDomains: {
          description: 'List of additional custom domains',
          items: {
            $ref: '#/$defs/CustomDomainReport',
          },
          title: 'Customdomains',
          type: 'array',
        },
      },
      required: ['kind', 'domains'],
      title: 'GatewayReport',
      type: 'object',
    },
    GitHubEnterpriseEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/GitHubEnterpriseReport',
        },
        input: {
          $ref: '#/$defs/GitHubEnterpriseManifest',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
      },
      required: ['stackVersion', 'output', 'input', 'policies'],
      title: 'GitHubEnterpriseEntry',
      type: 'object',
    },
    GitHubEnterpriseManifest: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'GitHubEnterpriseManifest',
          default: 'GitHubEnterpriseManifest',
          enum: ['GitHubEnterpriseManifest'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/Metadata',
        },
        spec: {
          $ref: '#/$defs/GitHubEnterpriseSpec',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'GitHubEnterpriseManifest',
      type: 'object',
    },
    GitHubEnterpriseReport: {
      properties: {
        registered: {
          $ref: '#/$defs/GitHubMembership',
        },
        id_name_map: {
          items: {
            maxItems: 2,
            minItems: 2,
            prefixItems: [
              {
                type: 'string',
              },
              {
                type: 'string',
              },
            ],
            type: 'array',
          },
          title: 'Id Name Map',
          type: 'array',
        },
      },
      required: ['registered', 'id_name_map'],
      title: 'GitHubEnterpriseReport',
      type: 'object',
    },
    GitHubEnterpriseSpec: {
      properties: {
        teams: {
          description: 'The teams in the organization.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Teams',
          type: 'array',
        },
      },
      required: ['teams'],
      title: 'GitHubEnterpriseSpec',
      type: 'object',
    },
    GitHubMembership: {
      properties: {
        users: {
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Users',
          type: 'array',
        },
        groups: {
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Groups',
          type: 'array',
        },
      },
      required: ['users', 'groups'],
      title: 'GitHubMembership',
      type: 'object',
    },
    GitHubTeamConfig: {
      properties: {
        name: {
          description: 'The name of the team.',
          title: 'Team namme',
          type: 'string',
        },
        description: {
          description: 'The description of the team.',
          title: 'Description',
          type: 'string',
        },
        objectId: {
          description:
            'The object ID of the group that represents the team in AAD.',
          format: 'uuid',
          title: 'Objectid',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['name', 'description', 'objectId'],
      title: 'GitHubTeamConfig',
      type: 'object',
    },
    GitHubTeamInfo: {
      properties: {
        ghId: {
          description: 'The GitHub ID of the team.',
          title: 'Ghid',
          type: 'string',
        },
        slug: {
          title: 'Slug',
          type: 'string',
        },
        mappedObjectId: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The object ID of the group that represents the team in AAD.',
          title: 'Mappedobjectid',
        },
        ghEmuId: {
          anyOf: [
            {
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The GitHub Enterprise Managed Users ID of the team.',
          title: 'Ghemuid',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['ghId', 'slug'],
      title: 'GitHubTeamInfo',
      type: 'object',
    },
    GroupInfo: {
      properties: {
        objectId: {
          title: 'Objectid',
          type: 'string',
        },
        displayName: {
          title: 'Displayname',
          type: 'string',
        },
      },
      required: ['objectId', 'displayName'],
      title: 'GroupInfo',
      type: 'object',
    },
    IdentityInfo: {
      properties: {
        kind: {
          const: 'identity',
          default: 'identity',
          enum: ['identity'],
          title: 'Kind',
          type: 'string',
        },
        clientId: {
          title: 'Identity client ID.',
          type: 'string',
        },
        objectId: {
          title: 'Identity object ID.',
          type: 'string',
        },
        displayName: {
          title: 'Identity display name.',
          type: 'string',
        },
      },
      required: ['kind', 'clientId', 'objectId', 'displayName'],
      title: 'IdentityInfo',
      type: 'object',
    },
    InfraCellRef: {
      description:
        'A reference to a infrstructure cell associated with a logical stage.',
      properties: {
        name: {
          description: 'The globally unique name of the cell.',
          title: 'name',
          type: 'string',
        },
        stage: {
          $ref: '#/$defs/LogicalStage',
          description:
            'The logical stage describes the order of a cell within a deployment squence.',
          title: 'logical stage',
        },
      },
      required: ['name', 'stage'],
      title: 'InfraCellRef',
      type: 'object',
    },
    InfrastructureCellEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/InfrastructureCellReport',
        },
      },
      required: ['stackVersion', 'output'],
      title: 'InfrastructureCellEntry',
      type: 'object',
    },
    InfrastructureCellReport: {
      properties: {
        cellId: {
          description: 'The ID of the node in the instance.',
          title: 'Cell ID',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage of the cell.',
          title: 'Logical stage',
        },
        cluster: {
          $ref: '#/$defs/K8sClusterResource',
          description: 'The cluster information.',
        },
        network: {
          $ref: '#/$defs/NetworkReport',
          description: 'The network information.',
        },
      },
      required: ['cellId', 'kind', 'logicalStage', 'cluster', 'network'],
      title: 'InfrastructureCellReport',
      type: 'object',
    },
    InstanceCellEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        manifest: {
          $ref: '#/$defs/ProductCellManifest',
        },
        report: {
          $ref: '#/$defs/InstanceCellReport',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
        resources: {
          $ref: '#/$defs/ResourcesReport',
          title: 'Resources.',
        },
      },
      required: ['stackVersion', 'manifest', 'report', 'policies', 'resources'],
      title: 'InstanceCellEntry',
      type: 'object',
    },
    InstanceCellKind: {
      enum: ['platform', 'product'],
      title: 'InstanceCellKind',
      type: 'string',
    },
    InstanceCellRef: {
      properties: {
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The kind of the cell.',
          title: 'Cell kind',
        },
        cellId: {
          description: 'The unique identifier of the cell.',
          title: 'Cell ID',
          type: 'string',
        },
      },
      required: ['kind', 'cellId'],
      title: 'InstanceCellRef',
      type: 'object',
    },
    InstanceCellReport: {
      properties: {
        cellId: {
          description: 'The ID of the node in the instance.',
          title: 'Cell ID',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage of the cell.',
          title: 'Logical stage',
        },
        subscriptions: {
          description: 'Subscriptions the cell is deployed to.',
          items: {
            $ref: '#/$defs/InstanceCellSubscription',
          },
          title: 'Subscriptions',
          type: 'array',
        },
        clusters: {
          description: 'Clusters deployed to insatnce cell.',
          items: {
            $ref: '#/$defs/CellClusterReport',
          },
          title: 'Cluster',
          type: 'array',
        },
      },
      required: ['cellId', 'kind', 'logicalStage', 'subscriptions', 'clusters'],
      title: 'InstanceCellReport',
      type: 'object',
    },
    InstanceCellSubscription: {
      properties: {
        id: {
          title: 'Id',
          type: 'string',
        },
        name: {
          title: 'Name',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellSubscriptionKind',
          description: 'The kind of the subscription.',
          title: 'Subscription kind',
        },
      },
      required: ['id', 'name', 'kind'],
      title: 'InstanceCellSubscription',
      type: 'object',
    },
    InstanceCellSubscriptionKind: {
      enum: ['dsp', 'appstore', 'argus'],
      title: 'InstanceCellSubscriptionKind',
      type: 'string',
    },
    InstanceConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/InstanceSpec',
            },
            {
              $ref: '#/$defs/StagedConfigDict_InstanceSpec_',
            },
          ],
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        stages: {
          description: 'Gloablly unique names for the instances.',
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Instance names',
          type: 'array',
        },
      },
      required: ['config'],
      title: 'InstanceConfig',
      type: 'object',
    },
    InstanceEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        manifest: {
          $ref: '#/$defs/PlatformManifest',
        },
        report: {
          $ref: '#/$defs/InstanceReport',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
        resources: {
          $ref: '#/$defs/ResourcesReport',
          title: 'Resources.',
        },
      },
      required: ['stackVersion', 'manifest', 'report', 'policies', 'resources'],
      title: 'InstanceEntry',
      type: 'object',
    },
    InstanceGroups: {
      description:
        'Entry ID groups that are used across cells within a platform instance.',
      properties: {
        workspaces: {
          $ref: '#/$defs/GroupInfo',
        },
        workspacePrincipals: {
          $ref: '#/$defs/GroupInfo',
          title: 'Workspace principals',
        },
        products: {
          $ref: '#/$defs/GroupInfo',
        },
        productPrincipals: {
          $ref: '#/$defs/GroupInfo',
          title: 'Product principals',
        },
        kubelets: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: [
        'workspaces',
        'workspacePrincipals',
        'products',
        'productPrincipals',
        'kubelets',
      ],
      title: 'InstanceGroups',
      type: 'object',
    },
    InstanceLayout: {
      properties: {
        platform: {
          $ref: '#/$defs/InfraCellRef',
        },
        products: {
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Products',
          type: 'array',
        },
      },
      required: ['platform', 'products'],
      title: 'InstanceLayout',
      type: 'object',
    },
    InstanceRegistryReport: {
      properties: {
        repository: {
          description: 'The name of the git repository.',
          title: 'Repository',
          type: 'string',
        },
        catalogRepository: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The name of the git repository for the catalog.',
          title: 'Catalog repository',
        },
        agentsGroupObjectId: {
          description: 'The object ID of the service principal group.',
          title: 'Agents group object ID',
          type: 'string',
        },
        agents: {
          description:
            'The service principals acting as agents to deploy into instance cells.',
          items: {
            $ref: '#/$defs/RegistryAgentReport',
          },
          title: 'Agents',
          type: 'array',
        },
      },
      required: ['repository', 'agentsGroupObjectId', 'agents'],
      title: 'InstanceRegistryReport',
      type: 'object',
    },
    InstanceReport: {
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          title: 'Logical stage',
        },
        layout: {
          $ref: '#/$defs/DeployedInstanceLayout',
          title: 'Instance layout',
        },
        platformCells: {
          description: 'The cells deployed in the platform instance.',
          items: {
            $ref: '#/$defs/InstanceCellReport',
          },
          title: 'Platform cells',
          type: 'array',
        },
        registry: {
          $ref: '#/$defs/InstanceRegistryReport',
        },
        groups: {
          $ref: '#/$defs/InstanceGroups',
        },
        resources: {
          $ref: '#/$defs/InstanceResources',
        },
      },
      required: [
        'name',
        'logicalStage',
        'layout',
        'registry',
        'groups',
        'resources',
      ],
      title: 'InstanceReport',
      type: 'object',
    },
    InstanceResources: {
      properties: {
        ociRegistry: {
          $ref: '#/$defs/AcrResource',
          title: 'Container registry',
        },
        portal: {
          anyOf: [
            {
              $ref: '#/$defs/PortalResources',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Portal resources',
        },
        legacy: {
          $ref: '#/$defs/LegacyResources',
          title: 'Legacy resources',
        },
      },
      required: ['ociRegistry', 'legacy'],
      title: 'InstanceResources',
      type: 'object',
    },
    InstanceSpec: {
      properties: {
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
        productCells: {
          description: 'List of product cells.',
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Product cells',
          type: 'array',
        },
      },
      title: 'InstanceSpec',
      type: 'object',
    },
    JupyterHubConfig: {
      description:
        'Web-based interactive development environment for notebooks, code, and data.',
      properties: {
        kind: {
          const: 'jupyter',
          default: 'jupyter',
          description: 'Module discriminator',
          enum: ['jupyter'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        imageTag: {
          default: 'latest',
          description: 'Jupyter hub docker image tag to be used',
          title: 'Image tag',
          type: 'string',
        },
        environments: {
          default: [
            {
              image: 'basfargusglobal.azurecr.io/argus-jupyterhub-datascience',
              tag: 'latest',
              displayName: 'Default Data Science Notebook',
              description:
                'Default notebook containing various popular data science libraries.',
            },
          ],
          description:
            'List of user environments users can choose to run their notebook in.',
          items: {
            $ref: '#/$defs/UserEnvironment',
          },
          title: 'User environments',
          type: 'array',
        },
      },
      required: ['kind'],
      title: 'Jupyter Hub',
      type: 'object',
    },
    K8sClusterResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        location: {
          description: 'Azure Region where the cluster is provisioned.',
          title: 'Location',
          type: 'string',
        },
        kubernetesVersion: {
          description: 'Kubernetes version installed in the cluster.',
          title: 'Kubernetesversion',
          type: 'string',
        },
        kubernetesOrchestratorVersion: {
          description: 'Kubernetes orchestrator version.',
          title: 'Kubernetesorchestratorversion',
          type: 'string',
        },
        fqdn: {
          description:
            'The fully qualified domain name of the Kubernetes cluster.',
          title: 'Fqdn',
          type: 'string',
        },
        oidcIssuerUrl: {
          description: 'The OpenID Connect issuer URL for the cluster.',
          title: 'Oidcissuerurl',
          type: 'string',
        },
        kubeletObjectId: {
          description:
            'The object ID of the kubelet identity associated with the cluster.',
          title: 'Kubeletobjectid',
          type: 'string',
        },
      },
      required: [
        'resourceId',
        'location',
        'kubernetesVersion',
        'kubernetesOrchestratorVersion',
        'fqdn',
        'oidcIssuerUrl',
        'kubeletObjectId',
      ],
      title: 'K8sClusterResource',
      type: 'object',
    },
    K8sCpuType: {
      pattern: '^(([0-9]+[.])?[0-9]+)(m)?$',
      type: 'string',
    },
    K8sMemoryType: {
      pattern: '^([0-9]+)(Ki|Ei|Pi|Ti|Gi|Mi|[EPTGMKmk])?$',
      type: 'string',
    },
    K8sReleaseInfo: {
      properties: {
        namespace: {
          title: 'Namespace',
          type: 'string',
        },
      },
      required: ['namespace'],
      title: 'K8sReleaseInfo',
      type: 'object',
    },
    K8sResourceConfig: {
      properties: {
        requests: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource requests.',
          title: 'Default resource requests',
        },
        limits: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource limits.',
          title: 'Default resource limits',
        },
      },
      required: ['requests', 'limits'],
      title: 'K8sResourceConfig',
      type: 'object',
    },
    K8sResources: {
      properties: {
        cpu: {
          $ref: '#/$defs/K8sCpuType',
          description: 'Specifies the number of physical or virtual cores.',
          title: 'CPU resource units',
        },
        memory: {
          $ref: '#/$defs/K8sMemoryType',
          description: 'Specifies the number of bytes of physical memory.',
          title: 'Memory resource units',
        },
      },
      required: ['cpu', 'memory'],
      title: 'K8sResources',
      type: 'object',
    },
    KeyVaultResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'key-vault',
          default: 'key-vault',
          enum: ['key-vault'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the key vault is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        uri: {
          description: 'The URI of the key vault.',
          title: 'URI',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'uri', 'resourceId'],
      title: 'KeyVaultResource',
      type: 'object',
    },
    KustoConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/KustoConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_KustoConfigBase_',
            },
          ],
          default: {
            databases: [],
          },
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        kind: {
          const: 'kusto',
          default: 'kusto',
          description: 'Module discriminator',
          enum: ['kusto'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
      },
      required: ['kind'],
      title: 'Kusto Cluster',
      type: 'object',
    },
    KustoConfigBase: {
      description:
        'Allows provisioning Kusto databases for use in product services.',
      properties: {
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Provisioned databases',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      title: 'KustoConfigBase',
      type: 'object',
    },
    LegacyResources: {
      properties: {
        shared_groups: {
          $ref: '#/$defs/SharedGroupsReport',
        },
      },
      required: ['shared_groups'],
      title: 'LegacyResources',
      type: 'object',
    },
    Lifecycle: {
      enum: ['experimental', 'development', 'production', 'end-of-life'],
      title: 'Lifecycle',
      type: 'string',
    },
    LinkedGitHubTeamConfig: {
      properties: {
        slug: {
          description: 'The slug of the githu team.',
          title: 'Team namme',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['slug'],
      title: 'LinkedGitHubTeamConfig',
      type: 'object',
    },
    LogicalStage: {
      enum: ['development', 'staging', 'production'],
      title: 'LogicalStage',
      type: 'string',
    },
    ManifestMetadata: {
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
        role: {
          $ref: '#/$defs/ManifestRole',
          default: 'product',
          description: 'Role of the entity.',
          title: 'Role',
        },
      },
      required: ['name', 'namespace'],
      title: 'ManifestMetadata',
      type: 'object',
    },
    ManifestRole: {
      enum: ['platform', 'product'],
      title: 'ManifestRole',
      type: 'string',
    },
    MemgraphConfig: {
      description: 'A high-performance, in-memory graph database',
      properties: {
        kind: {
          const: 'memgraph',
          default: 'memgraph',
          description: 'Module discriminator',
          enum: ['memgraph'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '2.1.3',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        imageName: {
          default: 'memgraph/memgraph',
          description: 'Memgraph docker image NAME to be used',
          title: 'Image name',
          type: 'string',
        },
        imageTag: {
          default: '2.20.1',
          description: 'Memgraph docker image tag to be used',
          title: 'Image tag',
          type: 'string',
        },
        deployments: {
          default: [],
          description: 'Deployments of unique Memgraph instances.',
          items: {
            $ref: '#/$defs/MemgraphDeployment',
          },
          title: 'Memgraph deployments',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      required: ['kind'],
      title: 'Memgraph',
      type: 'object',
    },
    MemgraphDeployment: {
      properties: {
        name: {
          default: 'memgraph',
          title: 'Deployment name',
          type: 'string',
        },
        resourcesRequests: {
          $ref: '#/$defs/K8sResources',
          default: {
            cpu: '1000m',
            memory: '5000',
          },
          description: 'Request Kubernetes resources for Memgraph instance',
        },
        resourcesLimits: {
          $ref: '#/$defs/K8sResources',
          default: {
            cpu: '2000m',
            memory: '10000',
          },
          description: 'Limits on Kubernetes resources for Memgraph instance',
        },
        extraArgs: {
          description: 'Extra arguments to pass to Memgraph',
          items: {
            type: 'string',
          },
          title: 'Extraargs',
          type: 'array',
        },
      },
      title: 'MemgraphDeployment',
      type: 'object',
    },
    Metadata: {
      description: 'Metadata fields describing the resource',
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
      },
      required: ['name', 'namespace'],
      title: 'Metadata',
      type: 'object',
    },
    MlflowConfig: {
      description:
        'An open source platform for the machine learning lifecycle\n\nThe [MLflow] module provides an instance of the MlFLow platform\nfor machine learning lifecycle management.\n\n[MLflow]: https://mlflow.org/',
      properties: {
        kind: {
          const: 'mlflow',
          default: 'mlflow',
          description: 'Module discriminator',
          enum: ['mlflow'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '1.12.0',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/mlflow',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the MLflow UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'MlFlow',
      type: 'object',
    },
    Mode: {
      description:
        'The type of deployment to be created.\n\nSee the [documentation] for details.\n\n[documentation]: https://docs.arangodb.com/stable/deploy/deployment/kubernetes/deployment-resource-reference/#specmode-string',
      enum: ['Single', 'Cluster', 'ActiveFailover'],
      title: 'Mode',
      type: 'string',
    },
    ModelUpdatePolicy: {
      description: 'Policy for updating the model.',
      enum: [
        'OnceNewDefaultVersionAvailable',
        'OnceCurrentVersionExpired',
        'NoAutoUpgrade',
      ],
      title: 'ModelUpdatePolicy',
      type: 'string',
    },
    MsGraphPermission: {
      description: 'Microsoft Graph permissions',
      enum: [
        'delegated/user.read.all',
        'delegated/group.read.all',
        'application/user.read.all',
        'application/group.read.all',
      ],
      title: 'MsGraphPermission',
      type: 'string',
    },
    MysqlConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_MysqlConfigBase_',
            },
          ],
          default: {
            version: '8.0.21',
            skuName: 'B_Standard_B1s',
            storageSizeGb: 32,
            backupRetentionDays: 7,
            databases: [],
            serverParameters: null,
          },
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        kind: {
          const: 'mysql',
          default: 'mysql',
          description: 'Module discriminator',
          enum: ['mysql'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
      },
      required: ['kind'],
      title: 'MySQL Server',
      type: 'object',
    },
    MysqlConfigBase: {
      description:
        'Allows provisioning Mysql databases for use in product services.',
      properties: {
        version: {
          $ref: '#/$defs/MysqlVersion',
          default: '8.0.21',
          description: 'Version of Mysql server to use.',
          title: 'Mysql version',
        },
        skuName: {
          $ref: '#/$defs/MysqlSku',
          default: 'B_Standard_B1s',
          description:
            'SKU name to be used as base model for the Mysql server.',
          title: 'Mysql SKU',
        },
        storageSizeGb: {
          default: 32,
          description: 'Size of the Mysql storage in GB',
          title: 'Database storage capacity',
          type: 'integer',
        },
        backupRetentionDays: {
          default: 7,
          description: 'Number of days the automatic Mysql backup is kept',
          maximum: 35,
          minimum: 7,
          title: 'Backup retention days',
          type: 'integer',
        },
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Databases',
          type: 'array',
          'vsc:action': 'array',
        },
        serverParameters: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlServerParameters',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Configurable server parameters.',
        },
      },
      title: 'MysqlConfigBase',
      type: 'object',
    },
    MysqlDatabaseResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'mysql-db',
          default: 'mysql-db',
          enum: ['mysql-db'],
          title: 'Kind',
          type: 'string',
        },
        charset: {
          description: 'The character set to use for the database.',
          title: 'Character set',
          type: 'string',
        },
        collation: {
          description: 'The collation to use for the database.',
          title: 'Collation',
          type: 'string',
        },
      },
      required: ['kind', 'resourceId'],
      title: 'MysqlDatabaseResource',
      type: 'object',
    },
    MysqlServerParameters: {
      description:
        'The Mysql server parameters.\n\n[documentation]: https://www.mysql.com/docs/refman/8.0/en/server-system-variables.html',
      properties: {
        maxConnections: {
          anyOf: [
            {
              maximum: 5000,
              minimum: 25,
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The maximum number of concurrent connections to the database server.',
          title: 'Maxconnections',
        },
        sqlGenerateInvisiblePrimaryKey: {
          anyOf: [
            {
              enum: ['ON', 'OFF'],
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Sqlgenerateinvisibleprimarykey',
        },
      },
      title: 'MysqlServerParameters',
      type: 'object',
    },
    MysqlServerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'mysql',
          default: 'mysql',
          enum: ['mysql'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the Mysql server is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        fqdn: {
          description: 'Fully qualified domain name of the server.',
          title: 'FQDN',
          type: 'string',
        },
        skuName: {
          description: 'The SKU of the Mysql server.',
          title: 'SKU name',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'fqdn', 'skuName', 'resourceId'],
      title: 'MysqlServerResource',
      type: 'object',
    },
    MysqlSku: {
      description:
        'The Mysql server SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/mysql/concepts-compute-storage#compute-tiers-vcores-and-server-types',
      enum: ['B_Standard_B1s', 'GP_Standard_D2ads_v5', 'MO_Standard_E2ds_v4'],
      title: 'MysqlSku',
      type: 'string',
    },
    MysqlVersion: {
      const: '8.0.21',
      description:
        'The Mysql server version to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/mysql/concepts-supported-versions',
      enum: ['8.0.21'],
      title: 'MysqlVersion',
      type: 'string',
    },
    NetworkReport: {
      properties: {
        pip: {
          $ref: '#/$defs/PublicIpResource',
          description: 'Public IP address of the network.',
        },
        vnet: {
          $ref: '#/$defs/BaseResourceInfo',
          description: 'The virtual network information.',
        },
      },
      required: ['pip', 'vnet'],
      title: 'NetworkReport',
      type: 'object',
    },
    OpenAIDeplyomentConfig: {
      properties: {
        name: {
          description: 'Name of the deployment',
          title: 'Name',
          type: 'string',
        },
        model: {
          $ref: '#/$defs/OpenAIModel',
          description: 'Model to deploy',
        },
        updatePolicy: {
          $ref: '#/$defs/ModelUpdatePolicy',
          default: 'OnceNewDefaultVersionAvailable',
          description: 'Policy for updating the model',
        },
        maxTokensPerMinuteThousands: {
          default: 1,
          description: 'Tokens-per-Minute (TPM) measured in thousands',
          maximum: 128,
          minimum: 1,
          title: 'tokens per minute (thousands)',
          type: 'integer',
        },
      },
      required: ['name', 'model'],
      title: 'OpenAIDeplyomentConfig',
      type: 'object',
    },
    OpenAIEmbeddingConfig: {
      properties: {
        name: {
          description: 'Name of the deployment',
          title: 'Name',
          type: 'string',
        },
        model: {
          $ref: '#/$defs/OpenAIEmbeddingModel',
          description: 'Model to deploy',
        },
        updatePolicy: {
          $ref: '#/$defs/ModelUpdatePolicy',
          default: 'NoAutoUpgrade',
          description: 'Policy for updating the model',
        },
        maxTokensPerMinuteThousands: {
          default: 1,
          description: 'Tokens-per-Minute (TPM) measured in thousands',
          maximum: 128,
          minimum: 1,
          title: 'tokens per minute in thousands',
          type: 'integer',
        },
      },
      required: ['name', 'model'],
      title: 'OpenAIEmbeddingConfig',
      type: 'object',
    },
    OpenAIEmbeddingModel: {
      enum: ['text-embedding-ada-002', 'text-embedding-3-large'],
      title: 'OpenAIEmbeddingModel',
      type: 'string',
    },
    OpenAIModel: {
      enum: [
        'gpt-4o-2024-08-06',
        'gpt-4o-2024-05-13',
        'gpt-4o-mini-2024-07-18',
        'gpt-4-turbo-2024-04-09',
        'gpt-35-turbo-1106',
      ],
      title: 'OpenAIModel',
      type: 'string',
    },
    OpenAiConfig: {
      properties: {
        kind: {
          const: 'openai',
          default: 'openai',
          description: 'Module discriminator',
          enum: ['openai'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chatDeployments: {
          description: 'Chat models to deploy',
          items: {
            $ref: '#/$defs/OpenAIDeplyomentConfig',
          },
          title: 'Chatdeployments',
          type: 'array',
        },
        embeddingDeployments: {
          description: 'Embedding models to deploy',
          items: {
            $ref: '#/$defs/OpenAIEmbeddingConfig',
          },
          title: 'Embeddingdeployments',
          type: 'array',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'swedencentral',
          description: 'Region where cognitive services should be provisioned.',
          title: 'Azure region',
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
      },
      required: ['kind'],
      title: 'Azure Open AI',
      type: 'object',
    },
    OpenTelemetryConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/OpenTelemetryConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_OpenTelemetryConfigBase_',
            },
          ],
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
      },
      required: ['config'],
      title: 'OpenTelemetryConfig',
      type: 'object',
    },
    OpenTelemetryConfigBase: {
      properties: {
        enabled: {
          default: false,
          title: 'Enable OpenTelemetry.',
          type: 'boolean',
        },
        collector_chart_version: {
          default: '0.108.0',
          title: 'The version of the OpenTelemetry collector chart to install.',
          type: 'string',
        },
      },
      title: 'OpenTelemetryConfigBase',
      type: 'object',
    },
    PathMatchType: {
      enum: ['prefix', 'exact'],
      title: 'PathMatchType',
      type: 'string',
    },
    PlatformEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        manifest: {
          $ref: '#/$defs/PlatformManifest',
        },
        report: {
          $ref: '#/$defs/PlatformReport',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
        resources: {
          $ref: '#/$defs/ResourcesReport',
          title: 'Resources.',
        },
      },
      required: ['stackVersion', 'manifest', 'report', 'policies', 'resources'],
      title: 'PlatformEntry',
      type: 'object',
    },
    PlatformGroups: {
      properties: {
        contributors: {
          $ref: '#/$defs/GroupInfo',
        },
        readers: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: ['contributors', 'readers'],
      title: 'PlatformGroups',
      type: 'object',
    },
    PlatformManifest: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'PlatformManifest',
          default: 'PlatformManifest',
          enum: ['PlatformManifest'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/PlatformSpec',
          description: 'Platform specification.',
          title: 'Platform specification',
        },
        instances: {
          $ref: '#/$defs/InstanceConfig',
          description: 'The instances of the platform.',
          title: 'Instances',
        },
        clusterSetup: {
          $ref: '#/$defs/ClusterSetup',
          description: 'The setup of the cluster.',
          title: 'Cluster setup',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'PlatformManifest',
      type: 'object',
    },
    PlatformReport: {
      properties: {
        groups: {
          $ref: '#/$defs/PlatformGroups',
        },
        instances: {
          items: {
            $ref: '#/$defs/InstanceLayout',
          },
          title: 'Instances',
          type: 'array',
        },
        datadog: {
          anyOf: [
            {
              $ref: '#/$defs/DataDogIntegrationInfo',
            },
            {
              type: 'null',
            },
          ],
          default: null,
        },
      },
      required: ['groups', 'instances'],
      title: 'PlatformReport',
      type: 'object',
    },
    PlatformSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        devTeams: {
          description: 'Platform teams assigned platform level roles.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Development teams',
          type: 'array',
        },
        teams: {
          description:
            'Teams that are assigned permissions on the platform instance.',
          items: {
            $ref: '#/$defs/PlatformTeam',
          },
          title: 'Platform teams',
          type: 'array',
        },
      },
      required: ['owner', 'primaryContact', 'secondaryContact', 'uniqueKey'],
      title: 'PlatformSpec',
      type: 'object',
    },
    PlatformTeam: {
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/PlatformTeamRole',
        },
        objectId: {
          format: 'uuid',
          title: 'Object ID',
          type: 'string',
        },
      },
      required: ['name', 'description', 'role', 'objectId'],
      title: 'PlatformTeam',
      type: 'object',
    },
    PlatformTeamRole: {
      enum: [
        'admin',
        'infra-admin',
        'developer',
        'stakeholder',
        'firefighter',
        'product-owner',
      ],
      title: 'PlatformTeamRole',
      type: 'string',
    },
    PolicyReport: {
      properties: {
        permissions: {
          description:
            'List of role assignments defined in the synthetized stack.',
          items: {
            $ref: '#/$defs/RoleAssignmentInfo',
          },
          title: 'Role Assignments',
          type: 'array',
        },
      },
      required: ['permissions'],
      title: 'PolicyReport',
      type: 'object',
    },
    PolicyReportEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        report: {
          $ref: '#/$defs/PolicyReport',
        },
      },
      required: ['stackVersion', 'report'],
      title: 'PolicyReportEntry',
      type: 'object',
    },
    PortNumber: {
      maximum: 65535,
      minimum: 1,
      type: 'integer',
    },
    PortalResources: {
      properties: {
        techdocsContainer: {
          $ref: '#/$defs/StorageContainerResource',
          title: 'TechDocs container',
        },
        templatesContainer: {
          anyOf: [
            {
              $ref: '#/$defs/StorageContainerResource',
            },
            {
              type: 'null',
            },
          ],
          title: 'Templates container',
        },
      },
      required: ['techdocsContainer', 'templatesContainer'],
      title: 'PortalResources',
      type: 'object',
    },
    PostgresConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_PostgresConfigBase_',
            },
          ],
          default: {
            version: '14',
            skuName: 'B_Standard_B1ms',
            storageSize: 32768,
            databases: [],
            backupRetentionDays: 7,
            additionalAdminGroup: {},
            ownerGroups: [],
            serverParameters: null,
          },
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        kind: {
          const: 'postgres',
          default: 'postgres',
          description: 'Module discriminator',
          enum: ['postgres'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
      },
      required: ['kind'],
      title: 'Postgres Server',
      type: 'object',
    },
    PostgresConfigBase: {
      description:
        'Allows provisioning Postgres databases for use in product services.',
      properties: {
        version: {
          $ref: '#/$defs/PostgresVersion',
          default: '14',
          description: 'Version of postgres server to use.',
          title: 'Postgres version',
        },
        skuName: {
          $ref: '#/$defs/PostgresSku',
          default: 'B_Standard_B1ms',
          description:
            'SKU name to be used as base model for the Postgres server.',
          title: 'Postgres SKU',
        },
        storageSize: {
          $ref: '#/$defs/PostgresStorageSize',
          default: 32768,
          description: 'Size of the Postgres storage in MB',
          title: 'Database storage capacity',
        },
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Provisioned databases',
          type: 'array',
          'vsc:action': 'array',
        },
        backupRetentionDays: {
          default: 7,
          description: 'Number of days the automatic Postgres backup is kept',
          maximum: 35,
          minimum: 7,
          title: 'Backup retention',
          type: 'integer',
        },
        additionalAdminGroup: {
          additionalProperties: {
            format: 'uuid',
            type: 'string',
          },
          default: {},
          deprecated: true,
          description:
            'Additional administrators to be mapped to Azure Groups.',
          title: 'Additionaladmingroup',
          type: 'object',
        },
        ownerGroups: {
          default: [],
          deprecated: true,
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Ownergroups',
          type: 'array',
        },
        serverParameters: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresServerParameters',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Configurable server parameters.',
        },
      },
      title: 'PostgresConfigBase',
      type: 'object',
    },
    PostgresDatabaseResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'postgres-db',
          default: 'postgres-db',
          enum: ['postgres-db'],
          title: 'Kind',
          type: 'string',
        },
        charset: {
          description: 'The character set to use for the database.',
          title: 'Character set',
          type: 'string',
        },
        collation: {
          description: 'The collation to use for the database.',
          title: 'Collation',
          type: 'string',
        },
      },
      required: ['kind', 'resourceId'],
      title: 'PostgresDatabaseResource',
      type: 'object',
    },
    PostgresServerParameters: {
      description:
        'The Postgres server parameters. See [documentation]\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-server-parameters',
      properties: {
        maxConnections: {
          anyOf: [
            {
              maximum: 5000,
              minimum: 25,
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The maximum number of concurrent connections to the database server.',
          title: 'Maxconnections',
        },
      },
      title: 'PostgresServerParameters',
      type: 'object',
    },
    PostgresServerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'postgres',
          default: 'postgres',
          enum: ['postgres'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the Postgres server is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        fqdn: {
          description: 'Fully qualified domain name of the server.',
          title: 'FQDN',
          type: 'string',
        },
        skuName: {
          description: 'The SKU of the Postgres server.',
          title: 'SKU name',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'fqdn', 'skuName', 'resourceId'],
      title: 'PostgresServerResource',
      type: 'object',
    },
    PostgresSku: {
      description:
        'The Postgres server SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-compute-storage#compute-tiers-vcores-and-server-types',
      enum: [
        'B_Standard_B1ms',
        'B_Standard_B2s',
        'B_Standard_B2ms',
        'GP_Standard_D2ds_v5',
      ],
      title: 'PostgresSku',
      type: 'string',
    },
    PostgresStorageSize: {
      enum: [
        32768, 65536, 131072, 262144, 524288, 1048576, 2097152, 4193280,
        4194304, 8388608, 16777216, 33553408,
      ],
      type: 'integer',
    },
    PostgresVersion: {
      const: '14',
      description:
        'The Postgres server version to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-supported-versions',
      enum: ['14'],
      title: 'PostgresVersion',
      type: 'string',
    },
    ProductCellManifest: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'ProductCellManifest',
          default: 'ProductCellManifest',
          enum: ['ProductCellManifest'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/ProductCellSpec',
          description: 'Platform specification.',
          title: 'Platform specification',
        },
        clusterSetup: {
          $ref: '#/$defs/ClusterSetup',
          description: 'The setup of the cluster.',
          title: 'Cluster setup',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'ProductCellManifest',
      type: 'object',
    },
    ProductCellSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        instance: {
          description: 'Platform isntance name.',
          title: 'Platform instance',
          type: 'string',
        },
      },
      required: [
        'owner',
        'primaryContact',
        'secondaryContact',
        'uniqueKey',
        'instance',
      ],
      title: 'ProductCellSpec',
      type: 'object',
    },
    ProductEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/ProductReport',
        },
        input: {
          $ref: '#/$defs/ProductManifest',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
      },
      required: ['stackVersion', 'output', 'input', 'policies'],
      title: 'ProductEntry',
      type: 'object',
    },
    ProductManifest: {
      description: 'Top level configuration for Argus products.',
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'ProductManifest',
          default: 'ProductManifest',
          enum: ['ProductManifest'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/ProductSpec',
        },
        capabilities: {
          default: [],
          description: 'Product capabilities',
          items: {
            discriminator: {
              mapping: {
                kusto: '#/$defs/KustoConfig',
                mysql: '#/$defs/MysqlConfig',
                'object-store': '#/$defs/StorageConfig',
                postgres: '#/$defs/PostgresConfig',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/MysqlConfig',
              },
              {
                $ref: '#/$defs/PostgresConfig',
              },
              {
                $ref: '#/$defs/StorageConfig',
              },
              {
                $ref: '#/$defs/KustoConfig',
              },
            ],
          },
          title: 'Product capabilities',
          type: 'array',
        },
        modules: {
          default: [],
          description:
            'Modules provide functionalities and experiences inside a product context.',
          items: {
            discriminator: {
              mapping: {
                'ai-multi-service': '#/$defs/AzureAIServicesConfig',
                'ai-search': '#/$defs/AISearchServiceConfigStandard',
                'ai-search-basic': '#/$defs/AISearchServiceConfigBasic',
                arango: '#/$defs/ArangoConfig',
                cloudbeaver: '#/$defs/CloudBeaverConfig',
                dagster: '#/$defs/DagsterConfig',
                jupyter: '#/$defs/JupyterHubConfig',
                memgraph: '#/$defs/MemgraphConfig',
                mlflow: '#/$defs/MlflowConfig',
                openai: '#/$defs/OpenAiConfig',
                redis: '#/$defs/RedisConfig',
                superset: '#/$defs/SupersetConfig',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/DagsterConfig',
              },
              {
                $ref: '#/$defs/MlflowConfig',
              },
              {
                $ref: '#/$defs/MemgraphConfig',
              },
              {
                $ref: '#/$defs/JupyterHubConfig',
              },
              {
                $ref: '#/$defs/ArangoConfig',
              },
              {
                $ref: '#/$defs/SupersetConfig',
              },
              {
                $ref: '#/$defs/CloudBeaverConfig',
              },
              {
                $ref: '#/$defs/OpenAiConfig',
              },
              {
                $ref: '#/$defs/AzureAIServicesConfig',
              },
              {
                $ref: '#/$defs/AISearchServiceConfigBasic',
              },
              {
                $ref: '#/$defs/AISearchServiceConfigStandard',
              },
              {
                $ref: '#/$defs/RedisConfig',
              },
            ],
          },
          title: 'Product modules',
          type: 'array',
        },
        routing: {
          $ref: '#/$defs/RoutingConfig',
          default: {
            exposedServices: [],
            linkedProducts: [],
            subDomainOverride: null,
          },
          description: 'Manage traffic in and out of a product namespace.',
          title: 'Routing',
        },
        codeLocations: {
          description: 'List of code locations for the product.',
          items: {
            $ref: '#/$defs/CodeLocation',
          },
          title: 'Code locations',
          type: 'array',
        },
        application: {
          $ref: '#/$defs/ApplicationConfig',
          default: {
            requiredResourceAccess: [],
            extraRedirectUrls: {},
          },
          description: 'Application configuration for the product.',
          title: 'Application',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'ProductManifest',
      type: 'object',
    },
    ProductReport: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'ProductCatalogEntry',
          default: 'ProductCatalogEntry',
          enum: ['ProductCatalogEntry'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/Metadata',
          description: 'Basic metadata about the product.',
          title: 'Product metadata.',
        },
        identity: {
          $ref: '#/$defs/IdentityInfo',
          description:
            'Default identity to use for accessing product resources.',
          title: 'Product identity.',
        },
        k8sRelease: {
          anyOf: [
            {
              $ref: '#/$defs/K8sReleaseInfo',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Basic information about K8s environment for the product.',
          title: 'K8s release info.',
        },
        resources: {
          description: 'Azure resources provisioned for the product.',
          items: {
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
            ],
          },
          title: 'Product resources.',
          type: 'array',
        },
        routing: {
          $ref: '#/$defs/RoutingReport',
          description: 'List of exposed services.',
          title: 'Product routing.',
        },
      },
      required: ['kind', 'metadata', 'identity', 'k8sRelease', 'resources'],
      title: 'ProductReport',
      type: 'object',
    },
    ProductSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        workspace: {
          description: 'Name of the workspace this product is managed by.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'Workspace name',
          type: 'string',
        },
        resourceQuotaRequests: {
          $ref: '#/$defs/K8sResources',
          description: 'Requested Kubernetes resources for product namespace.',
          title: 'Resource requests',
        },
        resourceQuotaLimits: {
          $ref: '#/$defs/K8sResources',
          description:
            'Upper limit for Kubernetes resources for product namespace.',
          title: 'Resource limits',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
      },
      required: [
        'owner',
        'primaryContact',
        'secondaryContact',
        'uniqueKey',
        'workspace',
      ],
      title: 'ProductSpec',
      type: 'object',
    },
    PublicIpResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'public-ip',
          default: 'public-ip',
          enum: ['public-ip'],
          title: 'Kind',
          type: 'string',
        },
        publicIp: {
          description: 'Public IP address of the network.',
          title: 'Publicip',
          type: 'string',
        },
      },
      required: ['resourceId', 'publicIp'],
      title: 'PublicIpResource',
      type: 'object',
    },
    RFC1035Name: {
      maxLength: 62,
      minLength: 1,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    RedisArchitecture: {
      enum: ['standalone', 'replication'],
      title: 'RedisArchitecture',
      type: 'string',
    },
    RedisConfig: {
      properties: {
        kind: {
          const: 'redis',
          default: 'redis',
          description: 'Module discriminator',
          enum: ['redis'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        architecture: {
          $ref: '#/$defs/RedisArchitecture',
          default: 'standalone',
          title: 'Architecture',
        },
        version: {
          default: '19.6.0',
          title: 'Redis Helm chart version',
          type: 'string',
        },
        imageTag: {
          default: '7.2.0',
          title: 'Redis version to use',
          type: 'string',
        },
        storageSize: {
          default: '1Gi',
          title: 'Redis persistence volume size',
          type: 'string',
        },
      },
      required: ['kind'],
      title: 'RedisConfig',
      type: 'object',
    },
    RegistryAgentReport: {
      properties: {
        targetCell: {
          $ref: '#/$defs/InstanceCellRef',
          description: 'The cell the agent can deploy to.',
          title: 'Target cell',
        },
        objectId: {
          description: 'The object ID of the service principal.',
          title: 'Object ID',
          type: 'string',
        },
        repositories: {
          description: 'The repositories the service principal has access to.',
          items: {
            type: 'string',
          },
          title: 'Repositories',
          type: 'array',
        },
        environmentName: {
          description:
            'The name of the environment the service principal is used in.',
          title: 'Environment name',
          type: 'string',
        },
      },
      required: ['targetCell', 'objectId', 'environmentName'],
      title: 'RegistryAgentReport',
      type: 'object',
    },
    ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_: {
      properties: {
        resource: {
          const: 'microsoft-graph',
          description: 'The Azure resource for which the access is configured.',
          enum: ['microsoft-graph'],
          title: 'Azure resource',
          type: 'string',
        },
        resourceAccess: {
          description: 'Access configuration for the selected resource.',
          items: {
            $ref: '#/$defs/MsGraphPermission',
          },
          title: 'Resource access',
          type: 'array',
          uniqueItems: true,
        },
      },
      required: ['resource'],
      title:
        "ResourceAccessConfig[Literal[<AzureResource.MS_GRAPH: 'microsoft-graph'>], MsGraphPermission]",
      type: 'object',
    },
    ResourceGroupResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        location: {
          description: 'Azure region where the resource group is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        kind: {
          const: 'resource-group',
          default: 'resource-group',
          enum: ['resource-group'],
          title: 'Kind',
          type: 'string',
        },
      },
      required: ['resourceId', 'location'],
      title: 'ResourceGroupResource',
      type: 'object',
    },
    ResourcesReport: {
      properties: {
        resources: {
          description: 'List of azure resources provisioned in the stack.',
          items: {
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
            ],
          },
          title: 'Azure resources',
          type: 'array',
        },
        resourceLocations: {
          additionalProperties: {
            type: 'string',
          },
          description:
            'Mapping of a node in the construct tree to the resource id.',
          title: 'Resource locations',
          type: 'object',
        },
      },
      required: ['resources', 'resourceLocations'],
      title: 'ResourcesReport',
      type: 'object',
    },
    ResourcesReportEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        report: {
          $ref: '#/$defs/ResourcesReport',
        },
      },
      required: ['stackVersion', 'report'],
      title: 'ResourcesReportEntry',
      type: 'object',
    },
    RoleAssignmentInfo: {
      properties: {
        roleDefinitionName: {
          title: 'Roledefinitionname',
          type: 'string',
        },
        roleDefinitionId: {
          title: 'Roledefinitionid',
          type: 'string',
        },
        resourceId: {
          description:
            'Azure Resource Manager ID of the resource this role applies to.',
          title: 'Resource id.',
          type: 'string',
        },
        principalId: {
          title: 'Principalid',
          type: 'string',
        },
        principalType: {
          title: 'Principaltype',
          type: 'string',
        },
        description: {
          description:
            'A short description on why the role assignment is required.',
          title: 'Motivation',
          type: 'string',
        },
      },
      required: [
        'roleDefinitionName',
        'roleDefinitionId',
        'resourceId',
        'principalId',
        'principalType',
        'description',
      ],
      title: 'RoleAssignmentInfo',
      type: 'object',
    },
    RoutingConfig: {
      properties: {
        exposedServices: {
          description: 'Product services exposed to clients or other products.',
          items: {
            $ref: '#/$defs/ExposedServiceConfig',
          },
          title: 'Exposed services',
          type: 'array',
        },
        linkedProducts: {
          description:
            'Products that are allowed to invoke services from this product.',
          items: {
            $ref: '#/$defs/EntityName',
          },
          title: 'Linked products',
          type: 'array',
        },
        subDomainOverride: {
          anyOf: [
            {
              $ref: '#/$defs/SubDomain',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Custom sub-domain for apps and services deployed in the product.',
          title: 'Subdomain override',
        },
      },
      title: 'RoutingConfig',
      type: 'object',
    },
    RoutingReport: {
      properties: {
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage this routing report is for.',
          title: 'Logical stage',
        },
        services: {
          items: {
            $ref: '#/$defs/ExposedServiceEntry',
          },
          title: 'Services',
          type: 'array',
        },
      },
      required: ['logicalStage', 'services'],
      title: 'RoutingReport',
      type: 'object',
    },
    RoutingRule: {
      properties: {
        path: {
          $ref: '#/$defs/UrlPathSegment',
          title: 'Matched path segment.',
        },
        pathMatch: {
          $ref: '#/$defs/PathMatchType',
          default: 'prefix',
          title: 'Path matcher type.',
        },
        pathRewrite: {
          anyOf: [
            {
              $ref: '#/$defs/UrlPathSegment',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Rewrite matched path before sending request to downstream service.',
          title: 'Path rewrite',
        },
        allowedGroups: {
          default: [],
          description: 'Restrict access to this route to specific AAD groups.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        timeout: {
          default: 15,
          title: 'Timeout in seconds: e.g. 5',
          type: 'integer',
        },
        unsafeDisableAuth: {
          default: false,
          description: 'UNSAFE - Disable authentication for this route.',
          title: 'Disable authentication',
          type: 'boolean',
        },
        autoHostRewrite: {
          anyOf: [
            {
              type: 'boolean',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Rewrite the host header to match the target service host.',
          title: 'Auto host rewrite',
        },
      },
      required: ['path'],
      title: 'RoutingRule',
      type: 'object',
    },
    RuleSet: {
      enum: ['base', 'strict'],
      title: 'RuleSet',
      type: 'string',
    },
    SharedGroupsReport: {
      properties: {
        workspace_owner: {
          $ref: '#/$defs/GroupInfo',
        },
        product_owner: {
          $ref: '#/$defs/GroupInfo',
        },
        workspace_identities: {
          $ref: '#/$defs/GroupInfo',
        },
        platform_contributors: {
          $ref: '#/$defs/GroupInfo',
        },
        basf_global_members: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: [
        'workspace_owner',
        'product_owner',
        'workspace_identities',
        'platform_contributors',
        'basf_global_members',
      ],
      title: 'SharedGroupsReport',
      type: 'object',
    },
    StagedConfigDict_CertManagerConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/CertManagerConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/CertManagerConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[CertManagerConfigBase]',
      type: 'object',
    },
    StagedConfigDict_InstanceSpec_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/InstanceSpec',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/InstanceSpec',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[InstanceSpec]',
      type: 'object',
    },
    StagedConfigDict_KustoConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/KustoConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/KustoConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[KustoConfigBase]',
      type: 'object',
    },
    StagedConfigDict_MysqlConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[MysqlConfigBase]',
      type: 'object',
    },
    StagedConfigDict_OpenTelemetryConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/OpenTelemetryConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/OpenTelemetryConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[OpenTelemetryConfigBase]',
      type: 'object',
    },
    StagedConfigDict_PostgresConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[PostgresConfigBase]',
      type: 'object',
    },
    StagedConfigDict_StorageConfigBase_: {
      properties: {
        development: {
          anyOf: [
            {
              $ref: '#/$defs/StorageConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Development configuration',
        },
        production: {
          anyOf: [
            {
              $ref: '#/$defs/StorageConfigBase',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Production configuration',
        },
      },
      title: 'StagedConfigDict[StorageConfigBase]',
      type: 'object',
    },
    StorageAccountResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'storage-account',
          default: 'storage-account',
          enum: ['storage-account'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the storage account is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        hnsEnabled: {
          title: 'Hnsenabled',
          type: 'boolean',
        },
        replicationType: {
          title: 'Replicationtype',
          type: 'string',
        },
      },
      required: [
        'kind',
        'location',
        'hnsEnabled',
        'replicationType',
        'resourceId',
      ],
      title: 'StorageAccountResource',
      type: 'object',
    },
    StorageConfig: {
      properties: {
        config: {
          anyOf: [
            {
              $ref: '#/$defs/StorageConfigBase',
            },
            {
              $ref: '#/$defs/StagedConfigDict_StorageConfigBase_',
            },
          ],
          default: {
            externalAccess: false,
            containers: [],
            accountDataContributors: [],
            containerRoleAssignments: [],
          },
          description: 'Configuration for the resource',
          title: 'Resource configuration',
        },
        kind: {
          const: 'object-store',
          default: 'object-store',
          description: 'Module discriminator',
          enum: ['object-store'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
      },
      required: ['kind'],
      title: 'Azure Storage Account',
      type: 'object',
    },
    StorageConfigBase: {
      description:
        'Allows provisioning storage containers within Azure storage accounts.\n\nThe `storage` capability provisions a [storage account] and enables users and\nmodules to request containers in that account.\n\n[storage account]: https://learn.microsoft.com/en-us/azure/storage/blobs/storage-blobs-introduction',
      properties: {
        externalAccess: {
          default: false,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        containers: {
          default: [],
          description:
            'Storage containers to be created in the account.\n\n### Warning: Reserved names\n\nDo not use the reserved container names `argus*`, `dagster`, `mlflow`.\nThis may lead to unexpected behavior until we have implemented conflict\nresolution on container names.',
          items: {
            $ref: '#/$defs/StorageContainerName',
          },
          title: 'Storage containers.',
          type: 'array',
          'vsc:action': 'array',
        },
        accountDataContributors: {
          default: [],
          description:
            'Object IDs for all principals to get account level data access.',
          items: {
            description: 'AAD object ID of the group.',
            format: 'uuid',
            title: 'AAD group',
            type: 'string',
            'ui:field': 'AzureGroupSearch',
            'vsc:picker': 'aad-group',
          },
          title: 'Account data contributors.',
          type: 'array',
          'vsc:action': 'array',
        },
        containerRoleAssignments: {
          default: [],
          description: 'Container specific role assignments.',
          items: {
            $ref: '#/$defs/StorageContainerRoleAssignment',
          },
          title: 'Container role assignments.',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      title: 'StorageConfigBase',
      type: 'object',
    },
    StorageContainerName: {
      maxLength: 63,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    StorageContainerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'storage-container',
          default: 'storage-container',
          enum: ['storage-container'],
          title: 'Kind',
          type: 'string',
        },
        url: {
          description: 'The URL of the storage container.',
          title: 'URL',
          type: 'string',
        },
      },
      required: ['kind', 'url', 'resourceId'],
      title: 'StorageContainerResource',
      type: 'object',
    },
    StorageContainerRole: {
      description:
        'Available storage roles to be assigned, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/role-based-access-control/built-in-roles',
      enum: [
        'Storage Blob Data Contributor',
        'Storage Blob Data Owner',
        'Storage Blob Data Reader',
      ],
      title: 'StorageContainerRole',
      type: 'string',
    },
    StorageContainerRoleAssignment: {
      properties: {
        objectId: {
          description: 'AAD entity for which the role is to be assigned.',
          format: 'uuid',
          title: 'Objectid',
          type: 'string',
        },
        containerName: {
          $ref: '#/$defs/StorageContainerName',
          description: 'The storage container this role applies to.',
        },
        role: {
          $ref: '#/$defs/StorageContainerRole',
          description: 'Role to be assigned to principal.',
        },
      },
      required: ['objectId', 'containerName', 'role'],
      title: 'StorageContainerRoleAssignment',
      type: 'object',
    },
    SubDomain: {
      maxLength: 50,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    SupersetConfig: {
      description:
        'An open-source modern data exploration and visualization platform.',
      properties: {
        kind: {
          const: 'superset',
          default: 'superset',
          description: 'Module discriminator',
          enum: ['superset'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '0.10.10',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Superset UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        globalResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '256Mi',
            },
            limits: {
              cpu: '500m',
              memory: '512Mi',
            },
          },
          description: 'Default Superset worker resources',
          title: 'Default Superset worker resources',
        },
        workerResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '512Mi',
            },
            limits: {
              cpu: '500m',
              memory: '1Gi',
            },
          },
          description: 'Default Superset worker resources',
          title: 'Default Superset worker resources',
        },
        nodeResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '100m',
              memory: '128Mi',
            },
            limits: {
              cpu: '200m',
              memory: '256Mi',
            },
          },
          description: 'Default Superset node resources',
          title: 'Default Superset node resources',
        },
      },
      required: ['kind'],
      title: 'Superset',
      type: 'object',
    },
    TeamRole: {
      enum: ['developers', 'admins'],
      title: 'TeamRole',
      type: 'string',
    },
    UniqueKeyType: {
      maxLength: 3,
      minLength: 3,
      pattern: '^[a-z]*$',
      type: 'string',
    },
    UrlPathSegment: {
      pattern: '^\\/[a-zA-Z0-9_\\.\\/\\-]*?$',
      type: 'string',
    },
    UserEnvironment: {
      properties: {
        image: {
          description:
            'Docker image to be used when spawning this environment.',
          title: 'Base image',
          type: 'string',
        },
        tag: {
          default: 'latest',
          description: 'User image tag to be used',
          title: 'Image tag.',
          type: 'string',
        },
        displayName: {
          description: 'User image display name to be used',
          title: 'Display name.',
          type: 'string',
        },
        description: {
          description: 'User image description to be used',
          title: 'Description',
          type: 'string',
        },
      },
      required: ['image'],
      title: 'UserEnvironment',
      type: 'object',
    },
    Workflow: {
      const: 'github-flow',
      description: 'Workflow configured on a code location.',
      enum: ['github-flow'],
      title: 'Workflow',
      type: 'string',
    },
    WorkspaceEntry: {
      properties: {
        stackVersion: {
          title: 'Stackversion',
          type: 'string',
        },
        signature: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Signature',
        },
        output: {
          $ref: '#/$defs/WorkspaceReport',
        },
        input: {
          $ref: '#/$defs/WorkspaceManifest',
        },
        policies: {
          $ref: '#/$defs/PolicyReport',
          title: 'Policies.',
        },
      },
      required: ['stackVersion', 'output', 'input', 'policies'],
      title: 'WorkspaceEntry',
      type: 'object',
    },
    WorkspaceManifest: {
      description: 'Manifest for a workspace in devhub.',
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'WorkspaceManifest',
          default: 'WorkspaceManifest',
          enum: ['WorkspaceManifest'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Metadata for the entity.',
          title: 'Metadata',
        },
        spec: {
          $ref: '#/$defs/WorkspaceSpec',
          description: 'Workspace specification.',
          title: 'Workspace specification',
        },
      },
      required: ['spec', 'metadata', 'apiVersion', 'kind'],
      title: 'WorkspaceManifest',
      type: 'object',
    },
    WorkspaceReport: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'WorkspaceCatalogEntry',
          default: 'WorkspaceCatalogEntry',
          enum: ['WorkspaceCatalogEntry'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Basic metadata about the workspace.',
          title: 'Workspace metadata.',
        },
        identity: {
          $ref: '#/$defs/IdentityInfo',
          description: 'Identity information for the workspace.',
          title: 'Workspace identity.',
        },
        application: {
          $ref: '#/$defs/ApplicationInfo',
          description: 'Application information for the workspace.',
          title: 'Workspace application.',
        },
        devops: {
          anyOf: [
            {
              $ref: '#/$defs/DevOpsOutput',
            },
            {
              type: 'null',
            },
          ],
          default: null,
        },
        githubTeams: {
          description: 'GitHub teams associated with the workspace.',
          items: {
            $ref: '#/$defs/GitHubTeamInfo',
          },
          title: 'GitHub teams.',
          type: 'array',
        },
        resources: {
          description: 'Azure resources provisioned for the workspace.',
          items: {
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
            ],
          },
          title: 'Workspace resources.',
          type: 'array',
        },
      },
      required: ['kind', 'metadata', 'identity', 'application', 'resources'],
      title: 'WorkspaceReport',
      type: 'object',
    },
    WorkspaceSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
        devOps: {
          anyOf: [
            {
              $ref: '#/$defs/AzureDevOpsProject',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Azure DevOps project, where workspace presence is to be installed.',
          title: 'Azure DevOps project',
        },
        devTeams: {
          description:
            'List of development teams that should have access to the workspace.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Development teams',
          type: 'array',
        },
        linkedTeams: {
          description:
            'List of teams that are assigned permissions on workspace repositories.',
          items: {
            $ref: '#/$defs/LinkedGitHubTeamConfig',
          },
          title: 'Linked GitHub teams',
          type: 'array',
        },
      },
      required: ['owner', 'primaryContact', 'secondaryContact', 'uniqueKey'],
      title: 'WorkspaceSpec',
      type: 'object',
    },
  },
  title: 'CatalogEntries',
  oneOf: [
    {
      $ref: '#/$defs/ProductManifest',
    },
    {
      $ref: '#/$defs/WorkspaceManifest',
    },
    {
      $ref: '#/$defs/PlatformManifest',
    },
    {
      $ref: '#/$defs/GitHubEnterpriseManifest',
    },
    {
      $ref: '#/$defs/ProductCellManifest',
    },
    {
      $ref: '#/$defs/PlatformEntry',
    },
    {
      $ref: '#/$defs/InfrastructureCellEntry',
    },
    {
      $ref: '#/$defs/GatewayEntry',
    },
    {
      $ref: '#/$defs/ProductEntry',
    },
    {
      $ref: '#/$defs/WorkspaceEntry',
    },
    {
      $ref: '#/$defs/GitHubEnterpriseEntry',
    },
    {
      $ref: '#/$defs/PolicyReportEntry',
    },
    {
      $ref: '#/$defs/ResourcesReportEntry',
    },
    {
      $ref: '#/$defs/InstanceEntry',
    },
    {
      $ref: '#/$defs/InstanceCellEntry',
    },
    {
      $ref: '#/$defs/InstanceRegistryReport',
    },
    {
      $ref: '#/$defs/RegistryAgentReport',
    },
    {
      $ref: '#/$defs/CellClusterReport',
    },
    {
      $ref: '#/$defs/InstanceCellReport',
    },
    {
      $ref: '#/$defs/DeployedInstanceLayout',
    },
    {
      $ref: '#/$defs/InstanceResources',
    },
    {
      $ref: '#/$defs/InstanceGroups',
    },
  ],
  type: 'object',
} as const;
