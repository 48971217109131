import { EmptyState } from '@backstage/core-components';
import { useEntityPermission } from '@backstage/plugin-catalog-react/alpha';
import { provisioningManageEntityPermission } from '@internal/plugin-permissions-common';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC } from 'react';

type RequireOwnershipProps = {
  children?: React.ReactNode;
};

const missingPermission = (
  <EmptyState
    title="You are not allowed to view this entity"
    description="Only members of the owner group are able to access this entity."
    missing="data"
  />
);

export const RequireOwnership: FC<RequireOwnershipProps> = ({ children }) => {
  const permission = useEntityPermission(provisioningManageEntityPermission);

  const content = () => {
    if (!permission.loading) {
      if (permission.allowed) {
        return children;
      }
      return missingPermission;
    }
    return <CircularProgress />;
  };

  return <>{content()}</>;
};
