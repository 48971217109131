import { useCallback } from 'react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useSuspenseQuery } from '@tanstack/react-query';
import { utils, ProductInfo } from '@provisioning/common';
import { provisioningCatalogApiRef } from '../api';

interface UseProductsProps {
  owned?: boolean;
}

interface UseProductsOutput {
  data: ProductInfo[];
  error: Error | null;
}

export function useProducts({
  owned = false,
}: UseProductsProps): UseProductsOutput {
  const catalogApi = useApi(provisioningCatalogApiRef);
  const identityApi = useApi(identityApiRef);

  const queryFn = useCallback(async () => {
    const identity = await identityApi.getBackstageIdentity();
    const { token } = await identityApi.getCredentials();
    return catalogApi
      .listProductEntities({
        token,
        filter: owned ? { 'spec.owner': identity.ownershipEntityRefs } : {},
      })
      .then(results => results.entities.map(utils.getProductInfo));
  }, [catalogApi, identityApi, owned]);

  const { data, error } = useSuspenseQuery({
    queryKey: [owned ? 'owned-products' : 'all-products'],
    queryFn,
    // Refetch the data every 10 second
    refetchInterval: 10000,
  });

  return { data, error };
}
