import { useState, useEffect } from 'react';

export const KEY_DARK_THEME = 'basf-dark-theme';
export const KEY_LIGHT_THEME = 'basf-light-theme';

export function useTheme() {
  const defaultDarkTheme =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useState(
    defaultDarkTheme ? KEY_DARK_THEME : KEY_LIGHT_THEME,
  );

  useEffect(() => {
    if (localStorage.getItem('theme') === KEY_DARK_THEME) {
      setTheme(KEY_DARK_THEME);
    }
    if (localStorage.getItem('theme') === KEY_LIGHT_THEME) {
      setTheme(KEY_LIGHT_THEME);
    }
  }, []);

  return theme;
}
