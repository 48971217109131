import { Ajv2020, Logger } from 'ajv/dist/2020';
import { AnyValidateFunction } from 'ajv/dist/core';
import {
  AnyCapabilityConfig,
  AnyManifest,
  schemasAnyCapabilityConfig,
  schemasAnyModuleConfig,
  getValidator,
  isPlatformManifest,
  isProductManifest,
  isWorkspaceManifest,
  AnyModuleConfig,
  ManifestMetadata,
  AnyModuleConfigKind,
  AnyCapabilityConfigKind,
  typesAnyModuleConfig,
  isModuleConfig,
  typesAnyCapabilityConfig,
  typesAnyCapabilityConfigStaged,
  isCapabilityConfig,
  isStagedConfigDict,
  StagedConfigDict,
  getTypeGuard,
} from '../models';
import { checkConstraints, moduleDependencies } from './constraints';
import { registrySchema } from '../models/gen/registrySchema';
import addFormats from 'ajv-formats';
import _ from 'lodash';

// NOTE(packre): we need a separate validator to genearate configs with
// default values added. Due to some complexitites how `anyOf`, `oneOf`
// and `discriminator` are handled, we can't use the same validator for
// both validation and default value generation.
const validatorDefaults = new Ajv2020({
  strict: false,
  // https://ajv.js.org/security.html#security-risks-of-trusted-schemas
  allErrors: false,
  removeAdditional: true,
  coerceTypes: 'array',
  useDefaults: true,
  verbose: false,
  discriminator: false,
  schemas: [registrySchema],
});
addFormats(validatorDefaults);

export class ManifestHandler<T extends AnyManifest> {
  private validateFn: AnyValidateFunction<T>;
  private data: T;

  constructor(options: { schemaRef: string; data: T; logger?: Logger }) {
    const validate = getValidator(options.logger).getSchema<T>(
      options.schemaRef,
    );
    if (!validate) {
      throw new Error(
        `Failed to resolve schema from registry for key: '${options.schemaRef}'`,
      );
    }
    this.validateFn = validate;
    if (!this.validateFn(options.data)) {
      throw new Error('Failed to validate manifest');
    }
    this.data = options.data;
  }

  static fromManifest<U extends AnyManifest>(manifest: U, logger?: Logger) {
    return handlerFromManifest(manifest, logger);
  }

  get manifest() {
    return this.data;
  }

  get metadata() {
    return this.data.metadata;
  }

  validate() {
    return checkConstraints(this.data);
  }

  hasModule(kind: AnyModuleConfigKind) {
    if (!Object.keys(schemasAnyModuleConfig).includes(kind)) {
      throw new Error(`Unknown module kind: '${kind}'`);
    }
    if (!isProductManifest(this.data)) return false;
    return this.data.modules.some(m => m.kind === kind);
  }

  /**
   * This function returns the configuration for a specific module.
   *
   * @param {AnyModuleConfigKind} kind - The kind of the module.
   * @returns {AnyModuleConfig | undefined} - The configuration of the module if it exists, undefined otherwise.
   */
  getModuleConfig<TKind extends AnyModuleConfigKind>(
    kind: TKind,
  ): typesAnyModuleConfig[TKind] | undefined {
    if (!isProductManifest(this.data)) return undefined;
    const config = this.data.modules.find(m => m.kind === kind);
    if (!config) return undefined;
    if (isModuleConfig(config, kind)) {
      return config;
    }
    throw new Error(`Invalid module config for kind: ${kind}`);
  }

  hasCapability(kind: AnyCapabilityConfigKind) {
    if (!Object.keys(schemasAnyCapabilityConfig).includes(kind)) {
      throw new Error(`Unknown capability kind: '${kind}'`);
    }
    if (!isProductManifest(this.data)) return false;
    return this.data.capabilities.some(c => c.kind === kind);
  }

  /**
   * This function returns the configuration for a specific capability.
   *
   * @param {AnyCapabilityConfigKind} kind - The kind of the capability.
   * @returns {AnyCapabilityConfig | undefined} - The configuration of the capability if it exists, undefined otherwise.
   */
  getCapabilityConfig<TKind extends AnyCapabilityConfigKind>(
    kind: TKind,
  ): typesAnyCapabilityConfigStaged[TKind] | undefined {
    if (!isProductManifest(this.data)) return undefined;
    const config = this.data.capabilities.find(c => c.kind === kind);
    if (!config) return undefined;
    if (isCapabilityConfig(config, kind)) {
      return config;
    }
    throw new Error(`Invalid capability config for kind: ${kind}`);
  }

  /**
   * This function adds a capability to the manifest.
   * It validates the passed config based on its kind and throws an error if the validation fails.
   *
   * @param {Pick<C, 'kind'> & Partial<C>} config - The configuration of the capability to be added.
   * @throws {Error} - Throws an error if the manifest is not a product manifest, if the capability already exists,
   * if the capability kind is unknown, or if the capability fails validation.
   */
  addCapability<TKind extends AnyCapabilityConfigKind>(
    kind: TKind,
    config:
      | Partial<typesAnyCapabilityConfig[TKind]>
      | StagedConfigDict<Partial<typesAnyCapabilityConfig[TKind]>> = {},
  ) {
    if (!isProductManifest(this.data)) {
      throw new Error('Capabilities can only be added to product manifests');
    }
    if (this.hasCapability(kind)) {
      throw new Error(`Capability '${kind}' already exists`);
    }
    const schemaRef = schemasAnyCapabilityConfig[kind];
    if (!schemaRef) {
      throw new Error(`Unknown capability kind: ${kind}`);
    }
    const validator =
      validatorDefaults.getSchema<typesAnyCapabilityConfig[TKind]>(schemaRef);
    if (!validator) {
      throw new Error(
        `Failed to resolve schema from registry for key: ${schemaRef}`,
      );
    }
    if (!(isStagedConfigDict(config, validator) || validator(config))) {
      throw new Error(
        `Failed to validate capability ${kind} with schema ${schemaRef}`,
      );
    }
    const stagedConfig = { kind, config };
    if (!isCapabilityConfig(stagedConfig, kind)) {
      throw new Error(`Invalid capability config for kind: ${kind}`);
    }
    this.data.capabilities.push(stagedConfig);
  }

  /**
   * This function either updates an existing capability in the product manifest or adds a new one if it doesn't exist.
   * It first checks if the manifest is a product manifest, throwing an error if it isn't.
   * Then it checks if a capability with the same kind already exists in the manifest.
   * If it does, it updates the existing capability with the new configuration.
   * If it doesn't, it adds a new capability with the given configuration.
   *
   * @param {Pick<C, 'kind'> & Partial<C>} config - The configuration of the capability to be upserted.
   * @throws {Error} - Throws an error if the manifest is not a product manifest.
   */
  upsertCapability<TKind extends AnyCapabilityConfigKind>(
    kind: TKind,
    config:
      | Partial<typesAnyCapabilityConfig[TKind]>
      | StagedConfigDict<Partial<typesAnyCapabilityConfig[TKind]>> = {},
  ) {
    if (!isProductManifest(this.data)) {
      throw new Error('Capabilities can only be added to product manifests');
    }
    const existingCapability = this.data.capabilities.find(
      c => c.kind === kind,
    );
    if (existingCapability) {
      const index = this.data.capabilities.indexOf(existingCapability);
      this.data.capabilities[index].config = _.merge(
        existingCapability.config,
        config,
      );
    } else {
      this.addCapability(kind, config);
    }
  }

  /**
   * This function adds a module to the product manifest.
   * It also checks the dependencies of the module and adds any missing capabilities.
   *
   * @param {Pick<M, 'kind'> & Partial<M>} config - The configuration of the module to be added.
   * @throws {Error} - Throws an error if the manifest is not a product manifest, if the module already exists,
   * if the module kind is unknown, or if the module fails validation.
   */
  addModule<M extends AnyModuleConfig>(config: Pick<M, 'kind'> & Partial<M>) {
    if (!isProductManifest(this.data)) {
      throw new Error('Modules can only be added to product manifests');
    }
    if (this.hasModule(config.kind)) {
      throw new Error(`Module '${config.kind}' already exists`);
    }
    const schemaRef = schemasAnyModuleConfig[config.kind];
    if (!schemaRef) {
      throw new Error(`Unknown module kind: ${config.kind}`);
    }
    const validator = validatorDefaults.getSchema<M>(schemaRef);
    if (!validator || !validator(config)) {
      throw new Error(
        `Failed to validate module ${config.kind} with schema ${schemaRef}`,
      );
    }
    this.data.modules.push(config as M);
    for (const kind of moduleDependencies[config.kind]) {
      if (!this.hasCapability(kind)) {
        this.addCapability(kind);
      }
    }
  }

  /**
   * This function either updates an existing module in the product manifest or adds a new one if it doesn't exist.
   * It first checks if the manifest is a product manifest, throwing an error if it isn't.
   * Then it checks if a module with the same kind already exists in the manifest.
   * If it does, it updates the existing module with the new configuration.
   * If it doesn't, it adds a new module with the given configuration.
   *
   * @param {Pick<M, 'kind'> & Partial<M>} config - The configuration of the module to be upserted.
   * @throws {Error} - Throws an error if the manifest is not a product manifest.
   */
  upsertModule<M extends AnyModuleConfig>(
    config: Pick<M, 'kind'> & Partial<M>,
  ) {
    if (!isProductManifest(this.data)) {
      throw new Error('Modules can only be added to product manifests');
    }
    const existingModule = this.data.modules.find(m => m.kind === config.kind);
    if (existingModule) {
      const index = this.data.modules.indexOf(existingModule);
      this.data.modules[index] = { ...existingModule, ...config };
    } else {
      this.addModule(config);
    }
  }

  /* Reconcile the manifest by applying the following steps:
   * - validate the manifest metadata
   * - insert default values for all modules and capabilities
   * - add missing capabilities for each active module
   */
  reconcile() {
    const metaValidator = validatorDefaults.getSchema<ManifestMetadata>(
      '#/$defs/ManifestMetadata',
    );
    if (!metaValidator || !metaValidator(this.data.metadata)) {
      throw new Error('Failed to validate manifest metadata');
    }
    if (!isProductManifest(this.data)) return;
    // ensure that default values are set for all modules
    const modules = [];
    for (const module of this.data.modules) {
      const schemaRef = schemasAnyModuleConfig[module.kind];
      if (!schemaRef) {
        throw new Error(`Unknown module kind: ${module.kind}`);
      }
      const validator = validatorDefaults.getSchema<AnyModuleConfig>(schemaRef);
      if (!validator || !validator(module)) {
        throw new Error(
          `Failed to validate module ${module.kind} with schema ${schemaRef}`,
        );
      }
      modules.push(module);
    }
    this.data.modules = modules;

    // ensure that default values are set for all capabilities
    const capabilities = [];
    for (const capability of this.data.capabilities) {
      const schemaRef = schemasAnyCapabilityConfig[capability.kind];
      if (!schemaRef) {
        throw new Error(`Unknown capability kind: ${capability.kind}`);
      }
      const validator =
        validatorDefaults.getSchema<AnyCapabilityConfig>(schemaRef);
      if (!validator) {
        throw new Error(`Failed to get validator for schema ${schemaRef}`);
      }
      if (isStagedConfigDict(capability?.config, getTypeGuard(schemaRef))) {
        if (!validator(capability.config.development)) {
          throw new Error(
            `Failed to validate capability ${capability.kind} with schema ${schemaRef} (development)`,
          );
        }
        if (capability.config.production) {
          if (!validator(capability.config.production)) {
            throw new Error(
              `Failed to validate capability ${capability.kind} with schema ${schemaRef} (production)`,
            );
          }
        }
      } else {
        if (!validator(capability.config)) {
          throw new Error(
            `Failed to validate capability ${capability.kind} with schema ${schemaRef}`,
          );
        }
      }
      capabilities.push(capability);
    }

    // add missing capabilities for each active module
    for (const module of this.data.modules) {
      for (const kind of moduleDependencies[module.kind]) {
        if (!this.hasCapability(kind)) {
          this.addCapability(kind);
        }
      }
    }
    this.validate();
  }
}

/**
 * This function takes a manifest and a logger as arguments and returns a new instance of ManifestHandler.
 * It checks the type of the manifest and creates a new ManifestHandler with the corresponding schemaRef.
 * If the manifest type is unknown, it throws an error.
 *
 * @param {T} manifest - The manifest to be handled.
 * @param {Logger} logger - The logger to be used.
 * @returns {ManifestHandler<T>} - A new instance of ManifestHandler.
 * @throws {Error} - Throws an error if the manifest type is unknown.
 */
function handlerFromManifest<T extends AnyManifest>(
  manifest: T,
  logger?: Logger,
): ManifestHandler<T> {
  if (isProductManifest(manifest)) {
    return new ManifestHandler({
      schemaRef: isProductManifest.schemaRef,
      data: manifest,
      logger,
    });
  }
  if (isWorkspaceManifest(manifest)) {
    return new ManifestHandler({
      schemaRef: isWorkspaceManifest.schemaRef,
      data: manifest,
      logger,
    });
  }
  if (isPlatformManifest(manifest)) {
    return new ManifestHandler({
      schemaRef: isPlatformManifest.schemaRef,
      data: manifest,
      logger,
    });
  }
  throw new Error('Unknown manifest type');
}

export function validateModule(config: any) {
  if (!config.kind) {
    throw new Error('Module kind is required');
  }
  // @ts-expect-error
  const schemaRef = schemasAnyModuleConfig[config.kind];
  if (!schemaRef) {
    throw new Error(`Unknown module kind: ${config.kind}`);
  }
  const validator = validatorDefaults.getSchema<AnyModuleConfig>(schemaRef);
  if (!validator || !validator(config)) {
    throw new Error(
      `Failed to validate module ${config.kind} with schema ${schemaRef}`,
    );
  }
  return config;
}
