import { EntityApiDefinitionCard } from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntitySwitch,
  isKind,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { UserProfileCard } from '@provisioning/react';
import Grid from '@mui/material/Grid';
import React from 'react';
import { systemPage } from './SystemPage';
import {
  adrs,
  api,
  dependencies,
  docs,
  k8s,
  overview,
  pipelines,
  todo,
  warning,
  githubActions,
} from './sections';

const componentPage = (
  <EntityLayout>
    {overview}
    {api}
    {dependencies}
    {docs}
    {adrs}
    {k8s}
    {pipelines}
    {todo}
    {githubActions}
  </EntityLayout>
);

const apiPage = (
  <EntityLayout>
    {overview}
    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    {pipelines}
    {k8s}
    {adrs}
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {warning}
        <Grid item md={6} xs={12}>
          <UserProfileCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {warning}
        <Grid item md={6} xs={12}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityMembersListCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {warning}
        <Grid item md={6} xs={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    {docs}
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case>{componentPage}</EntitySwitch.Case>
  </EntitySwitch>
);
