import { FeatureFlagged } from '@backstage/core-app-api';
import {
  CatalogIcon,
  Link,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  sidebarConfig,
  useSidebarOpenState,
} from '@backstage/core-components';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  catalogApisReadPermission,
  catalogCostReadPermission,
  endUserPortalPermission,
  provisioningManageAllPermission,
} from '@internal/plugin-permissions-common';
import CreateComponentIcon from '@mui/icons-material/AddCircleOutline';
import AppsIcon from '@mui/icons-material/Apps';
import Build from '@mui/icons-material/Build';
import CategoryIcon from '@mui/icons-material/Category';
import ExtensionIcon from '@mui/icons-material/Extension';
import DashboardIcon from '@mui/icons-material/InsertChart';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import MenuIcon from '@mui/icons-material/Menu';
import Payments from '@mui/icons-material/Payment';
import SearchIcon from '@mui/icons-material/Search';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import React, { PropsWithChildren } from 'react';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import BuildIcon from '@mui/icons-material/Build';
import { NotificationsSidebarItem } from '@backstage/plugin-notifications';

const logoLinkStyles = {
  root: {
    width: '100%',
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    marginLeft: 24,
  },
};

const SidebarLogo = () => {
  const { isOpen } = useSidebarOpenState();

  return (
    <div style={logoLinkStyles.root}>
      <Link
        to="/"
        underline="none"
        style={logoLinkStyles.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />

      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>

      <SidebarDivider />

      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <SidebarItem
          // NOTE(packre) This (the function invocation) is a workaround for the fact that
          // the icon prop is not yet migrated to support v5 icons.
          // https://github.com/backstage/backstage/issues/18018
          icon={() => <CategoryIcon />}
          to="workspaces"
          text="Workspaces"
        />
        <SidebarItem icon={() => <AppsIcon />} to="products" text="Products" />
        <SidebarItem
          icon={() => <DashboardIcon />}
          to="dashboard"
          text="Dashboard"
        />
        <SidebarItem icon={CatalogIcon} to="catalog" text="Catalog" />
        <NotificationsSidebarItem />
      </SidebarGroup>

      <SidebarGroup label="Admin Menu" icon={<MenuIcon />}>
        <SidebarDivider />
        <FeatureFlagged with="experimental">
          <RequirePermission
            permission={endUserPortalPermission}
            errorPage={<div />}
          >
            <SidebarItem
              icon={() => <Payments />}
              to="end-user-portal"
              text="End User Portal"
            />
          </RequirePermission>
        </FeatureFlagged>
        <RequirePermission
          permission={catalogApisReadPermission}
          errorPage={<div />}
        >
          <SidebarItem
            icon={() => <SettingsEthernetIcon />}
            to="api-docs"
            text="APIs"
          />
        </RequirePermission>
        <RequirePermission
          permission={catalogCostReadPermission}
          errorPage={<div />}
        >
          <SidebarItem
            icon={() => <Payments />}
            to="cost-overview"
            text="Cost Overview"
          />
        </RequirePermission>
        <RequirePermission
          permission={provisioningManageAllPermission}
          errorPage={<div />}
        >
          <SidebarItem
            icon={() => <ExtensionIcon />}
            to="provisioning"
            text="Provisioning"
          />
          <SidebarItem
            icon={() => <BuildIcon />}
            to="devtools"
            text="DevTools"
          />
        </RequirePermission>
      </SidebarGroup>

      <SidebarGroup label="Create" icon={<MenuIcon />}>
        <SidebarDivider />
        <SidebarItem
          icon={() => <CreateComponentIcon />}
          to="templates"
          text="Templates"
        />
      </SidebarGroup>

      <SidebarSpace />
      <SidebarDivider />

      <SidebarGroup label="Documentation">
        <SidebarItem
          icon={() => <LibraryBooks />}
          text="User Docs"
          onClick={() => {
            window.open(
              'https://devhub.intranet.basf.com/documentation',
              '_blank',
              'noreferrer',
            );
          }}
        />
        <SidebarItem icon={() => <Build />} to="docs" text="Tech Docs" />
      </SidebarGroup>

      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
