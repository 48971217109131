import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import ListItemText from '@mui/material/ListItemText';
import { ProductInfo, WorkspaceInfo } from '@provisioning/common';
import { stringifyEntityRef } from '@backstage/catalog-model';

export interface ArtifactSelectorFormData {
  title?: string;
  name?: string;
  entityRef?: string;
}

export const ArtifactSelector = ({
  title,
  onChange,
  required,
  options,
  formData,
}: {
  title: string;
  onChange: Function;
  required: boolean;
  options: (ProductInfo | WorkspaceInfo)[];
  formData: ArtifactSelectorFormData | undefined;
}) => {
  const getOptionLabel = (option: ProductInfo | WorkspaceInfo) => option.title;
  const selectedEntity = options.find(
    o => stringifyEntityRef(o.entityRef) === formData?.entityRef,
  );

  const isOptionEqualToValue = (
    option: ProductInfo | WorkspaceInfo,
    value: ProductInfo | WorkspaceInfo,
  ) => option?.entityRef === value?.entityRef;
  const onChangeCallback = (_: any, v: ProductInfo | WorkspaceInfo | null) =>
    onChange({
      title: v?.title,
      name: v?.name,
      entityRef: v?.entityRef ? stringifyEntityRef(v?.entityRef) : '',
    });

  const renderOption = (props: any, option: ProductInfo | WorkspaceInfo) => (
    <li {...props}>
      <ListItemText primary={option.title} secondary={option.description} />
    </li>
  );

  const renderInput = (params: any) => (
    <TextField {...params} label={title} margin="dense" required={required} />
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Autocomplete
        options={options}
        value={selectedEntity}
        onChange={onChangeCallback}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        style={{ width: '100%' }}
        renderOption={renderOption}
        renderInput={renderInput}
      />
    </div>
  );
};
