import React from 'react';
import colors from 'style-loader!css-loader!sass-loader!../../colors.module.scss';
import { useTheme, KEY_DARK_THEME } from '@internal/plugin-argus-react';

const LogoFull = () => {
  const currTheme = useTheme();
  const darkTheme = currTheme === KEY_DARK_THEME;

  return (
    <svg x="0px" y="0px" viewBox="0 0 75.2 22.1" className="argus-logo">
      <g>
        <path
          fill={darkTheme ? colors.lightColor : colors.basfDarkBlueColor}
          d="M35.5,4.1c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.3-0.9,0.7-1.2,1.2c0,0.1-0.1,0.1-0.1,0.2l-0.1-1.6h-3.2v12.7h3.4V8.7
		c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.5-0.4,0.9-0.5c0.4-0.1,0.8-0.2,1.2-0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0.1l0.1-3.1
		c-0.1,0-0.3-0.1-0.5-0.1C35.9,4.2,35.7,4.1,35.5,4.1z"
        />
        <path
          fill={darkTheme ? colors.lightColor : colors.basfDarkBlueColor}
          d="M20.7,0h-1.5h-0.9L12,17.1h3.7l1.2-3.6H23l1.2,3.6H28L21.6,0H20.7z M17.8,10.7L20,4.1l2.2,6.6H17.8z"
        />
        <path
          fill={darkTheme ? colors.lightColor : colors.basfDarkBlueColor}
          d="M45.8,5.6c-0.3-0.4-0.7-0.8-1.1-1c-0.6-0.3-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.3-1.2,0.8-1.6,1.3
		c-0.4,0.6-0.8,1.3-1,2.1c-0.2,0.8-0.4,1.7-0.4,2.6v0.2c0,0.9,0.1,1.8,0.4,2.6c0.2,0.8,0.6,1.5,1,2c0.4,0.6,1,1,1.6,1.3
		c0.6,0.3,1.3,0.5,2.1,0.5c0.8,0,1.5-0.2,2-0.5c0.4-0.2,0.8-0.5,1.1-0.9v0.7c0,0.6-0.1,1.1-0.3,1.5c-0.2,0.4-0.6,0.8-1,1
		c-0.4,0.2-0.9,0.3-1.5,0.3c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.2-1-0.6-1.4-1.1l-1.5,2c0.4,0.5,0.8,0.8,1.3,1.1c0.5,0.3,1.1,0.5,1.7,0.7
		c0.6,0.1,1.2,0.2,1.7,0.2c1.2,0,2.3-0.2,3.2-0.6c0.9-0.4,1.6-1,2.1-1.8c0.5-0.8,0.8-1.8,0.8-2.9V4.4h-3.1L45.8,5.6z M44.9,14.2
		c-0.4,0.3-0.9,0.4-1.5,0.4c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.5-0.8-0.8c-0.2-0.4-0.4-0.8-0.5-1.2c-0.1-0.5-0.2-0.9-0.2-1.5
		v-0.2c0-0.5,0.1-1,0.2-1.5c0.1-0.5,0.3-0.9,0.5-1.2c0.2-0.3,0.5-0.6,0.8-0.8c0.3-0.2,0.7-0.3,1.2-0.3c0.5,0,0.9,0.1,1.2,0.2
		c0.3,0.2,0.6,0.4,0.8,0.7c0.1,0.1,0.1,0.2,0.2,0.3v5.4C45.4,13.8,45.2,14,44.9,14.2z"
        />
        <path
          fill={darkTheme ? colors.lightColor : colors.basfDarkBlueColor}
          d="M59.1,13.3c-0.2,0.4-0.4,0.7-0.7,0.9c-0.4,0.3-0.9,0.4-1.6,0.4c-0.3,0-0.5,0-0.8-0.1c-0.2-0.1-0.4-0.2-0.6-0.4
		c-0.2-0.2-0.3-0.4-0.4-0.6c-0.1-0.3-0.1-0.6-0.1-0.9V4.4h-3.4v8.2c0,0.8,0.1,1.6,0.3,2.2c0.2,0.6,0.5,1.1,0.9,1.5
		c0.4,0.4,0.8,0.7,1.3,0.8c0.5,0.2,1.1,0.3,1.7,0.3c0.8,0,1.5-0.1,2.1-0.4c0.6-0.3,1-0.7,1.4-1.1l0.1,1.3h3.2V4.4h-3.4V13.3z"
        />
        <path
          fill={darkTheme ? colors.lightColor : colors.basfDarkBlueColor}
          d="M74.6,11.4c-0.4-0.5-1-0.9-1.7-1.2c-0.7-0.3-1.5-0.6-2.4-0.7c-0.4-0.1-0.8-0.2-1.1-0.3C69,9.1,68.7,9,68.6,8.8
		s-0.3-0.3-0.4-0.4C68.1,8.3,68,8.1,68,7.9c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.4-0.4,0.6-0.5c0.3-0.1,0.6-0.2,1-0.2
		c0.5,0,0.8,0.1,1.1,0.2c0.3,0.1,0.5,0.4,0.6,0.6c0.1,0.3,0.2,0.6,0.2,0.9h3.4c0-0.8-0.2-1.5-0.6-2.1c-0.4-0.6-1-1.1-1.8-1.5
		c-0.8-0.4-1.7-0.5-2.8-0.5c-0.8,0-1.5,0.1-2.1,0.3c-0.6,0.2-1.2,0.5-1.6,0.9c-0.4,0.4-0.8,0.8-1,1.2c-0.2,0.5-0.3,1-0.3,1.5
		c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.5,0.8,0.9,1.1c0.4,0.3,0.9,0.6,1.4,0.8c0.6,0.2,1.2,0.4,1.8,0.5c0.7,0.1,1.3,0.3,1.6,0.5
		c0.4,0.2,0.6,0.3,0.8,0.5c0.1,0.2,0.2,0.4,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.7,0.2-1.1,0.2
		c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.2-0.3-0.3-0.7-0.4-1.1h-3.2c0,0.7,0.2,1.4,0.6,2c0.4,0.6,1,1.2,1.9,1.6
		c0.8,0.4,1.8,0.6,3,0.6c0.8,0,1.5-0.1,2.2-0.3c0.7-0.2,1.2-0.5,1.7-0.8c0.5-0.4,0.8-0.8,1.1-1.2c0.2-0.5,0.4-1,0.4-1.5
		C75.2,12.6,75,11.9,74.6,11.4z"
        />
      </g>
      <polygon
        fill={colors.basfLightBlueColor}
        points="14.3,0 8,17.1 4,17.1 10.3,0 "
      />
      <polygon
        fill={colors.basfDarkBlueColor}
        points="10.3,0 4,17.1 0,17.1 6.3,0 "
      />
    </svg>
  );
};

export default LogoFull;
