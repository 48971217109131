import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { MsGraphEntity } from '@provisioning/common';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { MsGraphFormData } from './types';

export const AADSearch = ({
  title,
  onChange,
  required,
  fetchData,
  formData,
  showDescription = false,
}: {
  title: string;
  onChange: Function;
  required: boolean;
  fetchData: (input: string | undefined) => Promise<MsGraphEntity[]>;
  formData: MsGraphFormData | undefined;
  showDescription?: boolean;
}) => {
  const defaultOption =
    formData?.id && formData?.name
      ? [
          {
            name: formData?.name,
            email: formData?.email,
            id: formData?.id,
          },
        ]
      : [];

  const [options, setOptions] = useState<MsGraphEntity[]>(defaultOption);
  const [input, setInput] = useState<string>(formData?.name ?? '');
  const [trunk, setTrunk] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const getOptionLabel = (option: MsGraphEntity) => option?.displayName ?? '';
  const selectedEntity = options.find(o => o.id === formData?.id);
  const handleChangeDebounced = debounce(value => setInput(value), 1000);

  useEffect(() => {
    if (input !== trunk) {
      setLoading(true);
      fetchData(input)
        .then(o => setOptions(o))
        .catch(_ => setOptions([]));
      setTrunk(input);
    }
    setLoading(false);
  }, [fetchData, input, trunk]);

  const onInputChange = useCallback(
    (_: any, value: string, reason: string) => {
      if (reason === 'input') {
        setLoading(true);
        handleChangeDebounced(value);
      }
    },
    [handleChangeDebounced],
  );

  const isOptionEqualToValue = (option: MsGraphEntity, value: MsGraphEntity) =>
    option?.id === value?.id;
  const onChangeCallback = (_: any, v: MsGraphEntity | null) =>
    onChange({ name: v?.displayName, email: v?.email, id: v?.id });

  const renderOption = (props: any, option: MsGraphEntity) => (
    <li {...props}>
      <ListItemText
        primary={option.displayName}
        secondary={showDescription ? option.description : option.email}
      />
    </li>
  );

  const renderInput = (params: any) => (
    <TextField
      {...params}
      label={title}
      margin="dense"
      required={required}
      InputProps={{
        ...params.InputProps,
        endAdornment: loading ? (
          <CircularProgress
            {...params.InputProps.endAdornment}
            color="inherit"
            size={20}
          />
        ) : null,
      }}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Autocomplete
        options={options}
        loading={loading}
        value={selectedEntity}
        onInputChange={onInputChange}
        onChange={onChangeCallback}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        style={{ width: '100%' }}
        renderOption={renderOption}
        renderInput={renderInput}
      />
    </div>
  );
};
