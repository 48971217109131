import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const productPlugin = createPlugin({
  id: 'products',
  routes: {
    root: rootRouteRef,
  },
});

export const ProductPage = productPlugin.provide(
  createRoutableExtension({
    name: 'ProductPage',
    component: () => import('./components/Product').then(m => m.ProductPage),
    mountPoint: rootRouteRef,
  }),
);

export const workspacePlugin = createPlugin({
  id: 'workspaces',
  routes: {
    root: rootRouteRef,
  },
});

export const WorkspacePage = workspacePlugin.provide(
  createRoutableExtension({
    name: 'WorkspacePage',
    component: () =>
      import('./components/Workspace').then(m => m.WorkspacePage),
    mountPoint: rootRouteRef,
  }),
);

export const dashboardPlugin = createPlugin({
  id: 'dashboard',
  routes: {
    root: rootRouteRef,
  },
});

export const DashboardPage = dashboardPlugin.provide(
  createRoutableExtension({
    name: 'DashboardPage',
    component: () =>
      import('./components/Dashboard').then(m => m.DashboardPage),
    mountPoint: rootRouteRef,
  }),
);
