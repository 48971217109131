import React, { Suspense } from 'react';
import { ArtifactSelector, ArtifactSelectorFormData } from './ArtifactSelector';
import { useProducts } from '@provisioning/react';
import CircularProgress from '@mui/material/CircularProgress';

export const ProductSelector = ({
  title,
  onChange,
  required,
  formData,
}: {
  title: string;
  onChange: Function;
  required: boolean;
  formData: ArtifactSelectorFormData | undefined;
}) => {
  const { data: options } = useProducts({ owned: true });

  return (
    <Suspense fallback={<CircularProgress />}>
      <ArtifactSelector
        title={title}
        onChange={onChange}
        required={required}
        options={options}
        formData={formData}
      />
    </Suspense>
  );
};
