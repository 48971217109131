import React from 'react';
import {
  createUnifiedTheme,
  genPageTheme,
  shapes,
  UnifiedThemeProvider,
  palettes,
} from '@backstage/theme';
import { AppTheme } from '@backstage/core-plugin-api';

const DarkTheme = createUnifiedTheme({
  palette: {
    ...palettes.dark,
    primary: {
      main: '#d1d1d1',
    },
    secondary: {
      main: '#004a96',
    },
    error: {
      main: '#c50022',
    },
    warning: {
      main: '#f39500',
    },
    info: {
      main: '#21a0d2',
    },
    success: {
      main: '#65ac1e',
    },
    background: {
      default: '#001830',
      paper: '#00203f',
    },
    banner: {
      info: '#21a0d2',
      error: '#c50022',
      text: '#f0f0f0',
      link: '#21a0d2',
    },
    errorBackground: '#c50022',
    warningBackground: '#f39500',
    infoBackground: '#21a0d2',
    navigation: {
      background: '#00203f',
      indicator: '#21a0d2',
      color: '#d1d1d1',
      selectedColor: '#f0f0f0',
      navItem: {
        hoverBackground: '#004a96',
      },
    },
  },
  defaultPageTheme: 'home',
  fontFamily: "'Roboto', sans-serif",
  pageTheme: {
    home: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    documentation: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.round,
    }),
    service: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    other: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    app: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    apis: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
  },
});

const LightTheme = createUnifiedTheme({
  palette: {
    ...palettes.light,
    primary: {
      main: '#262626',
    },
    secondary: {
      main: '#004a96',
    },
    error: {
      main: '#c50022',
    },
    warning: {
      main: '#f39500',
    },
    info: {
      main: '#21a0d2',
    },
    success: {
      main: '#65ac1e',
    },
    background: {
      default: '#f0f0f0',
      paper: '#e6e6e6',
    },
    banner: {
      info: '#21a0d2',
      error: '#c50022',
      text: '#f0f0f0',
      link: '#21a0d2',
    },
    errorBackground: '#c50022',
    warningBackground: '#f39500',
    infoBackground: '#21a0d2',
    navigation: {
      background: '#e6e6e6',
      indicator: '#21a0d2',
      color: '#004a96',
      selectedColor: '#21a0d2',
      navItem: {
        hoverBackground: '#f0f0f0',
      },
    },
  },
  defaultPageTheme: 'home',
  fontFamily: "'Roboto', sans-serif",
  pageTheme: {
    home: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    documentation: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.round,
    }),
    service: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    other: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    app: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
    apis: genPageTheme({
      colors: ['#004a96', '#21a0d2'],
      shape: shapes.wave,
    }),
  },
});

export const themes: AppTheme[] = [
  {
    id: 'basf-dark-theme',
    title: 'Dark Theme',
    variant: 'dark',
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={DarkTheme} children={children} />
    ),
  },
  {
    id: 'basf-light-theme',
    title: 'Light Theme',
    variant: 'light',
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={LightTheme} children={children} />
    ),
  },
];
