import { FieldValidation } from '@rjsf/utils';
import { ArtifactSelectorFormData } from '../components/ArtifactSelector';
import { KubernetesValidatorFunctions } from '@backstage/catalog-model';
import { ServiceNameData } from '../components/ServiceName';

export const entityRefValidation = (
  value: ArtifactSelectorFormData,
  validation: FieldValidation,
) => {
  const entityRefPattern = /^([^:\/]+):([^\/]+)\/([^\/]+)$/;
  const isValidEntityRef = entityRefPattern.test(value?.entityRef || '');

  if (!isValidEntityRef) {
    validation.addError(
      `Please provide a valid EntityRef in the format <kind>:<namespace>/<name>.`,
    );
  }
};

export const serviceNameValidation = (
  value: ServiceNameData,
  validation: FieldValidation,
) => {
  if (!KubernetesValidatorFunctions.isValidObjectName(value.name)) {
    validation.addError(
      'Must start and end with an alphanumeric character, and contain only alphanumeric characters and hyphens. Maximum length is 62 characters.',
    );
  }
};
