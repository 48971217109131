import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { metricsApiRef } from '@internal/plugin-metrics';
import { MetricsClient } from '@internal/plugin-metrics-common';
import {
  provisioningRegistryApiRef,
  provisioningCatalogApiRef,
} from '@provisioning/react';
import {
  ProvisioningRegistryClient,
  ProvisioningCatalogClient,
} from '@provisioning/common';

export const apis: AnyApiFactory[] = [
  // SCM
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  // SCM Auth
  ScmAuth.createDefaultApiFactory(),
  // Metrics
  createApiFactory({
    api: metricsApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new MetricsClient({ discoveryApi, fetchApi }),
  }),
  // Provisioning
  createApiFactory({
    api: provisioningRegistryApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      // @ts-expect-error
      new ProvisioningRegistryClient({ discoveryApi, fetchApi }),
  }),
  createApiFactory({
    api: provisioningCatalogApiRef,
    deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
    factory: ({ discoveryApi, fetchApi }) =>
      ProvisioningCatalogClient.fromOptions({ discoveryApi, fetchApi }),
  }),
];
