import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Route, Routes } from 'react-router-dom';
import { ProvisioningContent, RequireOwnership } from './components';
import { MissingAnnotationEmptyState } from '@backstage/plugin-catalog-react';

import { Annotations } from '@provisioning/common';

export const isProvisioningAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[Annotations.LinkConfig]);

export const Router = () => {
  const { entity } = useEntity();

  const configAnnotationValue =
    entity.metadata.annotations?.[Annotations.LinkConfig];

  return configAnnotationValue ? (
    <Routes data-testid="reap-routes-root">
      <Route
        data-testid="reap-route"
        path="/"
        element={
          <RequireOwnership>
            <ProvisioningContent entity={entity} />
          </RequireOwnership>
        }
      />
    </Routes>
  ) : (
    <MissingAnnotationEmptyState annotation={Annotations.LinkConfig} />
  );
};
