import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

export interface ServiceNameData {
  name?: string;
  displayName?: string;
}

export const getServiceName = (value: string, prefix?: string) => {
  const regExp = /[^a-zA-Z0-9_-]/g;
  const maxLength = 62;
  let genName = value.replace(regExp, '-').toLowerCase();
  while (genName.includes('--')) {
    genName = genName.replace(/--/g, '-'); // remove multiple hyphens
  }
  if (genName.startsWith('-')) genName = genName.substring(1); // remove hyphen at first position
  genName = prefix ? `${prefix}-${genName}` : genName;
  if (genName.length > maxLength) genName = genName.substring(0, maxLength); // restrict to max length
  if (genName.endsWith('-')) genName = genName.substring(0, genName.length - 1); // remove hyphen at last position

  return genName;
};

export const ServiceName = ({
  onChange,
  required,
  title,
  description,
  rawErrors,
  formData,
  placeholder,
  prefix,
}: {
  onChange: Function;
  required: boolean;
  title: string;
  description?: string;
  rawErrors: any[];
  formData: ServiceNameData | undefined;
  placeholder?: string;
  prefix?: string;
}) => {
  const [displayName, setDisplayName] = useState(
    formData?.displayName ? formData.displayName : '',
  );

  const initName = prefix ? `${prefix}-` : '';
  const [name, setName] = useState(formData?.name ? formData.name : initName);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDisplayName(value);

    const genName = getServiceName(value, prefix);
    setName(genName);
    onChange({ displayName: value, name: genName });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        label={title}
        placeholder={placeholder}
        helperText={description}
        required={required}
        value={displayName}
        onChange={handleOnChange}
        margin="normal"
        error={rawErrors?.length > 0 && !formData}
      />
      <TextField disabled label="Service Name" value={name} margin="normal" />
    </div>
  );
};
