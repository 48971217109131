import { useCallback } from 'react';
import {
  isProductEntity,
  isWorkspaceEntity,
  RegistryManifest,
  isProductManifest,
  isWorkspaceManifest,
} from '@provisioning/common';
import { CompoundEntityRef } from '@backstage/catalog-model';
import {
  identityApiRef,
  useApi,
  alertApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { provisioningRegistryApiRef } from '../api';
import {
  useSuspenseQuery,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const useManifestContext = (entityRef: CompoundEntityRef) => {
  const registryApi = useApi(provisioningRegistryApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const errorApi = useApi(errorApiRef);
  const alertApi = useApi(alertApiRef);

  const queryKey = [
    'manifest',
    entityRef.kind,
    entityRef.namespace,
    entityRef.name,
  ];

  const queryFn = useCallback(async () => {
    const entity = await identityApi
      .getCredentials()
      .then(cred => catalogApi.getEntityByRef(entityRef, cred));
    let manifest: RegistryManifest | null = null;
    if (isProductEntity(entity)) {
      manifest = await identityApi
        .getCredentials()
        .then(cred =>
          registryApi.getProductManifest({ name: entity.metadata.name }, cred),
        )
        .then(r => r.manifest);
    } else if (isWorkspaceEntity(entity)) {
      manifest = await identityApi
        .getCredentials()
        .then(cred =>
          registryApi.getWorkspaceManifest(
            { name: entity.metadata.name },
            cred,
          ),
        )
        .then(r => r.manifest);
    } else {
      throw new Error('Invalid entity');
    }
    if (!manifest) {
      throw new Error('Manifest not found');
    }
    return { manifest, entity };
  }, [registryApi, identityApi, entityRef, catalogApi]);

  const { data, error } = useSuspenseQuery({ queryKey, queryFn });

  const queryClient = useQueryClient();
  const update = useMutation({
    mutationFn: async (manifest: RegistryManifest) => {
      if (isProductManifest(manifest)) {
        await identityApi
          .getCredentials()
          .then(cred => registryApi.updateProduct({ manifest }, cred));
      } else if (isWorkspaceManifest(manifest)) {
        await identityApi
          .getCredentials()
          .then(cred => registryApi.updateWorkspace({ manifest }, cred));
      } else {
        throw new Error('Invalid manifest');
      }
      queryClient.refetchQueries({ queryKey });
    },
    onSuccess: () => {
      alertApi.post({
        message: 'Config updated.',
        severity: 'success',
        display: 'transient',
      });
    },
    onError: () => {
      errorApi.post({
        message: 'Failed to update config.',
        name: 'Failed to update config.',
      });
    },
  });

  return { ...data, update, error };
};
