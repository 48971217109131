import { RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  ItemCardHeader,
  MarkdownContent,
  UserIcon,
} from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
import {
  EntityRefLinks,
  FavoriteEntity,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { TemplateEntityV1beta3 } from '@backstage/plugin-scaffolder-common';
import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { CardLink } from './CardLink';

export interface TemplateCardProps {
  template: TemplateEntityV1beta3 & { metadata: { languages?: string[] } };

  onSelected?: (template: TemplateEntityV1beta3) => void;
}

const useStyles = makeStyles<Theme>(_theme => ({
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  box: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical',
  },
  markdown: {
    /** to make the styles for React Markdown not leak into the description */
    '& :first-child': {
      margin: 0,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    alignItems: 'center',
  },
  ownedBy: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
}));

export const TemplateCard = (props: TemplateCardProps) => {
  const { template } = props;
  const ownedByRelations = getEntityRelations(template, RELATION_OWNED_BY);
  const hasTags = !!template.metadata.tags?.length;
  const hasLinks = !!template.metadata.links?.length;
  const styles = useStyles();
  const app = useApp();
  const iconResolver = (key?: string) =>
    key ? (app.getSystemIcon(key) ?? LanguageIcon) : LanguageIcon;

  const TitleComponent = (
    <div className={styles.titleWrapper}>
      <div>{props.template.metadata.title}</div>
      <div>
        <FavoriteEntity entity={props.template} style={{ padding: 0 }} />
      </div>
    </div>
  );

  return (
    <Card>
      <ItemCardHeader title={TitleComponent} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={styles.box}>
              <MarkdownContent
                className={styles.markdown}
                content={template.metadata.description ?? 'No description'}
              />
            </Box>
          </Grid>
          {hasTags && (
            <>
              <Grid item xs={12}>
                <Divider data-testid="template-card-separator--tags" />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {template.metadata.tags?.map(tag => (
                    <Grid key={`grid-${tag}`} item>
                      <Chip
                        style={{ margin: 0 }}
                        size="small"
                        label={tag}
                        key={tag}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
          {hasLinks && (
            <>
              <Grid item xs={12}>
                <Divider data-testid="template-card-separator--links" />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {template.metadata.links?.map(
                    ({ url, icon, title }, index) => (
                      <Grid
                        className={styles.linkText}
                        item
                        xs={12}
                        key={index}
                      >
                        <CardLink
                          // @ts-expect-error: iconResolver is not typed
                          icon={iconResolver(icon)}
                          text={title || url}
                          url={url}
                        />
                      </Grid>
                    ),
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions style={{ padding: '16px', flex: 1, alignItems: 'flex-end' }}>
        <div className={styles.footer}>
          <div className={styles.ownedBy}>
            {ownedByRelations.length > 0 && (
              <>
                <UserIcon fontSize="small" />
                <EntityRefLinks
                  style={{ marginLeft: '8px' }}
                  entityRefs={ownedByRelations}
                  defaultKind="Group"
                  hideIcons
                />
              </>
            )}
          </div>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() => props.onSelected?.(template)}
          >
            Choose
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};
