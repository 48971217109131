import { Entity, getCompoundEntityRef } from '@backstage/catalog-model';
import { EmptyState } from '@backstage/core-components';
import { LoadingState } from '@internal/plugin-argus-react';
import makeStyles from '@mui/styles/makeStyles';
import { isContextEntity } from '@provisioning/common';
import React, { FC, Suspense, useEffect, useRef } from 'react';
import { ManifestEditor } from './ManifestEditor';

const useStyles = makeStyles({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    margin: 'auto',
  },
});

type ProvisioningContentProps = {
  entity: Entity;
  children?: React.ReactNode;
};

export const ProvisioningContent: FC<ProvisioningContentProps> = props => {
  const classes = useStyles();
  const { entity } = props;
  const inputRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState('0');

  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      // compute height of the content area so it fills the remaining space
      setHeight(`calc(100vh - ${rect.top + 24}px)`);
    }
  }, [inputRef]);

  if (!isContextEntity(entity)) {
    return (
      <EmptyState
        title="Editor not available."
        description="Only product and workspace configs can be edited."
        missing="data"
      />
    );
  }

  return (
    <div className={classes.content} ref={inputRef} style={{ height }}>
      <Suspense fallback=<LoadingState />>
        <ManifestEditor entityRef={getCompoundEntityRef(entity)} />
      </Suspense>
    </div>
  );
};
