import {
  CompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ProductManifest, WorkspaceManifest } from '@provisioning/common';
import React, { FC } from 'react';
import { useCatalogGroup, useManifestContext } from '../../hooks';
import { AboutField } from '../AboutField';

export type ManifestMetadataPros = {
  manifest: ProductManifest | WorkspaceManifest;
  action?: React.ReactNode;
};

const useStyles = makeStyles({
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  stack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
  },
  button: {
    padding: 0,
    minWidth: 0,
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'start',
    flexWrap: 'wrap',
    columnGap: '4rem',
    rowGap: '1rem',
  },
});

export const ManifestMetadata: FC<{ entityRef: CompoundEntityRef }> = ({
  entityRef,
}) => {
  const { manifest, error } = useManifestContext(entityRef);
  const classes = useStyles();
  const ownerGroup = useCatalogGroup({ groupId: manifest?.spec.owner ?? '' });

  if (error) {
    return <Typography color="error">Error loading manifest data!</Typography>;
  }

  return (
    <Card>
      <CardHeader title={manifest.kind} />
      <CardContent>
        <Box className={classes.box}>
          {ownerGroup.data.group && (
            <AboutField label="owner">
              <EntityRefLink
                entityRef={stringifyEntityRef(ownerGroup.data.group)}
              />
            </AboutField>
          )}
          {manifest && (
            <>
              <AboutField label="role" value={manifest.metadata.role} />
            </>
          )}
          {manifest?.spec?.featureFlags &&
            manifest?.spec?.featureFlags.length > 0 && (
              <AboutField label="feature flags">
                <Stack direction="row" spacing={1}>
                  {manifest.spec.featureFlags.map(f => (
                    <Chip key={f} label={f} size="small" />
                  ))}
                </Stack>
              </AboutField>
            )}
        </Box>
      </CardContent>
    </Card>
  );
};
