import {
  EntityMeta,
  SystemEntity,
  isSystemEntity,
  CompoundEntityRef,
} from '@backstage/catalog-model';
import {
  ProductManifest,
  WorkspaceManifest,
  isProductManifest,
  isWorkspaceManifest,
} from '../models';
import * as utils from '../utils';
import { Annotations, Labels } from './constants';

export const PRODUCT_ENTITY_KIND = 'System';
export const WORKSPACE_ENTITY_KIND = 'System';
export const PRODUCT_ENTITY_NAMESPACE = 'products';
export const WORKSPACE_ENTITY_NAMESPACE = 'workspaces';

export interface ContextAnnotation {
  [Annotations.LinkConfig]: string;
  [Annotations.Status]: string;
  [Annotations.AzureRepo]: string;
  [Annotations.AzureBuild]: string;
  [key: string]: string;
}

export interface ContextMetadata extends EntityMeta {
  namespace:
    | typeof PRODUCT_ENTITY_NAMESPACE
    | typeof WORKSPACE_ENTITY_NAMESPACE;
  annotations: ContextAnnotation;
}
export interface ContextEntity extends SystemEntity {}

export function isContextEntity(entity: any): entity is ContextEntity {
  return (
    !!entity?.kind &&
    isSystemEntity(entity) &&
    [PRODUCT_ENTITY_NAMESPACE, WORKSPACE_ENTITY_NAMESPACE].includes(
      entity.metadata.namespace ?? '',
    )
  );
}

export interface WorkspaceAnnotations extends ContextAnnotation {
  [Annotations.AdoServiceConnectionIds]: string;
}

export interface WorkspaceMetadata extends ContextMetadata {
  namespace: typeof WORKSPACE_ENTITY_NAMESPACE;
  annotations: WorkspaceAnnotations;
}
export interface WorkspaceEntity extends ContextEntity {
  kind: typeof WORKSPACE_ENTITY_KIND;
  metadata: WorkspaceMetadata;
}

export function isWorkspaceEntity(entity: any): entity is WorkspaceEntity {
  return (
    isContextEntity(entity) &&
    entity.metadata.namespace === WORKSPACE_ENTITY_NAMESPACE
  );
}

export interface ProductLabels {
  [Labels.Workspace]: string;
  [key: string]: string;
}
export interface ProductMetadata extends ContextMetadata {
  namespace: typeof PRODUCT_ENTITY_NAMESPACE;
  labels: ProductLabels;
}
export interface ProductEntity extends ContextEntity {
  kind: typeof PRODUCT_ENTITY_KIND;
  metadata: ProductMetadata;
}

export function isProductEntity(entity: any): entity is ProductEntity {
  return (
    isContextEntity(entity) &&
    entity.metadata.namespace === PRODUCT_ENTITY_NAMESPACE
  );
}

export function newContextEntity(options: {
  manifest: ProductManifest | WorkspaceManifest;
  configFileUrl: string;
}): ContextEntity {
  const { manifest, configFileUrl } = options;
  let kind: string = '';
  let namespace: string = '';
  if (isProductManifest(manifest)) {
    kind = PRODUCT_ENTITY_KIND;
    namespace = PRODUCT_ENTITY_NAMESPACE;
  }
  if (isWorkspaceManifest(manifest)) {
    kind = WORKSPACE_ENTITY_KIND;
    namespace = WORKSPACE_ENTITY_NAMESPACE;
  }
  if (!kind) {
    throw new Error('Unknown manifest type');
  }

  return {
    apiVersion: 'backstage.io/v1alpha1',
    kind: kind as ContextEntity['kind'],
    metadata: {
      name: utils.pruneName(manifest.metadata.name),
      title: manifest.metadata.title,
      description: manifest.metadata.description,
      namespace,
      annotations: {
        [Annotations.LinkConfig]: configFileUrl,
        [Annotations.Status]: 'created',
        ...manifest.metadata.annotations,
      },
    },
    spec: {
      owner: manifest.spec.owner,
    },
  };
}

export function workspaceEntityRef(name: string): string {
  return `${WORKSPACE_ENTITY_KIND}:${WORKSPACE_ENTITY_NAMESPACE}/${utils.pruneName(
    name,
  )}`.toLowerCase();
}

export function productEntityRef(name: string): string {
  return `${PRODUCT_ENTITY_KIND}:${PRODUCT_ENTITY_NAMESPACE}/${utils.pruneName(
    name,
  )}`.toLowerCase();
}

export interface ConfigLocation {
  repository: string;
  path: string;
}

type BaseConfigInfo = {
  name: string;
  title: string;
  description?: string;
  entityRef: CompoundEntityRef;
  ownerRef?: CompoundEntityRef;
  configLocation?: ConfigLocation;
};

export type WorkspaceInfo = BaseConfigInfo;

export type ProductInfo = BaseConfigInfo & { workspace: CompoundEntityRef };
