import {
  CompoundEntityRef,
  getCompoundEntityRef,
} from '@backstage/catalog-model';
import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import MonacoEditor, { DiffEditor } from '@monaco-editor/react';
import {
  isProductManifest,
  isWorkspaceManifest,
  RegistryManifest,
} from '@provisioning/common';
import { useMutation } from '@tanstack/react-query';
import React, { FC, useCallback } from 'react';
import { provisioningRegistryApiRef } from '../../api';
import { useManifestContext } from '../../hooks';
import { State, useHandlers } from './hooks';
import { ManifestMetadata } from './ManifestMetadata';
import { useStyles } from './styles';
import { CreateDialog } from '../CreateDialog';

export type ManifestEditorProps = {
  entityRef: CompoundEntityRef;
};

export const ManifestEditor: FC<ManifestEditorProps> = ({ entityRef }) => {
  const { manifest, entity, update, error } = useManifestContext(entityRef);
  const registryApi = useApi(provisioningRegistryApiRef);
  const alertApi = useApi(alertApiRef);
  const errorApi = useApi(errorApiRef);

  const queue = useMutation({
    mutationFn: async () => {
      if (isProductManifest(manifest)) {
        return await registryApi.queueProductBuild({
          name: manifest.metadata.name,
        });
      } else if (isWorkspaceManifest(manifest)) {
        return await registryApi.queueWorkspaceBuild({
          name: manifest.metadata.name,
        });
      }
      throw new Error('Unknown manifest type');
    },
    onError: () => {
      errorApi.post({
        message: `Failed to queue pipeline for ${manifest.metadata.name}`,
        name: 'Failed to queue pipeline',
      });
    },
    onSuccess: () => {
      alertApi.post({
        message: `Pipeline queued for ${manifest.metadata.name}`,
        severity: 'success',
        display: 'transient',
      });
    },
  });

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <ManifestMonaco
      manifest={manifest}
      update={update}
      queue={queue}
      entityRef={getCompoundEntityRef(entity)}
    />
  );
};

type ManifestMonacoProps = {
  manifest: RegistryManifest;
  entityRef: CompoundEntityRef;
  update: ReturnType<typeof useMutation<void, Error, RegistryManifest>>;
  queue: ReturnType<typeof useMutation<{ status: string }, Error>>;
};

function ManifestMonaco({
  manifest,
  entityRef,
  update,
  queue,
}: ManifestMonacoProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const openDialog = useCallback(() => setOpen(true), []);

  const {
    view,
    handleBeforeMount,
    handleOnMount,
    handleDiffEditorOnMount,
    editorActions,
    formatContent,
    updateEditorValue,
    getEditorValue,
  } = useHandlers({
    manifest,
    queue,
    update,
    openDialog,
  });

  return (
    <>
      <div
        className={classes.showContent}
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ManifestMetadata entityRef={entityRef} />
        <div
          className={
            view !== State.review ? classes.showContent : classes.hideContent
          }
        >
          <MonacoEditor
            className={view === State.edit ? classes.editorEditing : undefined}
            language="json"
            defaultLanguage="json"
            defaultValue={formatContent(manifest)}
            beforeMount={handleBeforeMount}
            onMount={handleOnMount}
            options={{
              domReadOnly: view === State.view,
              readOnly: view === State.view,
            }}
          />
        </div>
        <div
          className={
            view === State.review ? classes.showContent : classes.hideContent
          }
        >
          <DiffEditor language="json" onMount={handleDiffEditorOnMount} />
        </div>
        {editorActions}
      </div>
      <CreateDialog
        open={open}
        onSubmit={updateEditorValue}
        getCurrent={getEditorValue}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
