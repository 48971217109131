import {
  ANNOTATION_EDIT_URL,
  RELATION_MEMBER_OF,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
} from '@backstage/core-components';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BusinessIcon from '@mui/icons-material/Business';
import ChatIcon from '@mui/icons-material/Chat';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import PersonIcon from '@mui/icons-material/Person';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { DevHubUserEntity } from '@provisioning/common';
import React from 'react';
import { LinksGroup } from '@internal/plugin-argus-react';
import { msTeamsChatHref } from './utils';

const CardTitle = (props: { title?: string }) =>
  props.title ? (
    <Box display="flex" alignItems="center">
      <PersonIcon fontSize="inherit" />
      <Box ml={1}>{props.title}</Box>
    </Box>
  ) : null;

/** @public */
export const UserProfileCard = (props: {
  variant?: InfoCardVariants;
  showLinks?: boolean;
}) => {
  const { entity: user } = useEntity<DevHubUserEntity>();
  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }

  const entityMetadataEditUrl =
    user.metadata.annotations?.[ANNOTATION_EDIT_URL];

  const {
    metadata: { name: metaName, description, links },
    spec: { profile },
  } = user;
  const displayName = profile?.displayName ?? metaName;
  const emailHref = profile?.email ? `mailto:${profile.email}` : undefined;
  const chatHref = msTeamsChatHref(profile?.userPrincipalName);
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Group',
  });

  return (
    <InfoCard
      title={<CardTitle title={displayName} />}
      subheader={description}
      variant={props.variant}
      action={
        <>
          {chatHref && (
            <IconButton
              aria-label="Edit"
              title="Edit Metadata"
              component={Link}
              to={chatHref}
              size="large"
            >
              <ChatIcon />
            </IconButton>
          )}
          {entityMetadataEditUrl && (
            <IconButton
              aria-label="Edit"
              title="Edit Metadata"
              component={Link}
              to={entityMetadataEditUrl}
              size="large"
            >
              <EditIcon />
            </IconButton>
          )}
        </>
      }
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} sm={2} xl={1}>
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>

        <Grid item md={10} xl={11}>
          <List>
            {profile?.email && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Email">
                    <EmailIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <Link to={emailHref ?? ''}>{profile.email}</Link>
                </ListItemText>
              </ListItem>
            )}

            {profile?.company && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Company">
                    <BusinessIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>{profile.company}</ListItemText>
              </ListItem>
            )}

            {profile?.department && (
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Department">
                    <AccountTreeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>{profile.department}</ListItemText>
              </ListItem>
            )}

            <ListItem>
              <ListItemIcon>
                <Tooltip title="Member of">
                  <GroupIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                <EntityRefLinks
                  entityRefs={memberOfRelations}
                  defaultKind="Group"
                />
              </ListItemText>
            </ListItem>

            {props?.showLinks && <LinksGroup links={links} />}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
