import {
  AnyManifest,
  isProductManifest,
  ProductManifest,
  AnyModuleConfigKind,
  AnyCapabilityConfigKind,
} from '../models';
import _ from 'lodash';

export const moduleDependencies: {
  [module in AnyModuleConfigKind]: AnyCapabilityConfigKind[];
} = {
  dagster: ['postgres', 'object-store'],
  mlflow: ['postgres', 'object-store'],
  jupyter: [],
  superset: ['postgres'],
  arango: ['object-store'],
  'ai-search': [],
  'ai-search-basic': [],
  'ai-multi-service': ['object-store'],
  cloudbeaver: [],
  memgraph: [],
  openai: [],
  redis: [],
};

export function checkConstraints(manifest: AnyManifest) {
  if (isProductManifest(manifest)) {
    checkProductConstraints(manifest);
  }
}

function checkProductConstraints(manifest: ProductManifest) {
  const moduleKeys = manifest.modules.map(m => m.kind);
  if (_.uniq(moduleKeys).length !== moduleKeys.length) {
    throw new Error('Duplicate module keys found');
  }
  const capabilityKeys = manifest.capabilities.map(c => c.kind);
  if (_.uniq(capabilityKeys).length !== capabilityKeys.length) {
    throw new Error('Duplicate capability keys found');
  }
}
