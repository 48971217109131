import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  FieldExtensionComponentProps,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder-react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import {
  ArtifactSelectorFormData,
  WorkspaceSelector,
} from '../components/ArtifactSelector';
import { entityRefValidation } from './helpers';

const WorkspaceSelectorForm = (
  props: FieldExtensionComponentProps<ArtifactSelectorFormData>,
) => {
  const {
    onChange,
    schema: {
      title = 'Argus Workspace',
      description = 'Select a Workspace that you own.',
    },
    rawErrors,
    required,
    formData,
  } = props;

  return (
    <FormControl
      margin="normal"
      required={required}
      error={(rawErrors?.length || 0) > 0 && !formData}
    >
      <WorkspaceSelector
        title={title}
        onChange={onChange}
        required={required || false}
        formData={formData}
      />
      <FormHelperText id="entityName">{description}</FormHelperText>
    </FormControl>
  );
};

export const WorkspaceSelectorExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'WorkspaceSelector',
    component: WorkspaceSelectorForm,
    validation: entityRefValidation,
  }),
);
