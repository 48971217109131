import { BaseClient } from '@internal/plugin-argus-config-common';
import {
  EntityCostsResponse,
  MetricsApi,
  MetricsRequestOptions,
  ProductCostEntry,
  ProductMetric,
  StatMetric,
  StatProductsDailyResponse,
  StatResponse,
  TimeframeRequest,
  WorkspaceCostEntry,
  WorkspaceCostsResponse,
} from '../api';

/**
 * A frontend and backend compatible client for communicating with
 * the Argus metrics API.
 *
 * @public
 */
export class MetricsClient implements MetricsApi {
  private readonly client: BaseClient;

  constructor(options: {
    discoveryApi: { getBaseUrl(pluginId: string): Promise<string> };
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.client = new BaseClient({ ...options, pluginId: 'metrics' });
  }

  async getProductCosts(
    request: TimeframeRequest,
    options?: MetricsRequestOptions,
  ): Promise<EntityCostsResponse> {
    const { startDate, endDate } = request;

    const params = [
      `startDate=${startDate.toISOString()}`,
      `endDate=${endDate.toISOString()}`,
    ];

    const query = params.length ? `?${params.join('&')}` : '';
    const entities = await this.client.requestRequired<ProductCostEntry[]>(
      'GET',
      `/costs/products${query}`,
      options,
    );

    return { costs: entities };
  }

  async getWorkspaceCosts(
    request: TimeframeRequest,
    options?: MetricsRequestOptions,
  ): Promise<WorkspaceCostsResponse> {
    const { startDate, endDate } = request;

    const params = [
      `startDate=${startDate.toISOString()}`,
      `endDate=${endDate.toISOString()}`,
    ];

    const query = params.length ? `?${params.join('&')}` : '';
    const entities = await this.client.requestRequired<WorkspaceCostEntry[]>(
      'GET',
      `/costs/workspaces${query}`,
      options,
    );

    return { costs: entities };
  }

  async getStatProductsDaily(
    request: TimeframeRequest,
    options?: MetricsRequestOptions,
  ): Promise<StatProductsDailyResponse> {
    const { startDate, endDate } = request;

    const params = [
      `startDate=${startDate.toISOString()}`,
      `endDate=${endDate.toISOString()}`,
    ];

    const query = params.length ? `?${params.join('&')}` : '';
    const entities = await this.client.requestRequired<ProductMetric[]>(
      'GET',
      `/stat/products/daily${query}`,
      options,
    );

    return { items: entities };
  }

  async getStatWorkspacesDaily(
    request: TimeframeRequest,
    options?: MetricsRequestOptions,
  ): Promise<StatResponse> {
    const { startDate, endDate } = request;

    const params = [
      `startDate=${startDate.toISOString()}`,
      `endDate=${endDate.toISOString()}`,
    ];

    const query = params.length ? `?${params.join('&')}` : '';
    const items = await this.client
      .requestRequired<
        StatMetric[]
      >('GET', `/stat/workspaces/daily${query}`, options)
      .then(entry =>
        entry.map(item => ({ ...item, date: new Date(item.date) })),
      );

    return { items };
  }

  async getStatDevelopersDaily(
    request: TimeframeRequest,
    options?: MetricsRequestOptions,
  ): Promise<StatResponse> {
    const { startDate, endDate } = request;

    const params = [
      `startDate=${startDate.toISOString()}`,
      `endDate=${endDate.toISOString()}`,
    ];

    const query = params.length ? `?${params.join('&')}` : '';
    const items = await this.client
      .requestRequired<
        StatMetric[]
      >('GET', `/stat/developers/daily${query}`, options)
      .then(entry =>
        entry.map(item => ({ ...item, date: new Date(item.date) })),
      );

    return { items };
  }

  async getStatOwnerGroupsDaily(
    request: TimeframeRequest,
    options?: MetricsRequestOptions,
  ): Promise<StatResponse> {
    const { startDate, endDate } = request;

    const params = [
      `startDate=${startDate.toISOString()}`,
      `endDate=${endDate.toISOString()}`,
    ];

    const query = params.length ? `?${params.join('&')}` : '';
    const items = await this.client
      .requestRequired<
        StatMetric[]
      >('GET', `/stat/ownerGroups/daily${query}`, options)
      .then(entry =>
        entry.map(item => ({ ...item, date: new Date(item.date) })),
      );

    return { items };
  }
}
