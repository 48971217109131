import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Content } from '@backstage/core-components';
import { useStyles } from './styles';

export const LoadingState = () => {
  const styles = useStyles();
  return (
    <Content className={styles.centered} stretch>
      <CircularProgress />
    </Content>
  );
};
