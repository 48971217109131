import {
  makeFieldSchemaFromZod,
  scaffolderPlugin,
} from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import { z } from 'zod';
import { ServiceName } from '../components/ServiceName';
import { serviceNameValidation } from './helpers';

const ServiceNameFormFieldSchema = makeFieldSchemaFromZod(
  z.object({
    name: z.string().describe('Service name'),
    displayName: z.string().describe('Service display name'),
  }),
  z.object({
    prefix: z.string().optional().describe('Prefix of the service name'),
    placeholder: z.string().optional().describe('Prefix of the service name'),
  }),
);

export const ServiceNameFormSchema = ServiceNameFormFieldSchema.schema;
export const ServiceNameFormUiSchemax =
  ServiceNameFormFieldSchema.uiOptionsType;
type ServiceNameFormProps = typeof ServiceNameFormFieldSchema.type;

export const ServiceNameForm = (props: ServiceNameFormProps) => {
  const {
    schema: { title = 'Display Name', description = 'Name of the component' },
    onChange,
    rawErrors,
    required,
    formData,
    uiSchema,
  } = props;

  const prefix = uiSchema['ui:options']?.prefix;
  const placeholder = uiSchema['ui:options']?.placeholder;

  return (
    <FormControl
      margin="normal"
      required={required}
      error={(rawErrors?.length ?? 0) > 0 && !formData}
    >
      <ServiceName
        title={title}
        prefix={prefix}
        placeholder={placeholder}
        onChange={onChange}
        rawErrors={rawErrors}
        required={required || false}
        formData={formData}
      />
      <FormHelperText id="entityName">{description}</FormHelperText>
    </FormControl>
  );
};

export const ServiceNameExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ServiceName',
    component: ServiceNameForm,
    validation: serviceNameValidation,
    schema: ServiceNameFormSchema,
  }),
);
