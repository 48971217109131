// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lightColor": `#f0f0f0`,
	"darkLightColor": `#d1d1d1`,
	"darkMediumColor": `#001830`,
	"darkColor": `#262626`,
	"darkThemeSidebarColor": `#00203f`,
	"lightThemeSidebarColor": `#e6e6e6`,
	"basfLightBlueColor": `#21a0d2`,
	"basfDarkBlueColor": `#004a96`,
	"basfLightGreenColor": `#65ac1e`,
	"basfDarkGreenColor": `#00793a`,
	"basfOrangeColor": `#f39500`,
	"basfRedColor": `#c50022`
};
export default ___CSS_LOADER_EXPORT___;
