import { useCallback } from 'react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useSuspenseQuery } from '@tanstack/react-query';
import { WorkspaceInfo, utils } from '@provisioning/common';
import { provisioningCatalogApiRef } from '@provisioning/react';

interface UseWorkspacesProps {
  owned?: boolean;
}

interface UseWorkspacesOutput {
  data: WorkspaceInfo[];
  error: Error | null;
}

export function useWorkspaces({
  owned = false,
}: UseWorkspacesProps): UseWorkspacesOutput {
  const catalogApi = useApi(provisioningCatalogApiRef);
  const identityApi = useApi(identityApiRef);

  const queryFn = useCallback(async () => {
    const identity = await identityApi.getBackstageIdentity();
    return catalogApi
      .listWorkspaceEntities({
        filter: owned ? { 'spec.owner': identity.ownershipEntityRefs } : {},
      })
      .then(results => results.entities.map(utils.getWorkspaceInfo));
  }, [catalogApi, identityApi, owned]);

  const { data, error } = useSuspenseQuery({
    queryKey: [owned ? 'owned-workspaces' : 'all-workspaces'],
    queryFn,
    // Refetch the data every 10 second
    refetchInterval: 10000,
  });

  return { data, error };
}
