import { isUserEntity } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { InputError } from '@backstage/errors';
import { CatalogApi } from '@backstage/plugin-catalog-react';

export async function getUserId(
  catalogApi: CatalogApi,
  identityApi: IdentityApi,
): Promise<string> {
  const { userEntityRef } = await identityApi.getBackstageIdentity();
  const user = await catalogApi.getEntityByRef(userEntityRef);
  if (typeof user === 'undefined') {
    throw new InputError(`User '${user}' is not registered in the catalog.`);
  }
  if (!isUserEntity(user!)) {
    throw new InputError(`Expected user ('${user}') to be of kind 'User'.`);
  }

  return user.metadata.annotations!['graph.microsoft.com/user-id'];
}
