import { GraphiQLPage } from '@backstage-community/plugin-graphiql';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import type { IdentityApi } from '@backstage/core-plugin-api';
import {
  configApiRef,
  discoveryApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { ApiExplorerPage, apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { CatalogUnprocessedEntitiesPage } from '@backstage/plugin-catalog-unprocessed-entities';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  TechDocsReaderPage,
  techdocsPlugin,
} from '@backstage/plugin-techdocs';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { EndUserPortalPage } from '@internal/backstage-plugin-end-user-portal';
import { CostOverviewPage } from '@internal/plugin-metrics';
import {
  catalogApisReadPermission,
  catalogCostReadPermission,
  endUserPortalPermission,
  provisioningManageAllPermission,
} from '@internal/plugin-permissions-common';
import {
  DashboardPage,
  ProductPage,
  WorkspacePage,
} from '@internal/plugin-portal';
import {
  AzureGroupSearchExtension,
  AzureGroupSearchMultipleExtension,
  AzureUserSearchExtension,
  ProductSelectorExtension,
  ProvisioningPage,
  ServiceNameExtension,
  WorkspaceSelectorExtension,
} from '@provisioning/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import React, { Suspense } from 'react';
import { Navigate, Route } from 'react-router-dom';
import 'style-loader!css-loader!sass-loader!./main.scss';
import { themes } from './Theme';
import { apis } from './apis';
import { Root } from './components/Root';
import { TemplateCard } from './components/TemplateCard';
import { entityPage } from './components/catalog/EntityPage';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { searchPage } from './components/search/SearchPage';
import { setTokenCookie } from './cookieAuth';
import { NotificationsPage } from '@backstage/plugin-notifications';
import { SignalsDisplay } from '@backstage/plugin-signals';

const app = createApp({
  apis,
  themes,
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          {...(useApi(configApiRef).getOptionalString('auth.environment') ===
          'local'
            ? { providers: ['guest'] }
            : {
                provider: {
                  id: 'microsoft-auth-provider',
                  title: 'Azure',
                  message: 'Sign in using Azure AD',
                  apiRef: microsoftAuthApiRef,
                },
              })}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      // registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  featureFlags: [
    {
      // pluginId is required for feature flags in plugins.
      // It can be left blank for a feature flag leveraged in the application.
      pluginId: '',
      name: 'experimental',
      description: 'Enables experimental features',
    },
  ],
});

const queryClient = new QueryClient();

export default app.createRoot(
  <QueryClientProvider client={queryClient}>
    <AlertDisplay />
    <OAuthRequestDialog />
    <SignalsDisplay />
    <Suspense fallback={<div />}>
      <AppRouter>
        <Root>
          <FlatRoutes>
            <Route path="/" element={<Navigate to="workspaces" />} />
            <Route path="/workspaces" element={<WorkspacePage />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/catalog" element={<CatalogIndexPage />} />
            <Route
              path="/catalog/:namespace/:kind/:name"
              element={<CatalogEntityPage />}
            >
              {entityPage}
            </Route>
            <Route path="/notifications" element={<NotificationsPage />} />
            <Route path="/docs" element={<TechDocsIndexPage />} />
            <Route
              path="/docs/:namespace/:kind/:name/*"
              element={<TechDocsReaderPage />}
            >
              <TechDocsAddons>
                <ReportIssue />
                <Mermaid
                  config={{
                    theme: 'forest',
                    themeVariables: { lineColor: '#000000' },
                  }}
                />
              </TechDocsAddons>
            </Route>
            <Route
              path="/templates"
              element={
                <ScaffolderPage
                  templateFilter={entity =>
                    !entity.metadata?.tags?.includes('internal') ?? true
                  }
                  headerOptions={{
                    title: 'Templates',
                    subtitle:
                      'Get started quickly by using standardized templates',
                  }}
                  components={{
                    TemplateCardComponent: TemplateCard,
                  }}
                  groups={[
                    {
                      title: 'Data & AI',
                      filter: entity =>
                        (entity?.metadata?.tags?.includes('data') ||
                          entity?.metadata?.tags?.includes('ai-ml')) ??
                        false,
                    },
                    {
                      title: 'Frontend & Web Apps',
                      filter: entity =>
                        (entity?.metadata?.tags?.includes('frontend') ||
                          entity?.metadata?.tags?.includes('web-app')) ??
                        false,
                    },
                    {
                      title: 'Backends',
                      filter: entity =>
                        (entity?.metadata?.tags?.includes('backend') ||
                          entity?.metadata?.tags?.includes('api')) ??
                        false,
                    },
                  ]}
                />
              }
            >
              <ScaffolderFieldExtensions>
                <AzureGroupSearchExtension />
                <AzureGroupSearchMultipleExtension />
                <AzureUserSearchExtension />
                <ProductSelectorExtension />
                <WorkspaceSelectorExtension />
                <ServiceNameExtension />
              </ScaffolderFieldExtensions>
            </Route>
            <Route
              path="/cost-overview"
              element={
                <RequirePermission permission={catalogCostReadPermission}>
                  <CostOverviewPage />
                </RequirePermission>
              }
            />
            <FeatureFlagged with="experimental">
              <Route
                path="/end-user-portal"
                element={
                  <RequirePermission permission={endUserPortalPermission}>
                    <EndUserPortalPage />
                  </RequirePermission>
                }
              />
            </FeatureFlagged>
            <Route
              path="/api-docs"
              element={
                <RequirePermission permission={catalogApisReadPermission}>
                  <ApiExplorerPage />
                </RequirePermission>
              }
            />
            <Route
              path="/provisioning"
              element={
                <RequirePermission permission={provisioningManageAllPermission}>
                  <ProvisioningPage />
                </RequirePermission>
              }
            />
            <Route
              path="/devtools"
              element={
                <RequirePermission permission={provisioningManageAllPermission}>
                  <DevToolsPage />
                </RequirePermission>
              }
            >
              {customDevToolsPage}
            </Route>
            <FeatureFlagged with="experimental">
              <Route
                path="/catalog-import"
                element={
                  <RequirePermission permission={catalogEntityCreatePermission}>
                    <CatalogImportPage />
                  </RequirePermission>
                }
              />
            </FeatureFlagged>
            <Route path="/search" element={<SearchPage />}>
              {searchPage}
            </Route>
            <Route path="/settings" element={<UserSettingsPage />} />
            <Route path="/catalog-graph" element={<CatalogGraphPage />} />
            <Route path="/graphiql" element={<GraphiQLPage />} />
            <Route
              path="/catalog-unprocessed-entities"
              element={<CatalogUnprocessedEntitiesPage />}
            />
          </FlatRoutes>
        </Root>
      </AppRouter>
    </Suspense>
  </QueryClientProvider>,
);
