import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  centered: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
});
