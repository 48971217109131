import React from 'react';
import colors from 'style-loader!css-loader!sass-loader!../../colors.module.scss';
import { useTheme, KEY_DARK_THEME } from '@internal/plugin-argus-react';

const LogoIcon = () => {
  const currTheme = useTheme();
  const darkTheme = currTheme === KEY_DARK_THEME;

  return (
    <svg x="0px" y="0px" viewBox="0 0 28 17.1" className="argus-logo icon">
      <path
        fill={darkTheme ? colors.lightColor : colors.basfDarkBlueColor}
        d="M20.7,0h-1.5h-0.9L12,17.1h3.7l1.2-3.6H23l1.2,3.6H28L21.6,0H20.7z M17.8,10.7L20,4.1l2.2,6.6H17.8z"
      />
      <polygon
        fill={colors.basfLightBlueColor}
        points="14.3,0 8,17.1 4,17.1 10.3,0 "
      />
      <polygon
        fill={colors.basfDarkBlueColor}
        points="10.3,0 4,17.1 0,17.1 6.3,0 "
      />
    </svg>
  );
};

export default LogoIcon;
