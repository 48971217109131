import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  FieldExtensionComponentProps,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder-react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import {
  ArtifactSelectorFormData,
  ProductSelector,
} from '../components/ArtifactSelector';
import { entityRefValidation } from './helpers';

const ProductSelectorForm = (
  props: FieldExtensionComponentProps<ArtifactSelectorFormData>,
) => {
  const {
    onChange,
    schema: {
      title = 'Owned Argus Products',
      description = 'Select a Product that you own.',
    },
    rawErrors,
    required,
    formData,
  } = props;

  return (
    <FormControl
      margin="normal"
      required={required}
      error={(rawErrors?.length ?? 0) > 0 && !formData}
    >
      <ProductSelector
        title={title}
        onChange={onChange}
        required={required || false}
        formData={formData}
      />
      <FormHelperText id="entityName">{description}</FormHelperText>
    </FormControl>
  );
};

export const ProductSelectorExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ProductSelector',
    component: ProductSelectorForm,
    validation: entityRefValidation,
  }),
);
