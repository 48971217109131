import { UserEntity, isUserEntity } from '@backstage/catalog-model';

export type Address = {
  country?: string;
  state?: string;
  city?: string;
  street?: string;
};

export type UserProfile = {
  email: string;
  displayName: string;
  userPrincipalName?: string;
  mobilePhone?: string;
  company?: string;
  department?: string;
  companyId?: string;
  employeeId?: string;
  costCenter?: string;
  address?: Address;
  picture?: string;
};

export interface DevHubUserEntity extends UserEntity {
  spec: { profile: UserProfile };
}

export function isDevHubUserEntity(entity: any): entity is DevHubUserEntity {
  return isUserEntity(entity) && entity.spec?.profile?.email !== undefined;
}
