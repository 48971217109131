import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import { MsGraphEntity } from '@provisioning/common';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { MsGraphFormData } from './types';

export const AADSearchMultiple = ({
  title,
  onChange,
  required,
  fetchData,
  formData,
  showDescription = false,
}: {
  title: string;
  onChange: Function;
  required: boolean;
  fetchData: (input: string | undefined) => Promise<MsGraphEntity[]>;
  formData: MsGraphFormData[];
  showDescription?: boolean;
}) => {
  const [options, setOptions] = useState<MsGraphEntity[]>([]);
  const [input, setInput] = useState<string>('');
  const [trunk, setTrunk] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const getOptionLabel = (option: MsGraphEntity) => option?.displayName ?? '';
  const handleChangeDebounced = debounce(value => {
    setInput(value);
  }, 1000);

  useEffect(() => {
    if (input !== trunk) {
      setLoading(true);
      fetchData(input)
        .then(o =>
          setOptions(
            [...formData, ...o].filter(
              (value, index, array) =>
                array.findIndex(value2 => value2.id === value.id) === index,
            ),
          ),
        ) // Deduplicates values
        .catch(_ => setOptions([]));
      setTrunk(input);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, input, trunk]);

  const onInputChange = useCallback(
    (_: any, value: string, reason: string) => {
      if (reason === 'input') {
        setLoading(true);
        handleChangeDebounced(value);
      }
    },
    [handleChangeDebounced],
  );

  const isOptionEqualToValue = (option: MsGraphEntity, value: MsGraphEntity) =>
    option?.id === value?.id;

  const onChangeCallback = (_: any, value: MsGraphEntity[]) =>
    onChange(value, input);

  const renderOption = (props: any, option: MsGraphEntity) => (
    <li {...props}>
      <ListItemText
        primary={option.displayName}
        secondary={showDescription ? option.description : option.email}
      />
    </li>
  );

  const renderInput = (params: any) => (
    <TextField
      {...params}
      label={title}
      margin="dense"
      required={required}
      InputProps={{
        ...params.InputProps,
        endAdornment: loading ? (
          <CircularProgress
            {...params.InputProps.endAdornment}
            color="inherit"
            size={20}
          />
        ) : null,
      }}
    />
  );

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Autocomplete
        multiple
        options={options}
        loading={loading}
        value={formData}
        onInputChange={onInputChange}
        onChange={onChangeCallback}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        style={{ width: '100%' }}
        renderOption={renderOption}
        renderInput={renderInput}
        limitTags={4}
      />
    </div>
  );
};
