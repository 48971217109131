// @ts-nocheck
import { FromSchema, asConst } from 'json-schema-to-ts';
import { getTypeGuard } from '../validator';

export type StagedConfigDict<C> = {
  development: C;
  production?: C;
};

export type StagedConfig<K, C> = {
  kind: K;
  config: C | StagedConfigDict<C>;
};
const MysqlConfigBaseSchema = asConst({
  $defs: {
    DatabaseName: {
      maxLength: 63,
      minLength: 1,
      pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
      type: 'string',
    },
    MysqlConfigBase: {
      description:
        'Allows provisioning Mysql databases for use in product services.',
      properties: {
        version: {
          $ref: '#/$defs/MysqlVersion',
          default: '8.0.21',
          description: 'Version of Mysql server to use.',
          title: 'Mysql version',
        },
        skuName: {
          $ref: '#/$defs/MysqlSku',
          default: 'B_Standard_B1s',
          description:
            'SKU name to be used as base model for the Mysql server.',
          title: 'Mysql SKU',
        },
        storageSizeGb: {
          default: 32,
          description: 'Size of the Mysql storage in GB',
          title: 'Database storage capacity',
          type: 'integer',
        },
        backupRetentionDays: {
          default: 7,
          description: 'Number of days the automatic Mysql backup is kept',
          maximum: 35,
          minimum: 7,
          title: 'Backup retention days',
          type: 'integer',
        },
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Databases',
          type: 'array',
          'vsc:action': 'array',
        },
        serverParameters: {
          anyOf: [
            {
              $ref: '#/$defs/MysqlServerParameters',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Configurable server parameters.',
        },
      },
      title: 'MysqlConfigBase',
      type: 'object',
      additionalProperties: false,
    },
    MysqlServerParameters: {
      description:
        'The Mysql server parameters.\n\n[documentation]: https://www.mysql.com/docs/refman/8.0/en/server-system-variables.html',
      properties: {
        maxConnections: {
          anyOf: [
            {
              maximum: 5000,
              minimum: 25,
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The maximum number of concurrent connections to the database server.',
          title: 'Maxconnections',
        },
        sqlGenerateInvisiblePrimaryKey: {
          anyOf: [
            {
              enum: ['ON', 'OFF'],
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Sqlgenerateinvisibleprimarykey',
        },
      },
      title: 'MysqlServerParameters',
      type: 'object',
      additionalProperties: false,
    },
    MysqlSku: {
      description:
        'The Mysql server SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/mysql/concepts-compute-storage#compute-tiers-vcores-and-server-types',
      enum: ['B_Standard_B1s', 'GP_Standard_D2ads_v5', 'MO_Standard_E2ds_v4'],
      title: 'MysqlSku',
      type: 'string',
    },
    MysqlVersion: {
      const: '8.0.21',
      description:
        'The Mysql server version to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/mysql/concepts-supported-versions',
      enum: ['8.0.21'],
      title: 'MysqlVersion',
      type: 'string',
    },
  },
  $ref: '#/$defs/MysqlConfigBase',
  type: 'object',
});
export type MysqlConfigBase = FromSchema<typeof MysqlConfigBaseSchema>;
export const isMysqlConfigBase = getTypeGuard<MysqlConfigBase>(
  '#/$defs/MysqlConfigBase',
);
export type MysqlConfig = StagedConfig<'mysql', MysqlConfigBase>;
export const isMysqlConfig = getTypeGuard<MysqlConfig>('#/$defs/MysqlConfig');

const PostgresConfigBaseSchema = asConst({
  $defs: {
    DatabaseName: {
      maxLength: 63,
      minLength: 1,
      pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
      type: 'string',
    },
    PostgresConfigBase: {
      description:
        'Allows provisioning Postgres databases for use in product services.',
      properties: {
        version: {
          $ref: '#/$defs/PostgresVersion',
          default: '14',
          description: 'Version of postgres server to use.',
          title: 'Postgres version',
        },
        skuName: {
          $ref: '#/$defs/PostgresSku',
          default: 'B_Standard_B1ms',
          description:
            'SKU name to be used as base model for the Postgres server.',
          title: 'Postgres SKU',
        },
        storageSize: {
          $ref: '#/$defs/PostgresStorageSize',
          default: 32768,
          description: 'Size of the Postgres storage in MB',
          title: 'Database storage capacity',
        },
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Provisioned databases',
          type: 'array',
          'vsc:action': 'array',
        },
        backupRetentionDays: {
          default: 7,
          description: 'Number of days the automatic Postgres backup is kept',
          maximum: 35,
          minimum: 7,
          title: 'Backup retention',
          type: 'integer',
        },
        additionalAdminGroup: {
          additionalProperties: {
            format: 'uuid',
            type: 'string',
          },
          default: {},
          deprecated: true,
          description:
            'Additional administrators to be mapped to Azure Groups.',
          title: 'Additionaladmingroup',
          type: 'object',
        },
        ownerGroups: {
          default: [],
          deprecated: true,
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Ownergroups',
          type: 'array',
        },
        serverParameters: {
          anyOf: [
            {
              $ref: '#/$defs/PostgresServerParameters',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Configurable server parameters.',
        },
      },
      title: 'PostgresConfigBase',
      type: 'object',
      additionalProperties: false,
    },
    PostgresServerParameters: {
      description:
        'The Postgres server parameters. See [documentation]\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-server-parameters',
      properties: {
        maxConnections: {
          anyOf: [
            {
              maximum: 5000,
              minimum: 25,
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The maximum number of concurrent connections to the database server.',
          title: 'Maxconnections',
        },
      },
      title: 'PostgresServerParameters',
      type: 'object',
      additionalProperties: false,
    },
    PostgresSku: {
      description:
        'The Postgres server SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-compute-storage#compute-tiers-vcores-and-server-types',
      enum: [
        'B_Standard_B1ms',
        'B_Standard_B2s',
        'B_Standard_B2ms',
        'GP_Standard_D2ds_v5',
      ],
      title: 'PostgresSku',
      type: 'string',
    },
    PostgresStorageSize: {
      enum: [
        32768, 65536, 131072, 262144, 524288, 1048576, 2097152, 4193280,
        4194304, 8388608, 16777216, 33553408,
      ],
      type: 'integer',
    },
    PostgresVersion: {
      const: '14',
      description:
        'The Postgres server version to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/postgresql/flexible-server/concepts-supported-versions',
      enum: ['14'],
      title: 'PostgresVersion',
      type: 'string',
    },
  },
  $ref: '#/$defs/PostgresConfigBase',
  type: 'object',
});
export type PostgresConfigBase = FromSchema<typeof PostgresConfigBaseSchema>;
export const isPostgresConfigBase = getTypeGuard<PostgresConfigBase>(
  '#/$defs/PostgresConfigBase',
);
export type PostgresConfig = StagedConfig<'postgres', PostgresConfigBase>;
export const isPostgresConfig = getTypeGuard<PostgresConfig>(
  '#/$defs/PostgresConfig',
);

const StorageConfigBaseSchema = asConst({
  $defs: {
    StorageConfigBase: {
      description:
        'Allows provisioning storage containers within Azure storage accounts.\n\nThe `storage` capability provisions a [storage account] and enables users and\nmodules to request containers in that account.\n\n[storage account]: https://learn.microsoft.com/en-us/azure/storage/blobs/storage-blobs-introduction',
      properties: {
        externalAccess: {
          default: false,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        containers: {
          default: [],
          description:
            'Storage containers to be created in the account.\n\n### Warning: Reserved names\n\nDo not use the reserved container names `argus*`, `dagster`, `mlflow`.\nThis may lead to unexpected behavior until we have implemented conflict\nresolution on container names.',
          items: {
            $ref: '#/$defs/StorageContainerName',
          },
          title: 'Storage containers.',
          type: 'array',
          'vsc:action': 'array',
        },
        accountDataContributors: {
          default: [],
          description:
            'Object IDs for all principals to get account level data access.',
          items: {
            description: 'AAD object ID of the group.',
            format: 'uuid',
            title: 'AAD group',
            type: 'string',
            'ui:field': 'AzureGroupSearch',
            'vsc:picker': 'aad-group',
          },
          title: 'Account data contributors.',
          type: 'array',
          'vsc:action': 'array',
        },
        containerRoleAssignments: {
          default: [],
          description: 'Container specific role assignments.',
          items: {
            $ref: '#/$defs/StorageContainerRoleAssignment',
          },
          title: 'Container role assignments.',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      title: 'StorageConfigBase',
      type: 'object',
      additionalProperties: false,
    },
    StorageContainerName: {
      maxLength: 63,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    StorageContainerRole: {
      description:
        'Available storage roles to be assigned, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/role-based-access-control/built-in-roles',
      enum: [
        'Storage Blob Data Contributor',
        'Storage Blob Data Owner',
        'Storage Blob Data Reader',
      ],
      title: 'StorageContainerRole',
      type: 'string',
    },
    StorageContainerRoleAssignment: {
      properties: {
        objectId: {
          description: 'AAD entity for which the role is to be assigned.',
          format: 'uuid',
          title: 'Objectid',
          type: 'string',
        },
        containerName: {
          $ref: '#/$defs/StorageContainerName',
          description: 'The storage container this role applies to.',
        },
        role: {
          $ref: '#/$defs/StorageContainerRole',
          description: 'Role to be assigned to principal.',
        },
      },
      required: ['objectId', 'containerName', 'role'],
      title: 'StorageContainerRoleAssignment',
      type: 'object',
      additionalProperties: false,
    },
  },
  $ref: '#/$defs/StorageConfigBase',
  type: 'object',
});
export type StorageConfigBase = FromSchema<typeof StorageConfigBaseSchema>;
export const isStorageConfigBase = getTypeGuard<StorageConfigBase>(
  '#/$defs/StorageConfigBase',
);
export type StorageConfig = StagedConfig<'object-store', StorageConfigBase>;
export const isStorageConfig = getTypeGuard<StorageConfig>(
  '#/$defs/StorageConfig',
);

const KustoConfigBaseSchema = asConst({
  $defs: {
    DatabaseName: {
      maxLength: 63,
      minLength: 1,
      pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
      type: 'string',
    },
    KustoConfigBase: {
      description:
        'Allows provisioning Kusto databases for use in product services.',
      properties: {
        databases: {
          default: [],
          description: 'Additional databases to be created in the service.',
          items: {
            $ref: '#/$defs/DatabaseName',
          },
          title: 'Provisioned databases',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      title: 'KustoConfigBase',
      type: 'object',
      additionalProperties: false,
    },
  },
  $ref: '#/$defs/KustoConfigBase',
  type: 'object',
});
export type KustoConfigBase = FromSchema<typeof KustoConfigBaseSchema>;
export const isKustoConfigBase = getTypeGuard<KustoConfigBase>(
  '#/$defs/KustoConfigBase',
);
export type KustoConfig = StagedConfig<'kusto', KustoConfigBase>;
export const isKustoConfig = getTypeGuard<KustoConfig>('#/$defs/KustoConfig');

export type AnyCapabilityConfig =
  | MysqlConfig
  | PostgresConfig
  | StorageConfig
  | KustoConfig;

export type typesAnyCapabilityConfig = {
  mysql: MysqlConfigBase;
  postgres: PostgresConfigBase;
  'object-store': StorageConfigBase;
  kusto: KustoConfigBase;
};

export type AnyCapabilityConfigKind = keyof typesAnyCapabilityConfig;

export const schemasAnyCapabilityConfig = {
  mysql: '#/$defs/MysqlConfigBase',
  postgres: '#/$defs/PostgresConfigBase',
  'object-store': '#/$defs/StorageConfigBase',
  kusto: '#/$defs/KustoConfigBase',
};

export type typesAnyCapabilityConfigStaged = {
  mysql: MysqlConfig;
  postgres: PostgresConfig;
  'object-store': StorageConfig;
  kusto: KustoConfig;
};

const DagsterConfigSchema = asConst({
  $defs: {
    DagsterApplyLimitPerUniqueValue: {
      properties: {
        applyLimitPerUniqueValue: {
          title: 'Applylimitperuniquevalue',
          type: 'boolean',
        },
      },
      required: ['applyLimitPerUniqueValue'],
      title: 'DagsterApplyLimitPerUniqueValue',
      type: 'object',
      additionalProperties: false,
    },
    DagsterConfig: {
      description:
        'Orchestrator for the whole development lifecycle, with integrated lineage and observability\n\nThe [Dagster] module provides an instance of the Dagster orchestrator to run\nyour data-engineering, or machine learning pipelines.\n\n[Dagster]: https://docs.dagster.io/\n\nNote:\n    This module will only provision the core orchestrator. Dagster code locations\n    where you define your pipelines are deployed separately.',
      properties: {
        kind: {
          const: 'dagster',
          default: 'dagster',
          description: 'Module discriminator',
          enum: ['dagster'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '1.7.16',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/dagster',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Dagster UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        storeComputeLogsEnabled: {
          default: false,
          description: 'Compute logs in blob storage',
          title: 'Store compute logs',
          type: 'boolean',
        },
        userDeployments: {
          default: [],
          description: 'Code location servers.',
          items: {
            $ref: '#/$defs/DagsterUserDeployment',
          },
          title: 'Code locations',
          type: 'array',
          'vsc:action': 'array',
        },
        maxConcurrentRuns: {
          default: 10,
          description: 'Maximum number of concurrent job execution runs.',
          title: 'Maximum concurrent runs',
          type: 'integer',
        },
        tagConcurrencyLimits: {
          default: [],
          description:
            'List of tags and their corresponding concurrency limits.',
          items: {
            $ref: '#/$defs/DagsterTagConcurrencyLimits',
          },
          title: 'Tag based concurrency limits',
          type: 'array',
          'vsc:action': 'array',
        },
        defaultJobResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '512Mi',
            },
            limits: {
              cpu: '1000m',
              memory: '1Gi',
            },
          },
          description: 'Default resources applied to pipeline execution runs.',
          title: 'Default job resources',
        },
      },
      required: ['kind'],
      title: 'Dagster',
      type: 'object',
      additionalProperties: false,
    },
    DagsterTagConcurrencyLimits: {
      properties: {
        key: {
          title: 'Job tag key',
          type: 'string',
        },
        value: {
          anyOf: [
            {
              type: 'string',
            },
            {
              $ref: '#/$defs/DagsterApplyLimitPerUniqueValue',
            },
            {
              type: 'null',
            },
          ],
          title: 'Job tag value',
        },
        limit: {
          title: 'Maximum number of concurrent runs',
          type: 'integer',
        },
      },
      required: ['key', 'value', 'limit'],
      title: 'DagsterTagConcurrencyLimits',
      type: 'object',
      additionalProperties: false,
    },
    DagsterUserDeployment: {
      properties: {
        host: {
          $ref: '#/$defs/RFC1035Name',
          description: 'Host name of the code location server',
          title: 'Code location host',
        },
        port: {
          $ref: '#/$defs/PortNumber',
          default: 3030,
          description: 'Port number of the code location server',
          title: 'Code location port',
        },
      },
      required: ['host', 'port'],
      title: 'DagsterUserDeployment',
      type: 'object',
      additionalProperties: false,
    },
    K8sCpuType: {
      pattern: '^(([0-9]+[.])?[0-9]+)(m)?$',
      type: 'string',
    },
    K8sMemoryType: {
      pattern: '^([0-9]+)(Ki|Ei|Pi|Ti|Gi|Mi|[EPTGMKmk])?$',
      type: 'string',
    },
    K8sResourceConfig: {
      properties: {
        requests: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource requests.',
          title: 'Default resource requests',
        },
        limits: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource limits.',
          title: 'Default resource limits',
        },
      },
      required: ['requests', 'limits'],
      title: 'K8sResourceConfig',
      type: 'object',
      additionalProperties: false,
    },
    K8sResources: {
      properties: {
        cpu: {
          $ref: '#/$defs/K8sCpuType',
          description: 'Specifies the number of physical or virtual cores.',
          title: 'CPU resource units',
        },
        memory: {
          $ref: '#/$defs/K8sMemoryType',
          description: 'Specifies the number of bytes of physical memory.',
          title: 'Memory resource units',
        },
      },
      required: ['cpu', 'memory'],
      title: 'K8sResources',
      type: 'object',
      additionalProperties: false,
    },
    PortNumber: {
      maximum: 65535,
      minimum: 1,
      type: 'integer',
    },
    RFC1035Name: {
      maxLength: 62,
      minLength: 1,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    UrlPathSegment: {
      pattern: '^\\/[a-zA-Z0-9_\\.\\/\\-]*?$',
      type: 'string',
    },
  },
  $ref: '#/$defs/DagsterConfig',
  type: 'object',
});
export type DagsterConfig = FromSchema<typeof DagsterConfigSchema>;
export const isDagsterConfig = getTypeGuard<DagsterConfig>(
  '#/$defs/DagsterConfig',
);

const MlflowConfigSchema = asConst({
  $defs: {
    MlflowConfig: {
      description:
        'An open source platform for the machine learning lifecycle\n\nThe [MLflow] module provides an instance of the MlFLow platform\nfor machine learning lifecycle management.\n\n[MLflow]: https://mlflow.org/',
      properties: {
        kind: {
          const: 'mlflow',
          default: 'mlflow',
          description: 'Module discriminator',
          enum: ['mlflow'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '1.12.0',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/mlflow',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the MLflow UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'MlFlow',
      type: 'object',
      additionalProperties: false,
    },
    UrlPathSegment: {
      pattern: '^\\/[a-zA-Z0-9_\\.\\/\\-]*?$',
      type: 'string',
    },
  },
  $ref: '#/$defs/MlflowConfig',
  type: 'object',
});
export type MlflowConfig = FromSchema<typeof MlflowConfigSchema>;
export const isMlflowConfig = getTypeGuard<MlflowConfig>(
  '#/$defs/MlflowConfig',
);

const MemgraphConfigSchema = asConst({
  $defs: {
    K8sCpuType: {
      pattern: '^(([0-9]+[.])?[0-9]+)(m)?$',
      type: 'string',
    },
    K8sMemoryType: {
      pattern: '^([0-9]+)(Ki|Ei|Pi|Ti|Gi|Mi|[EPTGMKmk])?$',
      type: 'string',
    },
    K8sResources: {
      properties: {
        cpu: {
          $ref: '#/$defs/K8sCpuType',
          description: 'Specifies the number of physical or virtual cores.',
          title: 'CPU resource units',
        },
        memory: {
          $ref: '#/$defs/K8sMemoryType',
          description: 'Specifies the number of bytes of physical memory.',
          title: 'Memory resource units',
        },
      },
      required: ['cpu', 'memory'],
      title: 'K8sResources',
      type: 'object',
      additionalProperties: false,
    },
    MemgraphConfig: {
      description: 'A high-performance, in-memory graph database',
      properties: {
        kind: {
          const: 'memgraph',
          default: 'memgraph',
          description: 'Module discriminator',
          enum: ['memgraph'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '2.1.3',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        imageName: {
          default: 'memgraph/memgraph',
          description: 'Memgraph docker image NAME to be used',
          title: 'Image name',
          type: 'string',
        },
        imageTag: {
          default: '2.20.1',
          description: 'Memgraph docker image tag to be used',
          title: 'Image tag',
          type: 'string',
        },
        deployments: {
          default: [],
          description: 'Deployments of unique Memgraph instances.',
          items: {
            $ref: '#/$defs/MemgraphDeployment',
          },
          title: 'Memgraph deployments',
          type: 'array',
          'vsc:action': 'array',
        },
      },
      required: ['kind'],
      title: 'Memgraph',
      type: 'object',
      additionalProperties: false,
    },
    MemgraphDeployment: {
      properties: {
        name: {
          default: 'memgraph',
          title: 'Deployment name',
          type: 'string',
        },
        resourcesRequests: {
          $ref: '#/$defs/K8sResources',
          default: {
            cpu: '1000m',
            memory: '5000',
          },
          description: 'Request Kubernetes resources for Memgraph instance',
        },
        resourcesLimits: {
          $ref: '#/$defs/K8sResources',
          default: {
            cpu: '2000m',
            memory: '10000',
          },
          description: 'Limits on Kubernetes resources for Memgraph instance',
        },
        extraArgs: {
          description: 'Extra arguments to pass to Memgraph',
          items: {
            type: 'string',
          },
          title: 'Extraargs',
          type: 'array',
        },
      },
      title: 'MemgraphDeployment',
      type: 'object',
      additionalProperties: false,
    },
  },
  $ref: '#/$defs/MemgraphConfig',
  type: 'object',
});
export type MemgraphConfig = FromSchema<typeof MemgraphConfigSchema>;
export const isMemgraphConfig = getTypeGuard<MemgraphConfig>(
  '#/$defs/MemgraphConfig',
);

const JupyterHubConfigSchema = asConst({
  $defs: {
    JupyterHubConfig: {
      description:
        'Web-based interactive development environment for notebooks, code, and data.',
      properties: {
        kind: {
          const: 'jupyter',
          default: 'jupyter',
          description: 'Module discriminator',
          enum: ['jupyter'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        imageTag: {
          default: 'latest',
          description: 'Jupyter hub docker image tag to be used',
          title: 'Image tag',
          type: 'string',
        },
        environments: {
          default: [
            {
              image: 'basfargusglobal.azurecr.io/argus-jupyterhub-datascience',
              tag: 'latest',
              displayName: 'Default Data Science Notebook',
              description:
                'Default notebook containing various popular data science libraries.',
            },
          ],
          description:
            'List of user environments users can choose to run their notebook in.',
          items: {
            $ref: '#/$defs/UserEnvironment',
          },
          title: 'User environments',
          type: 'array',
        },
      },
      required: ['kind'],
      title: 'Jupyter Hub',
      type: 'object',
      additionalProperties: false,
    },
    UserEnvironment: {
      properties: {
        image: {
          description:
            'Docker image to be used when spawning this environment.',
          title: 'Base image',
          type: 'string',
        },
        tag: {
          default: 'latest',
          description: 'User image tag to be used',
          title: 'Image tag.',
          type: 'string',
        },
        displayName: {
          description: 'User image display name to be used',
          title: 'Display name.',
          type: 'string',
        },
        description: {
          description: 'User image description to be used',
          title: 'Description',
          type: 'string',
        },
      },
      required: ['image'],
      title: 'UserEnvironment',
      type: 'object',
      additionalProperties: false,
    },
  },
  $ref: '#/$defs/JupyterHubConfig',
  type: 'object',
});
export type JupyterHubConfig = FromSchema<typeof JupyterHubConfigSchema>;
export const isJupyterHubConfig = getTypeGuard<JupyterHubConfig>(
  '#/$defs/JupyterHubConfig',
);

const ArangoConfigSchema = asConst({
  $defs: {
    ArangoBackupPolicy: {
      description:
        'The BackupPolicy controls how Arango will create and retain backups.',
      properties: {
        enabled: {
          default: false,
          description:
            'Denotes if regular backups of data contained in arango are created.',
          title: 'Backups enabled.',
          type: 'boolean',
        },
        schedule: {
          default: '*/15 * * * *',
          description:
            'Defines the interval at which backups should be created.',
          pattern:
            '(@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\\d+(ns|us|\u00b5s|ms|s|m|h))+)|((((\\d+,)+\\d+|(\\d+(\\/|-)\\d+)|\\d+|\\*) ?){5,7})',
          title: 'Backup schedule',
          type: 'string',
        },
        maxBackups: {
          default: 10,
          description: 'Maximum number of backups that will be kept.',
          title: 'Maximum backups',
          type: 'integer',
        },
      },
      title: 'ArangoBackupPolicy',
      type: 'object',
      additionalProperties: false,
    },
    ArangoConfig: {
      description:
        'Scalable graph database for graph, document, and search functionality.',
      properties: {
        kind: {
          const: 'arango',
          default: 'arango',
          description: 'Module discriminator',
          enum: ['arango'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        operatorImageVersion: {
          default: '1.2.36',
          title: 'ArangoDb operator image version',
          type: 'string',
        },
        imageVersion: {
          default: '3.11.4',
          title: 'ArangoDb image version',
          type: 'string',
        },
        deploymentChartVersion: {
          default: '1.2.18',
          title: 'Arango deployment Helm chart version',
          type: 'string',
        },
        mode: {
          $ref: '#/$defs/Mode',
          default: 'Single',
          description:
            'Controls if the database is deployed as single instance or cluster.',
          title: 'Single instance or Cluster mode.',
        },
        metrics: {
          $ref: '#/$defs/ArangoMetrics',
          default: {
            enabled: false,
          },
          description:
            'Configuration on how metrics are emitted and collected.',
          title: 'Metrics configuration.',
        },
        backupPolicy: {
          $ref: '#/$defs/ArangoBackupPolicy',
          default: {
            enabled: false,
            schedule: '*/15 * * * *',
            maxBackups: 10,
          },
          description:
            'The backup policy controls creation and retention of database backups.',
          title: 'Backup policy',
        },
        resources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '1',
              memory: '1Gi',
            },
            limits: {
              cpu: '3',
              memory: '5Gi',
            },
          },
          description: 'Resource request and limit for the Arango workload.',
          title: 'Arango resources',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Arango UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'Arango DB',
      type: 'object',
      additionalProperties: false,
    },
    ArangoMetrics: {
      description: 'Metrics configuration controls how metrics are collected.',
      properties: {
        enabled: {
          default: false,
          description:
            'Denotes if metrics for the Arango deployment are collected.',
          title: 'Metrics enabled.',
          type: 'boolean',
        },
      },
      title: 'ArangoMetrics',
      type: 'object',
      additionalProperties: false,
    },
    K8sCpuType: {
      pattern: '^(([0-9]+[.])?[0-9]+)(m)?$',
      type: 'string',
    },
    K8sMemoryType: {
      pattern: '^([0-9]+)(Ki|Ei|Pi|Ti|Gi|Mi|[EPTGMKmk])?$',
      type: 'string',
    },
    K8sResourceConfig: {
      properties: {
        requests: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource requests.',
          title: 'Default resource requests',
        },
        limits: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource limits.',
          title: 'Default resource limits',
        },
      },
      required: ['requests', 'limits'],
      title: 'K8sResourceConfig',
      type: 'object',
      additionalProperties: false,
    },
    K8sResources: {
      properties: {
        cpu: {
          $ref: '#/$defs/K8sCpuType',
          description: 'Specifies the number of physical or virtual cores.',
          title: 'CPU resource units',
        },
        memory: {
          $ref: '#/$defs/K8sMemoryType',
          description: 'Specifies the number of bytes of physical memory.',
          title: 'Memory resource units',
        },
      },
      required: ['cpu', 'memory'],
      title: 'K8sResources',
      type: 'object',
      additionalProperties: false,
    },
    Mode: {
      description:
        'The type of deployment to be created.\n\nSee the [documentation] for details.\n\n[documentation]: https://docs.arangodb.com/stable/deploy/deployment/kubernetes/deployment-resource-reference/#specmode-string',
      enum: ['Single', 'Cluster', 'ActiveFailover'],
      title: 'Mode',
      type: 'string',
    },
  },
  $ref: '#/$defs/ArangoConfig',
  type: 'object',
});
export type ArangoConfig = FromSchema<typeof ArangoConfigSchema>;
export const isArangoConfig = getTypeGuard<ArangoConfig>(
  '#/$defs/ArangoConfig',
);

const SupersetConfigSchema = asConst({
  $defs: {
    K8sCpuType: {
      pattern: '^(([0-9]+[.])?[0-9]+)(m)?$',
      type: 'string',
    },
    K8sMemoryType: {
      pattern: '^([0-9]+)(Ki|Ei|Pi|Ti|Gi|Mi|[EPTGMKmk])?$',
      type: 'string',
    },
    K8sResourceConfig: {
      properties: {
        requests: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource requests.',
          title: 'Default resource requests',
        },
        limits: {
          $ref: '#/$defs/K8sResources',
          description: 'Default resource limits.',
          title: 'Default resource limits',
        },
      },
      required: ['requests', 'limits'],
      title: 'K8sResourceConfig',
      type: 'object',
      additionalProperties: false,
    },
    K8sResources: {
      properties: {
        cpu: {
          $ref: '#/$defs/K8sCpuType',
          description: 'Specifies the number of physical or virtual cores.',
          title: 'CPU resource units',
        },
        memory: {
          $ref: '#/$defs/K8sMemoryType',
          description: 'Specifies the number of bytes of physical memory.',
          title: 'Memory resource units',
        },
      },
      required: ['cpu', 'memory'],
      title: 'K8sResources',
      type: 'object',
      additionalProperties: false,
    },
    SupersetConfig: {
      description:
        'An open-source modern data exploration and visualization platform.',
      properties: {
        kind: {
          const: 'superset',
          default: 'superset',
          description: 'Module discriminator',
          enum: ['superset'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chartVersion: {
          default: '0.10.10',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Superset UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        globalResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '256Mi',
            },
            limits: {
              cpu: '500m',
              memory: '512Mi',
            },
          },
          description: 'Default Superset worker resources',
          title: 'Default Superset worker resources',
        },
        workerResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '200m',
              memory: '512Mi',
            },
            limits: {
              cpu: '500m',
              memory: '1Gi',
            },
          },
          description: 'Default Superset worker resources',
          title: 'Default Superset worker resources',
        },
        nodeResources: {
          $ref: '#/$defs/K8sResourceConfig',
          default: {
            requests: {
              cpu: '100m',
              memory: '128Mi',
            },
            limits: {
              cpu: '200m',
              memory: '256Mi',
            },
          },
          description: 'Default Superset node resources',
          title: 'Default Superset node resources',
        },
      },
      required: ['kind'],
      title: 'Superset',
      type: 'object',
      additionalProperties: false,
    },
    UrlPathSegment: {
      pattern: '^\\/[a-zA-Z0-9_\\.\\/\\-]*?$',
      type: 'string',
    },
  },
  $ref: '#/$defs/SupersetConfig',
  type: 'object',
});
export type SupersetConfig = FromSchema<typeof SupersetConfigSchema>;
export const isSupersetConfig = getTypeGuard<SupersetConfig>(
  '#/$defs/SupersetConfig',
);

const CloudBeaverConfigSchema = asConst({
  $defs: {
    CloudBeaverConfig: {
      properties: {
        kind: {
          const: 'cloudbeaver',
          default: 'cloudbeaver',
          description: 'Module discriminator',
          enum: ['cloudbeaver'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        uiPathPrefix: {
          $ref: '#/$defs/UrlPathSegment',
          default: '/dbeaver',
          description: 'Route where the main UI is served.',
          title: 'UI path prefix',
        },
        chartVersion: {
          default: '1.1.2',
          description: 'Version of the service chart to deploy.',
          title: 'Helm chart version',
          type: 'string',
        },
        allowedGroups: {
          default: [],
          description: 'AAD groups allowed to access the Cloudbeaver UI.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
      },
      required: ['kind'],
      title: 'CloudBeaver',
      type: 'object',
      additionalProperties: false,
    },
    UrlPathSegment: {
      pattern: '^\\/[a-zA-Z0-9_\\.\\/\\-]*?$',
      type: 'string',
    },
  },
  $ref: '#/$defs/CloudBeaverConfig',
  type: 'object',
});
export type CloudBeaverConfig = FromSchema<typeof CloudBeaverConfigSchema>;
export const isCloudBeaverConfig = getTypeGuard<CloudBeaverConfig>(
  '#/$defs/CloudBeaverConfig',
);

const OpenAiConfigSchema = asConst({
  $defs: {
    AzureRegion: {
      enum: ['westeurope', 'swedencentral', 'francecentral'],
      title: 'AzureRegion',
      type: 'string',
    },
    ModelUpdatePolicy: {
      description: 'Policy for updating the model.',
      enum: [
        'OnceNewDefaultVersionAvailable',
        'OnceCurrentVersionExpired',
        'NoAutoUpgrade',
      ],
      title: 'ModelUpdatePolicy',
      type: 'string',
    },
    OpenAIDeplyomentConfig: {
      properties: {
        name: {
          description: 'Name of the deployment',
          title: 'Name',
          type: 'string',
        },
        model: {
          $ref: '#/$defs/OpenAIModel',
          description: 'Model to deploy',
        },
        updatePolicy: {
          $ref: '#/$defs/ModelUpdatePolicy',
          default: 'OnceNewDefaultVersionAvailable',
          description: 'Policy for updating the model',
        },
        maxTokensPerMinuteThousands: {
          default: 1,
          description: 'Tokens-per-Minute (TPM) measured in thousands',
          maximum: 128,
          minimum: 1,
          title: 'tokens per minute (thousands)',
          type: 'integer',
        },
      },
      required: ['name', 'model'],
      title: 'OpenAIDeplyomentConfig',
      type: 'object',
      additionalProperties: false,
    },
    OpenAIEmbeddingConfig: {
      properties: {
        name: {
          description: 'Name of the deployment',
          title: 'Name',
          type: 'string',
        },
        model: {
          $ref: '#/$defs/OpenAIEmbeddingModel',
          description: 'Model to deploy',
        },
        updatePolicy: {
          $ref: '#/$defs/ModelUpdatePolicy',
          default: 'NoAutoUpgrade',
          description: 'Policy for updating the model',
        },
        maxTokensPerMinuteThousands: {
          default: 1,
          description: 'Tokens-per-Minute (TPM) measured in thousands',
          maximum: 128,
          minimum: 1,
          title: 'tokens per minute in thousands',
          type: 'integer',
        },
      },
      required: ['name', 'model'],
      title: 'OpenAIEmbeddingConfig',
      type: 'object',
      additionalProperties: false,
    },
    OpenAIEmbeddingModel: {
      enum: ['text-embedding-ada-002', 'text-embedding-3-large'],
      title: 'OpenAIEmbeddingModel',
      type: 'string',
    },
    OpenAIModel: {
      enum: [
        'gpt-4o-2024-08-06',
        'gpt-4o-2024-05-13',
        'gpt-4o-mini-2024-07-18',
        'gpt-4-turbo-2024-04-09',
        'gpt-35-turbo-1106',
      ],
      title: 'OpenAIModel',
      type: 'string',
    },
    OpenAiConfig: {
      properties: {
        kind: {
          const: 'openai',
          default: 'openai',
          description: 'Module discriminator',
          enum: ['openai'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        chatDeployments: {
          description: 'Chat models to deploy',
          items: {
            $ref: '#/$defs/OpenAIDeplyomentConfig',
          },
          title: 'Chatdeployments',
          type: 'array',
        },
        embeddingDeployments: {
          description: 'Embedding models to deploy',
          items: {
            $ref: '#/$defs/OpenAIEmbeddingConfig',
          },
          title: 'Embeddingdeployments',
          type: 'array',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'swedencentral',
          description: 'Region where cognitive services should be provisioned.',
          title: 'Azure region',
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
      },
      required: ['kind'],
      title: 'Azure Open AI',
      type: 'object',
      additionalProperties: false,
    },
  },
  $ref: '#/$defs/OpenAiConfig',
  type: 'object',
});
export type OpenAiConfig = FromSchema<typeof OpenAiConfigSchema>;
export const isOpenAiConfig = getTypeGuard<OpenAiConfig>(
  '#/$defs/OpenAiConfig',
);

const AzureAIServicesConfigSchema = asConst({
  $defs: {
    AzureAIServicesConfig: {
      properties: {
        kind: {
          const: 'ai-multi-service',
          default: 'ai-multi-service',
          description: 'Module discriminator',
          enum: ['ai-multi-service'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        externalAccess: {
          default: true,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
      },
      required: ['kind'],
      title: 'Azure AI Services',
      type: 'object',
      additionalProperties: false,
    },
  },
  $ref: '#/$defs/AzureAIServicesConfig',
  type: 'object',
});
export type AzureAIServicesConfig = FromSchema<
  typeof AzureAIServicesConfigSchema
>;
export const isAzureAIServicesConfig = getTypeGuard<AzureAIServicesConfig>(
  '#/$defs/AzureAIServicesConfig',
);

const AISearchServiceConfigBasicSchema = asConst({
  $defs: {
    AISearchServiceConfigBasic: {
      properties: {
        kind: {
          const: 'ai-search-basic',
          default: 'ai-search-basic',
          description: 'Module discriminator',
          enum: ['ai-search-basic'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        sku: {
          $ref: '#/$defs/AISearchSku',
          default: 'basic',
        },
        semanticRankerEnabled: {
          default: false,
          description: 'Enable semantic ranking',
          title: 'Semantic ranking',
          type: 'boolean',
        },
        externalAccess: {
          default: false,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        replicaCount: {
          default: 1,
          description: 'Number of replicas for the Azure Search service.',
          maximum: 3,
          title: 'Replica count',
          type: 'integer',
        },
        partition_count: {
          default: null,
          title: 'Partition Count',
          type: 'null',
        },
      },
      required: ['kind'],
      title: 'Azure AI Search',
      type: 'object',
      additionalProperties: false,
    },
    AISearchSku: {
      description:
        'The Azure Search service SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/search/search-sku-tier',
      enum: ['basic', 'standard'],
      title: 'AISearchSku',
      type: 'string',
    },
  },
  $ref: '#/$defs/AISearchServiceConfigBasic',
  type: 'object',
});
export type AISearchServiceConfigBasic = FromSchema<
  typeof AISearchServiceConfigBasicSchema
>;
export const isAISearchServiceConfigBasic =
  getTypeGuard<AISearchServiceConfigBasic>(
    '#/$defs/AISearchServiceConfigBasic',
  );

const AISearchServiceConfigStandardSchema = asConst({
  $defs: {
    AISearchServiceConfigStandard: {
      properties: {
        kind: {
          const: 'ai-search',
          default: 'ai-search',
          description: 'Module discriminator',
          enum: ['ai-search'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        sku: {
          $ref: '#/$defs/AISearchSku',
          default: 'standard',
        },
        semanticRankerEnabled: {
          default: false,
          description: 'Enable semantic ranking',
          title: 'Semantic ranking',
          type: 'boolean',
        },
        externalAccess: {
          default: false,
          description:
            'Enable external access to the service via the product Gateway.',
          title: 'External access',
          type: 'boolean',
        },
        replicaCount: {
          default: 1,
          description: 'Number of replicas for the Azure Search service.',
          maximum: 12,
          title: 'Replica count',
          type: 'integer',
        },
        partitionCount: {
          anyOf: [
            {
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'Number of partitions for the Azure Search service.',
          title: 'Partition count',
        },
      },
      required: ['kind'],
      title: 'Azure AI Search',
      type: 'object',
      additionalProperties: false,
    },
    AISearchSku: {
      description:
        'The Azure Search service SKU name to be used, see the [documentation] for details.\n\n[documentation]: https://learn.microsoft.com/en-us/azure/search/search-sku-tier',
      enum: ['basic', 'standard'],
      title: 'AISearchSku',
      type: 'string',
    },
  },
  $ref: '#/$defs/AISearchServiceConfigStandard',
  type: 'object',
});
export type AISearchServiceConfigStandard = FromSchema<
  typeof AISearchServiceConfigStandardSchema
>;
export const isAISearchServiceConfigStandard =
  getTypeGuard<AISearchServiceConfigStandard>(
    '#/$defs/AISearchServiceConfigStandard',
  );

const RedisConfigSchema = asConst({
  $defs: {
    RedisArchitecture: {
      enum: ['standalone', 'replication'],
      title: 'RedisArchitecture',
      type: 'string',
    },
    RedisConfig: {
      properties: {
        kind: {
          const: 'redis',
          default: 'redis',
          description: 'Module discriminator',
          enum: ['redis'],
          title: 'Kind',
          type: 'string',
          'ui:disabled': true,
        },
        architecture: {
          $ref: '#/$defs/RedisArchitecture',
          default: 'standalone',
          title: 'Architecture',
        },
        version: {
          default: '19.6.0',
          title: 'Redis Helm chart version',
          type: 'string',
        },
        imageTag: {
          default: '7.2.0',
          title: 'Redis version to use',
          type: 'string',
        },
        storageSize: {
          default: '1Gi',
          title: 'Redis persistence volume size',
          type: 'string',
        },
      },
      required: ['kind'],
      title: 'RedisConfig',
      type: 'object',
      additionalProperties: false,
    },
  },
  $ref: '#/$defs/RedisConfig',
  type: 'object',
});
export type RedisConfig = FromSchema<typeof RedisConfigSchema>;
export const isRedisConfig = getTypeGuard<RedisConfig>('#/$defs/RedisConfig');

export type AnyModuleConfig =
  | DagsterConfig
  | MlflowConfig
  | MemgraphConfig
  | JupyterHubConfig
  | ArangoConfig
  | SupersetConfig
  | CloudBeaverConfig
  | OpenAiConfig
  | AzureAIServicesConfig
  | AISearchServiceConfigBasic
  | AISearchServiceConfigStandard
  | RedisConfig;

export type typesAnyModuleConfig = {
  dagster: DagsterConfig;
  mlflow: MlflowConfig;
  memgraph: MemgraphConfig;
  jupyter: JupyterHubConfig;
  arango: ArangoConfig;
  superset: SupersetConfig;
  cloudbeaver: CloudBeaverConfig;
  openai: OpenAiConfig;
  'ai-multi-service': AzureAIServicesConfig;
  'ai-search-basic': AISearchServiceConfigBasic;
  'ai-search': AISearchServiceConfigStandard;
  redis: RedisConfig;
};

export type AnyModuleConfigKind = keyof typesAnyModuleConfig;

export const schemasAnyModuleConfig = {
  dagster: '#/$defs/DagsterConfig',
  mlflow: '#/$defs/MlflowConfig',
  memgraph: '#/$defs/MemgraphConfig',
  jupyter: '#/$defs/JupyterHubConfig',
  arango: '#/$defs/ArangoConfig',
  superset: '#/$defs/SupersetConfig',
  cloudbeaver: '#/$defs/CloudBeaverConfig',
  openai: '#/$defs/OpenAiConfig',
  'ai-multi-service': '#/$defs/AzureAIServicesConfig',
  'ai-search-basic': '#/$defs/AISearchServiceConfigBasic',
  'ai-search': '#/$defs/AISearchServiceConfigStandard',
  redis: '#/$defs/RedisConfig',
};
const AnyManifestSpecSchema = asConst({
  $defs: {
    AzureDevOpsProject: {
      description:
        'Configuration for an Azure DevOps project integrated with the Argus platform.',
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        organization: {
          default: 'basf4dev',
          description:
            'The Azure DevOps organization where the project resides.',
          title: 'Azure DevOps organization',
          type: 'string',
        },
      },
      required: ['name'],
      title: 'AzureDevOpsProject',
      type: 'object',
      additionalProperties: false,
    },
    AzureRegion: {
      enum: ['westeurope', 'swedencentral', 'francecentral'],
      title: 'AzureRegion',
      type: 'string',
    },
    FeatureFlag: {
      enum: ['github-env-next', 'managed-report-types', 'structured-logging'],
      title: 'FeatureFlag',
      type: 'string',
    },
    GitHubTeamConfig: {
      properties: {
        name: {
          description: 'The name of the team.',
          title: 'Team namme',
          type: 'string',
        },
        description: {
          description: 'The description of the team.',
          title: 'Description',
          type: 'string',
        },
        objectId: {
          description:
            'The object ID of the group that represents the team in AAD.',
          format: 'uuid',
          title: 'Objectid',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['name', 'description', 'objectId'],
      title: 'GitHubTeamConfig',
      type: 'object',
      additionalProperties: false,
    },
    InfraCellRef: {
      description:
        'A reference to a infrstructure cell associated with a logical stage.',
      properties: {
        name: {
          description: 'The globally unique name of the cell.',
          title: 'name',
          type: 'string',
        },
        stage: {
          $ref: '#/$defs/LogicalStage',
          description:
            'The logical stage describes the order of a cell within a deployment squence.',
          title: 'logical stage',
        },
      },
      required: ['name', 'stage'],
      title: 'InfraCellRef',
      type: 'object',
      additionalProperties: false,
    },
    InstanceSpec: {
      properties: {
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
        productCells: {
          description: 'List of product cells.',
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Product cells',
          type: 'array',
        },
      },
      title: 'InstanceSpec',
      type: 'object',
      additionalProperties: false,
    },
    K8sCpuType: {
      pattern: '^(([0-9]+[.])?[0-9]+)(m)?$',
      type: 'string',
    },
    K8sMemoryType: {
      pattern: '^([0-9]+)(Ki|Ei|Pi|Ti|Gi|Mi|[EPTGMKmk])?$',
      type: 'string',
    },
    K8sResources: {
      properties: {
        cpu: {
          $ref: '#/$defs/K8sCpuType',
          description: 'Specifies the number of physical or virtual cores.',
          title: 'CPU resource units',
        },
        memory: {
          $ref: '#/$defs/K8sMemoryType',
          description: 'Specifies the number of bytes of physical memory.',
          title: 'Memory resource units',
        },
      },
      required: ['cpu', 'memory'],
      title: 'K8sResources',
      type: 'object',
      additionalProperties: false,
    },
    Lifecycle: {
      enum: ['experimental', 'development', 'production', 'end-of-life'],
      title: 'Lifecycle',
      type: 'string',
    },
    LinkedGitHubTeamConfig: {
      properties: {
        slug: {
          description: 'The slug of the githu team.',
          title: 'Team namme',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['slug'],
      title: 'LinkedGitHubTeamConfig',
      type: 'object',
      additionalProperties: false,
    },
    LogicalStage: {
      enum: ['development', 'staging', 'production'],
      title: 'LogicalStage',
      type: 'string',
    },
    PlatformSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        devTeams: {
          description: 'Platform teams assigned platform level roles.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Development teams',
          type: 'array',
        },
        teams: {
          description:
            'Teams that are assigned permissions on the platform instance.',
          items: {
            $ref: '#/$defs/PlatformTeam',
          },
          title: 'Platform teams',
          type: 'array',
        },
      },
      required: ['owner', 'primaryContact', 'secondaryContact', 'uniqueKey'],
      title: 'PlatformSpec',
      type: 'object',
      additionalProperties: false,
    },
    PlatformTeam: {
      properties: {
        name: {
          title: 'Name',
          type: 'string',
        },
        description: {
          title: 'Description',
          type: 'string',
        },
        role: {
          $ref: '#/$defs/PlatformTeamRole',
        },
        objectId: {
          format: 'uuid',
          title: 'Object ID',
          type: 'string',
        },
      },
      required: ['name', 'description', 'role', 'objectId'],
      title: 'PlatformTeam',
      type: 'object',
      additionalProperties: false,
    },
    PlatformTeamRole: {
      enum: [
        'admin',
        'infra-admin',
        'developer',
        'stakeholder',
        'firefighter',
        'product-owner',
      ],
      title: 'PlatformTeamRole',
      type: 'string',
    },
    ProductCellSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        instance: {
          description: 'Platform isntance name.',
          title: 'Platform instance',
          type: 'string',
        },
      },
      required: [
        'owner',
        'primaryContact',
        'secondaryContact',
        'uniqueKey',
        'instance',
      ],
      title: 'ProductCellSpec',
      type: 'object',
      additionalProperties: false,
    },
    ProductSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        workspace: {
          description: 'Name of the workspace this product is managed by.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'Workspace name',
          type: 'string',
        },
        resourceQuotaRequests: {
          $ref: '#/$defs/K8sResources',
          description: 'Requested Kubernetes resources for product namespace.',
          title: 'Resource requests',
        },
        resourceQuotaLimits: {
          $ref: '#/$defs/K8sResources',
          description:
            'Upper limit for Kubernetes resources for product namespace.',
          title: 'Resource limits',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
      },
      required: [
        'owner',
        'primaryContact',
        'secondaryContact',
        'uniqueKey',
        'workspace',
      ],
      title: 'ProductSpec',
      type: 'object',
      additionalProperties: false,
    },
    TeamRole: {
      enum: ['developers', 'admins'],
      title: 'TeamRole',
      type: 'string',
    },
    UniqueKeyType: {
      maxLength: 3,
      minLength: 3,
      pattern: '^[a-z]*$',
      type: 'string',
    },
    WorkspaceSpec: {
      properties: {
        owner: {
          description:
            'AAD object ID or display name for the group owning the entity.',
          format: 'uuid',
          title: 'Owner group',
          type: 'string',
          'ui:field': 'AzureGroupSearch',
          'vsc:picker': 'aad-group',
        },
        primaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description: 'AAD object id or email of the primary contact person.',
          title: 'Primary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        secondaryContact: {
          anyOf: [
            {
              format: 'uuid',
              type: 'string',
            },
            {
              format: 'email',
              type: 'string',
            },
          ],
          description:
            'AAD object id or email of the secondary contact person.',
          title: 'Secondary contact',
          'ui:field': 'AzureUserSearch',
          'vsc:picker': 'aad-user',
        },
        uniqueKey: {
          $ref: '#/$defs/UniqueKeyType',
          description: 'A unique identifier for the entity.',
          title: 'Unique key.',
          'ui:disabled': true,
        },
        lifecycle: {
          $ref: '#/$defs/Lifecycle',
          default: 'production',
          description: 'Lifecycle of the entity.',
          title: 'Entity lifecycle stage.',
        },
        featureFlags: {
          default: [],
          description:
            'Feature flags allow op-ting into experimental functionality in the stack',
          items: {
            $ref: '#/$defs/FeatureFlag',
          },
          title: 'Feature flags.',
          type: 'array',
          'vsc:action': 'array',
        },
        location: {
          $ref: '#/$defs/AzureRegion',
          default: 'westeurope',
          description: 'Azure region where resources should be provisioned.',
          title: 'Location',
        },
        devOps: {
          anyOf: [
            {
              $ref: '#/$defs/AzureDevOpsProject',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Azure DevOps project, where workspace presence is to be installed.',
          title: 'Azure DevOps project',
        },
        devTeams: {
          description:
            'List of development teams that should have access to the workspace.',
          items: {
            $ref: '#/$defs/GitHubTeamConfig',
          },
          title: 'Development teams',
          type: 'array',
        },
        linkedTeams: {
          description:
            'List of teams that are assigned permissions on workspace repositories.',
          items: {
            $ref: '#/$defs/LinkedGitHubTeamConfig',
          },
          title: 'Linked GitHub teams',
          type: 'array',
        },
      },
      required: ['owner', 'primaryContact', 'secondaryContact', 'uniqueKey'],
      title: 'WorkspaceSpec',
      type: 'object',
      additionalProperties: false,
    },
  },
  properties: {
    config: {
      anyOf: [
        {
          $ref: '#/$defs/ProductCellSpec',
        },
        {
          $ref: '#/$defs/WorkspaceSpec',
        },
        {
          $ref: '#/$defs/InstanceSpec',
        },
        {
          $ref: '#/$defs/ProductSpec',
        },
        {
          $ref: '#/$defs/PlatformSpec',
        },
      ],
      description: 'Manifest spec type',
      title: 'Config',
    },
  },
  required: ['config'],
  title: 'AnyManifestSpec',
  type: 'object',
});

const ProductCellSpecSchema = asConst({
  $defs: AnyManifestSpecSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ProductCellSpec',
});
export type ProductCellSpec = FromSchema<typeof ProductCellSpecSchema>;

const WorkspaceSpecSchema = asConst({
  $defs: AnyManifestSpecSchema.$defs,
  type: 'object',
  $ref: '#/$defs/WorkspaceSpec',
});
export type WorkspaceSpec = FromSchema<typeof WorkspaceSpecSchema>;

const InstanceSpecSchema = asConst({
  $defs: AnyManifestSpecSchema.$defs,
  type: 'object',
  $ref: '#/$defs/InstanceSpec',
});
export type InstanceSpec = FromSchema<typeof InstanceSpecSchema>;

const ProductSpecSchema = asConst({
  $defs: AnyManifestSpecSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ProductSpec',
});
export type ProductSpec = FromSchema<typeof ProductSpecSchema>;

const PlatformSpecSchema = asConst({
  $defs: AnyManifestSpecSchema.$defs,
  type: 'object',
  $ref: '#/$defs/PlatformSpec',
});
export type PlatformSpec = FromSchema<typeof PlatformSpecSchema>;

const AnyExtraConfigSchema = asConst({
  $defs: {
    ApplicationConfig: {
      properties: {
        requiredResourceAccess: {
          description: 'Additional permissions on the product application.',
          items: {
            discriminator: {
              mapping: {
                'microsoft-graph':
                  '#/$defs/ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_',
              },
              propertyName: 'resource',
            },
            oneOf: [
              {
                $ref: '#/$defs/ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_',
              },
            ],
          },
          title: 'Additional resource accesses',
          type: 'array',
        },
        extraRedirectUrls: {
          additionalProperties: {
            items: {
              type: 'string',
            },
            type: 'array',
          },
          description: 'Additional callback URLs for the product application.',
          examples: [
            {
              development: ['http://localhost:3000'],
            },
          ],
          title: 'Extra callback URLs',
          type: 'object',
        },
      },
      title: 'ApplicationConfig',
      type: 'object',
      additionalProperties: false,
    },
    ArtifactType: {
      description: 'Artifact type.',
      enum: ['python-library', 'npm-library', 'oci', 'workload', 'techdocs'],
      title: 'ArtifactType',
      type: 'string',
    },
    CodeLocation: {
      properties: {
        repository: {
          description: 'Repository name.',
          title: 'Repository',
          type: 'string',
        },
        artifacts: {
          description: 'List of artifacts.',
          items: {
            $ref: '#/$defs/ArtifactType',
          },
          title: 'Artifacts',
          type: 'array',
        },
        workflow: {
          $ref: '#/$defs/Workflow',
          default: 'github-flow',
        },
        ruleset: {
          $ref: '#/$defs/RuleSet',
          default: 'base',
          description: 'Ruleset to apply to the code location.',
          title: 'Ruleset',
        },
      },
      required: ['repository', 'artifacts'],
      title: 'CodeLocation',
      type: 'object',
      additionalProperties: false,
    },
    Domain: {
      enum: ['data', 'app'],
      title: 'Domain',
      type: 'string',
    },
    EntityName: {
      maxLength: 253,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    ExposedServiceConfig: {
      properties: {
        name: {
          description: 'Name af the K8s service inside the product namespace.',
          examples: ['k8s-service-name'],
          maxLength: 62,
          minLength: 1,
          pattern:
            'prodai_ada|ai_store|digital_twin|prodai_nasa|competitor_intelligence|app_dev_enablement|scott_solutions|spinal_os|dob_aisol|advanced_imaging|forecasting_platform|data_and_ai_academy|ai_solutions|gb_gtu|^[a-z]+(?:-?[a-z0-9]+)+$',
          title: 'Service name.',
          type: 'string',
        },
        port: {
          $ref: '#/$defs/PortNumber',
          default: 80,
          description: 'Target port of the service.',
          title: 'Service port.',
        },
        domains: {
          default: ['app'],
          description: 'The domains this service is available at.',
          items: {
            $ref: '#/$defs/Domain',
          },
          title: 'Routing domains',
          type: 'array',
        },
        routes: {
          description: 'Routes exposed on the service.',
          items: {
            $ref: '#/$defs/RoutingRule',
          },
          title: 'Service routes',
          type: 'array',
        },
      },
      required: ['name', 'routes'],
      title: 'ExposedServiceConfig',
      type: 'object',
      additionalProperties: false,
    },
    MsGraphPermission: {
      description: 'Microsoft Graph permissions',
      enum: [
        'delegated/user.read.all',
        'delegated/group.read.all',
        'application/user.read.all',
        'application/group.read.all',
      ],
      title: 'MsGraphPermission',
      type: 'string',
    },
    PathMatchType: {
      enum: ['prefix', 'exact'],
      title: 'PathMatchType',
      type: 'string',
    },
    PortNumber: {
      maximum: 65535,
      minimum: 1,
      type: 'integer',
    },
    ResourceAccessConfig_Literal_MS_GRAPH__MsGraphPermission_: {
      properties: {
        resource: {
          const: 'microsoft-graph',
          description: 'The Azure resource for which the access is configured.',
          enum: ['microsoft-graph'],
          title: 'Azure resource',
          type: 'string',
        },
        resourceAccess: {
          description: 'Access configuration for the selected resource.',
          items: {
            $ref: '#/$defs/MsGraphPermission',
          },
          title: 'Resource access',
          type: 'array',
          uniqueItems: true,
        },
      },
      required: ['resource'],
      title:
        "ResourceAccessConfig[Literal[<AzureResource.MS_GRAPH: 'microsoft-graph'>], MsGraphPermission]",
      type: 'object',
      additionalProperties: false,
    },
    RoutingConfig: {
      properties: {
        exposedServices: {
          description: 'Product services exposed to clients or other products.',
          items: {
            $ref: '#/$defs/ExposedServiceConfig',
          },
          title: 'Exposed services',
          type: 'array',
        },
        linkedProducts: {
          description:
            'Products that are allowed to invoke services from this product.',
          items: {
            $ref: '#/$defs/EntityName',
          },
          title: 'Linked products',
          type: 'array',
        },
        subDomainOverride: {
          anyOf: [
            {
              $ref: '#/$defs/SubDomain',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Custom sub-domain for apps and services deployed in the product.',
          title: 'Subdomain override',
        },
      },
      title: 'RoutingConfig',
      type: 'object',
      additionalProperties: false,
    },
    RoutingRule: {
      properties: {
        path: {
          $ref: '#/$defs/UrlPathSegment',
          title: 'Matched path segment.',
        },
        pathMatch: {
          $ref: '#/$defs/PathMatchType',
          default: 'prefix',
          title: 'Path matcher type.',
        },
        pathRewrite: {
          anyOf: [
            {
              $ref: '#/$defs/UrlPathSegment',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Rewrite matched path before sending request to downstream service.',
          title: 'Path rewrite',
        },
        allowedGroups: {
          default: [],
          description: 'Restrict access to this route to specific AAD groups.',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        timeout: {
          default: 15,
          title: 'Timeout in seconds: e.g. 5',
          type: 'integer',
        },
        unsafeDisableAuth: {
          default: false,
          description: 'UNSAFE - Disable authentication for this route.',
          title: 'Disable authentication',
          type: 'boolean',
        },
        autoHostRewrite: {
          anyOf: [
            {
              type: 'boolean',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Rewrite the host header to match the target service host.',
          title: 'Auto host rewrite',
        },
      },
      required: ['path'],
      title: 'RoutingRule',
      type: 'object',
      additionalProperties: false,
    },
    RuleSet: {
      enum: ['base', 'strict'],
      title: 'RuleSet',
      type: 'string',
    },
    SubDomain: {
      maxLength: 50,
      minLength: 3,
      pattern: '^[a-z]+(?:-?[a-z0-9]+)+$',
      type: 'string',
    },
    UrlPathSegment: {
      pattern: '^\\/[a-zA-Z0-9_\\.\\/\\-]*?$',
      type: 'string',
    },
    Workflow: {
      const: 'github-flow',
      description: 'Workflow configured on a code location.',
      enum: ['github-flow'],
      title: 'Workflow',
      type: 'string',
    },
  },
  properties: {
    config: {
      anyOf: [
        {
          $ref: '#/$defs/ApplicationConfig',
        },
        {
          $ref: '#/$defs/RoutingConfig',
        },
        {
          $ref: '#/$defs/CodeLocation',
        },
      ],
      description: 'Additional configs applied at manifest level',
      title: 'Config',
    },
  },
  required: ['config'],
  title: 'AnyExtraConfig',
  type: 'object',
});

const ApplicationConfigSchema = asConst({
  $defs: AnyExtraConfigSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ApplicationConfig',
});
export type ApplicationConfig = FromSchema<typeof ApplicationConfigSchema>;

const RoutingConfigSchema = asConst({
  $defs: AnyExtraConfigSchema.$defs,
  type: 'object',
  $ref: '#/$defs/RoutingConfig',
});
export type RoutingConfig = FromSchema<typeof RoutingConfigSchema>;

const CodeLocationSchema = asConst({
  $defs: AnyExtraConfigSchema.$defs,
  type: 'object',
  $ref: '#/$defs/CodeLocation',
});
export type CodeLocation = FromSchema<typeof CodeLocationSchema>;

const ExposedServiceConfigSchema = asConst({
  $defs: AnyExtraConfigSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ExposedServiceConfig',
});
export type ExposedServiceConfig = FromSchema<
  typeof ExposedServiceConfigSchema
>;

const AnyMetadataSchema = asConst({
  $defs: {
    ManifestMetadata: {
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
        role: {
          $ref: '#/$defs/ManifestRole',
          default: 'product',
          description: 'Role of the entity.',
          title: 'Role',
        },
      },
      required: ['name', 'namespace'],
      title: 'ManifestMetadata',
      type: 'object',
      additionalProperties: false,
    },
    ManifestRole: {
      enum: ['platform', 'product'],
      title: 'ManifestRole',
      type: 'string',
    },
    Metadata: {
      description: 'Metadata fields describing the resource',
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
      },
      required: ['name', 'namespace'],
      title: 'Metadata',
      type: 'object',
      additionalProperties: false,
    },
  },
  properties: {
    config: {
      anyOf: [
        {
          $ref: '#/$defs/ManifestMetadata',
        },
        {
          $ref: '#/$defs/Metadata',
        },
      ],
      title: 'Config',
    },
  },
  required: ['config'],
  title: 'AnyMetadata',
  type: 'object',
});

const ManifestMetadataSchema = asConst({
  $defs: AnyMetadataSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ManifestMetadata',
});
export type ManifestMetadata = FromSchema<typeof ManifestMetadataSchema>;

const MetadataSchema = asConst({
  $defs: AnyMetadataSchema.$defs,
  type: 'object',
  $ref: '#/$defs/Metadata',
});
export type Metadata = FromSchema<typeof MetadataSchema>;
const AnyReportSchema = asConst({
  $defs: {
    AcrResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'acr',
          default: 'acr',
          enum: ['acr'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the ACR is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        sku: {
          description: 'The SKU of the ACR.',
          title: 'SKU',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'sku', 'resourceId'],
      title: 'AcrResource',
      type: 'object',
      additionalProperties: false,
    },
    ApplicationInfo: {
      properties: {
        kind: {
          const: 'application',
          default: 'application',
          enum: ['application'],
          title: 'Kind',
          type: 'string',
        },
        clientId: {
          title: 'Application client ID.',
          type: 'string',
        },
        objectId: {
          title: 'Application object ID.',
          type: 'string',
        },
        displayName: {
          title: 'Application display name.',
          type: 'string',
        },
      },
      required: ['kind', 'clientId', 'objectId', 'displayName'],
      title: 'ApplicationInfo',
      type: 'object',
      additionalProperties: false,
    },
    BaseResourceInfo: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
      },
      required: ['resourceId'],
      title: 'BaseResourceInfo',
      type: 'object',
      additionalProperties: false,
    },
    CognitiveAccountResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'cognitive-services',
          default: 'cognitive-services',
          enum: ['cognitive-services'],
          title: 'Kind',
          type: 'string',
        },
        accountType: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The type of the Cognitive Services account.',
          title: 'Account type',
        },
        location: {
          description: 'The location where the Cognitive Services is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        endpoint: {
          description: 'The FQDN of the Azure Cognitive Services.',
          title: 'Cognitive Services URI',
          type: 'string',
        },
      },
      required: ['resourceId', 'location', 'endpoint'],
      title: 'CognitiveAccountResource',
      type: 'object',
      additionalProperties: false,
    },
    CognitiveDeploymentsResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'cognitive-deployment',
          default: 'cognitive-deployment',
          enum: ['cognitive-deployment'],
          title: 'Kind',
          type: 'string',
        },
        name: {
          description: 'The name of the Cognitive Services deployment.',
          title: 'Deployment name',
          type: 'string',
        },
        modelName: {
          description: 'The name of the Cognitive Services model.',
          title: 'Model name',
          type: 'string',
        },
        modelVersion: {
          description: 'The version of the Cognitive Services model.',
          title: 'Model version',
          type: 'string',
        },
      },
      required: ['resourceId', 'name', 'modelName', 'modelVersion'],
      title: 'CognitiveDeploymentsResource',
      type: 'object',
      additionalProperties: false,
    },
    CustomDomainReport: {
      properties: {
        nodeId: {
          description: 'The ID of the node in the cluster.',
          title: 'Node ID',
          type: 'string',
        },
        cert: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Name of the certificate',
        },
        kvId: {
          anyOf: [
            {
              description: 'Canonical reference to resource.',
              title: 'Azure resource id.',
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Keyvault Resource ID',
        },
        role: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        url: {
          description: 'The URL of the domain.',
          title: 'URL',
          type: 'string',
        },
        flavour: {
          $ref: '#/$defs/Domain',
          description: 'The flavour of the domain.',
          title: 'Flavour',
        },
        product: {
          description: 'Name of the Product this URL is used for',
          title: 'Product Name',
          type: 'string',
        },
      },
      required: ['nodeId', 'role', 'url', 'flavour', 'product'],
      title: 'CustomDomainReport',
      type: 'object',
      additionalProperties: false,
    },
    DataDogIntegrationInfo: {
      properties: {
        agent_object_id: {
          title: 'Agent Object Id',
          type: 'string',
        },
      },
      required: ['agent_object_id'],
      title: 'DataDogIntegrationInfo',
      type: 'object',
      additionalProperties: false,
    },
    DeploymentTarget: {
      properties: {
        serviceConnection: {
          title: 'Serviceconnection',
          type: 'string',
        },
        serviceConnectionId: {
          title: 'Serviceconnectionid',
          type: 'string',
        },
        clusterName: {
          title: 'Clustername',
          type: 'string',
        },
        clusterRg: {
          title: 'Clusterrg',
          type: 'string',
        },
      },
      required: [
        'serviceConnection',
        'serviceConnectionId',
        'clusterName',
        'clusterRg',
      ],
      title: 'DeploymentTarget',
      type: 'object',
      additionalProperties: false,
    },
    DevOpsOutput: {
      properties: {
        projectUrl: {
          title: 'Projecturl',
          type: 'string',
        },
        targetEnvironments: {
          additionalProperties: {
            $ref: '#/$defs/DeploymentTarget',
          },
          title: 'Targetenvironments',
          type: 'object',
        },
        variableGroups: {
          items: {
            type: 'string',
          },
          title: 'Variablegroups',
          type: 'array',
        },
      },
      required: ['projectUrl', 'targetEnvironments', 'variableGroups'],
      title: 'DevOpsOutput',
      type: 'object',
      additionalProperties: false,
    },
    Domain: {
      enum: ['data', 'app'],
      title: 'Domain',
      type: 'string',
    },
    DomainReport: {
      properties: {
        nodeId: {
          description: 'The ID of the node in the cluster.',
          title: 'Node ID',
          type: 'string',
        },
        cert: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Name of the certificate',
        },
        kvId: {
          anyOf: [
            {
              description: 'Canonical reference to resource.',
              title: 'Azure resource id.',
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Keyvault Resource ID',
        },
        role: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        url: {
          description: 'The URL of the domain.',
          title: 'URL',
          type: 'string',
        },
        flavour: {
          $ref: '#/$defs/Domain',
          description: 'The flavour of the domain.',
          title: 'Flavour',
        },
      },
      required: ['nodeId', 'role', 'url', 'flavour'],
      title: 'DomainReport',
      type: 'object',
      additionalProperties: false,
    },
    ExposedServiceEntry: {
      properties: {
        name: {
          description: 'Name of the service in Kubernetes.',
          title: 'Kubernetes service name',
          type: 'string',
        },
        urls: {
          description: 'URLs for the service.',
          items: {
            $ref: '#/$defs/ExposedServiceUrl',
          },
          title: 'URLs for the service.',
          type: 'array',
        },
      },
      required: ['name'],
      title: 'ExposedServiceEntry',
      type: 'object',
      additionalProperties: false,
    },
    ExposedServiceUrl: {
      properties: {
        url: {
          description: 'URL for the service.',
          title: 'URL',
          type: 'string',
        },
        allowedGroups: {
          default: [],
          description: 'Groups that have access to this URL',
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Allowed groups',
          type: 'array',
          'vsc:action': 'array',
          'vsc:picker': 'aad-group',
        },
        domain: {
          $ref: '#/$defs/Domain',
          default: ['app'],
          description: 'The domain this URL is available at.',
          title: 'URL domain',
        },
      },
      required: ['url'],
      title: 'ExposedServiceUrl',
      type: 'object',
      additionalProperties: false,
    },
    GatewayReport: {
      properties: {
        kind: {
          description: 'The kind of the gateway.',
          title: 'Kind',
          type: 'string',
        },
        domains: {
          description: 'List of domains exposed by the gateway.',
          items: {
            $ref: '#/$defs/DomainReport',
          },
          title: 'Domains',
          type: 'array',
        },
        customDomains: {
          description: 'List of additional custom domains',
          items: {
            $ref: '#/$defs/CustomDomainReport',
          },
          title: 'Customdomains',
          type: 'array',
        },
      },
      required: ['kind', 'domains'],
      title: 'GatewayReport',
      type: 'object',
      additionalProperties: false,
    },
    GitHubEnterpriseReport: {
      properties: {
        registered: {
          $ref: '#/$defs/GitHubMembership',
        },
        id_name_map: {
          items: {
            maxItems: 2,
            minItems: 2,
            prefixItems: [
              {
                type: 'string',
              },
              {
                type: 'string',
              },
            ],
            type: 'array',
          },
          title: 'Id Name Map',
          type: 'array',
        },
      },
      required: ['registered', 'id_name_map'],
      title: 'GitHubEnterpriseReport',
      type: 'object',
      additionalProperties: false,
    },
    GitHubMembership: {
      properties: {
        users: {
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Users',
          type: 'array',
        },
        groups: {
          items: {
            format: 'uuid',
            type: 'string',
          },
          title: 'Groups',
          type: 'array',
        },
      },
      required: ['users', 'groups'],
      title: 'GitHubMembership',
      type: 'object',
      additionalProperties: false,
    },
    GitHubTeamInfo: {
      properties: {
        ghId: {
          description: 'The GitHub ID of the team.',
          title: 'Ghid',
          type: 'string',
        },
        slug: {
          title: 'Slug',
          type: 'string',
        },
        mappedObjectId: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'The object ID of the group that represents the team in AAD.',
          title: 'Mappedobjectid',
        },
        ghEmuId: {
          anyOf: [
            {
              type: 'integer',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The GitHub Enterprise Managed Users ID of the team.',
          title: 'Ghemuid',
        },
        role: {
          $ref: '#/$defs/TeamRole',
          default: 'developers',
          description: 'The role of the team.',
        },
      },
      required: ['ghId', 'slug'],
      title: 'GitHubTeamInfo',
      type: 'object',
      additionalProperties: false,
    },
    GroupInfo: {
      properties: {
        objectId: {
          title: 'Objectid',
          type: 'string',
        },
        displayName: {
          title: 'Displayname',
          type: 'string',
        },
      },
      required: ['objectId', 'displayName'],
      title: 'GroupInfo',
      type: 'object',
      additionalProperties: false,
    },
    IdentityInfo: {
      properties: {
        kind: {
          const: 'identity',
          default: 'identity',
          enum: ['identity'],
          title: 'Kind',
          type: 'string',
        },
        clientId: {
          title: 'Identity client ID.',
          type: 'string',
        },
        objectId: {
          title: 'Identity object ID.',
          type: 'string',
        },
        displayName: {
          title: 'Identity display name.',
          type: 'string',
        },
      },
      required: ['kind', 'clientId', 'objectId', 'displayName'],
      title: 'IdentityInfo',
      type: 'object',
      additionalProperties: false,
    },
    InfraCellRef: {
      description:
        'A reference to a infrstructure cell associated with a logical stage.',
      properties: {
        name: {
          description: 'The globally unique name of the cell.',
          title: 'name',
          type: 'string',
        },
        stage: {
          $ref: '#/$defs/LogicalStage',
          description:
            'The logical stage describes the order of a cell within a deployment squence.',
          title: 'logical stage',
        },
      },
      required: ['name', 'stage'],
      title: 'InfraCellRef',
      type: 'object',
      additionalProperties: false,
    },
    InfrastructureCellReport: {
      properties: {
        cellId: {
          description: 'The ID of the node in the instance.',
          title: 'Cell ID',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage of the cell.',
          title: 'Logical stage',
        },
        cluster: {
          $ref: '#/$defs/K8sClusterResource',
          description: 'The cluster information.',
        },
        network: {
          $ref: '#/$defs/NetworkReport',
          description: 'The network information.',
        },
      },
      required: ['cellId', 'kind', 'logicalStage', 'cluster', 'network'],
      title: 'InfrastructureCellReport',
      type: 'object',
      additionalProperties: false,
    },
    InstanceCellKind: {
      enum: ['platform', 'product'],
      title: 'InstanceCellKind',
      type: 'string',
    },
    InstanceLayout: {
      properties: {
        platform: {
          $ref: '#/$defs/InfraCellRef',
        },
        products: {
          items: {
            $ref: '#/$defs/InfraCellRef',
          },
          title: 'Products',
          type: 'array',
        },
      },
      required: ['platform', 'products'],
      title: 'InstanceLayout',
      type: 'object',
      additionalProperties: false,
    },
    K8sClusterResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        location: {
          description: 'Azure Region where the cluster is provisioned.',
          title: 'Location',
          type: 'string',
        },
        kubernetesVersion: {
          description: 'Kubernetes version installed in the cluster.',
          title: 'Kubernetesversion',
          type: 'string',
        },
        kubernetesOrchestratorVersion: {
          description: 'Kubernetes orchestrator version.',
          title: 'Kubernetesorchestratorversion',
          type: 'string',
        },
        fqdn: {
          description:
            'The fully qualified domain name of the Kubernetes cluster.',
          title: 'Fqdn',
          type: 'string',
        },
        oidcIssuerUrl: {
          description: 'The OpenID Connect issuer URL for the cluster.',
          title: 'Oidcissuerurl',
          type: 'string',
        },
        kubeletObjectId: {
          description:
            'The object ID of the kubelet identity associated with the cluster.',
          title: 'Kubeletobjectid',
          type: 'string',
        },
      },
      required: [
        'resourceId',
        'location',
        'kubernetesVersion',
        'kubernetesOrchestratorVersion',
        'fqdn',
        'oidcIssuerUrl',
        'kubeletObjectId',
      ],
      title: 'K8sClusterResource',
      type: 'object',
      additionalProperties: false,
    },
    K8sReleaseInfo: {
      properties: {
        namespace: {
          title: 'Namespace',
          type: 'string',
        },
      },
      required: ['namespace'],
      title: 'K8sReleaseInfo',
      type: 'object',
      additionalProperties: false,
    },
    KeyVaultResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'key-vault',
          default: 'key-vault',
          enum: ['key-vault'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the key vault is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        uri: {
          description: 'The URI of the key vault.',
          title: 'URI',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'uri', 'resourceId'],
      title: 'KeyVaultResource',
      type: 'object',
      additionalProperties: false,
    },
    LogicalStage: {
      enum: ['development', 'staging', 'production'],
      title: 'LogicalStage',
      type: 'string',
    },
    ManifestMetadata: {
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
        role: {
          $ref: '#/$defs/ManifestRole',
          default: 'product',
          description: 'Role of the entity.',
          title: 'Role',
        },
      },
      required: ['name', 'namespace'],
      title: 'ManifestMetadata',
      type: 'object',
      additionalProperties: false,
    },
    ManifestRole: {
      enum: ['platform', 'product'],
      title: 'ManifestRole',
      type: 'string',
    },
    Metadata: {
      description: 'Metadata fields describing the resource',
      properties: {
        name: {
          description:
            'Name of the entity. May only contain lowercase letters and hyphens',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        namespace: {
          description: 'Namespace the entity is defined in.',
          maxLength: 62,
          minLength: 1,
          pattern: '^[a-z]+(?:[-_]?[a-z0-9]+)+$',
          title: 'RFC1035 name',
          type: 'string',
        },
        title: {
          default: '',
          description: 'A display name of the entity.',
          title: 'Title',
          type: 'string',
        },
        description: {
          default: '',
          description: 'A human readable description of the entity.',
          title: 'Description',
          type: 'string',
        },
        annotations: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Annotations object regarding the resource.',
          title: 'Annotations',
          type: 'object',
        },
        labels: {
          additionalProperties: {
            type: 'string',
          },
          description: 'Labels object regarding the resource.',
          title: 'Labels',
          type: 'object',
        },
      },
      required: ['name', 'namespace'],
      title: 'Metadata',
      type: 'object',
      additionalProperties: false,
    },
    MysqlDatabaseResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'mysql-db',
          default: 'mysql-db',
          enum: ['mysql-db'],
          title: 'Kind',
          type: 'string',
        },
        charset: {
          description: 'The character set to use for the database.',
          title: 'Character set',
          type: 'string',
        },
        collation: {
          description: 'The collation to use for the database.',
          title: 'Collation',
          type: 'string',
        },
      },
      required: ['kind', 'resourceId'],
      title: 'MysqlDatabaseResource',
      type: 'object',
      additionalProperties: false,
    },
    MysqlServerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'mysql',
          default: 'mysql',
          enum: ['mysql'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the Mysql server is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        fqdn: {
          description: 'Fully qualified domain name of the server.',
          title: 'FQDN',
          type: 'string',
        },
        skuName: {
          description: 'The SKU of the Mysql server.',
          title: 'SKU name',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'fqdn', 'skuName', 'resourceId'],
      title: 'MysqlServerResource',
      type: 'object',
      additionalProperties: false,
    },
    NetworkReport: {
      properties: {
        pip: {
          $ref: '#/$defs/PublicIpResource',
          description: 'Public IP address of the network.',
        },
        vnet: {
          $ref: '#/$defs/BaseResourceInfo',
          description: 'The virtual network information.',
        },
      },
      required: ['pip', 'vnet'],
      title: 'NetworkReport',
      type: 'object',
      additionalProperties: false,
    },
    PlatformGroups: {
      properties: {
        contributors: {
          $ref: '#/$defs/GroupInfo',
        },
        readers: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: ['contributors', 'readers'],
      title: 'PlatformGroups',
      type: 'object',
      additionalProperties: false,
    },
    PlatformReport: {
      properties: {
        groups: {
          $ref: '#/$defs/PlatformGroups',
        },
        instances: {
          items: {
            $ref: '#/$defs/InstanceLayout',
          },
          title: 'Instances',
          type: 'array',
        },
        datadog: {
          anyOf: [
            {
              $ref: '#/$defs/DataDogIntegrationInfo',
            },
            {
              type: 'null',
            },
          ],
          default: null,
        },
      },
      required: ['groups', 'instances'],
      title: 'PlatformReport',
      type: 'object',
      additionalProperties: false,
    },
    PolicyReport: {
      properties: {
        permissions: {
          description:
            'List of role assignments defined in the synthetized stack.',
          items: {
            $ref: '#/$defs/RoleAssignmentInfo',
          },
          title: 'Role Assignments',
          type: 'array',
        },
      },
      required: ['permissions'],
      title: 'PolicyReport',
      type: 'object',
      additionalProperties: false,
    },
    PostgresDatabaseResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'postgres-db',
          default: 'postgres-db',
          enum: ['postgres-db'],
          title: 'Kind',
          type: 'string',
        },
        charset: {
          description: 'The character set to use for the database.',
          title: 'Character set',
          type: 'string',
        },
        collation: {
          description: 'The collation to use for the database.',
          title: 'Collation',
          type: 'string',
        },
      },
      required: ['kind', 'resourceId'],
      title: 'PostgresDatabaseResource',
      type: 'object',
      additionalProperties: false,
    },
    PostgresServerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'postgres',
          default: 'postgres',
          enum: ['postgres'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the Postgres server is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        fqdn: {
          description: 'Fully qualified domain name of the server.',
          title: 'FQDN',
          type: 'string',
        },
        skuName: {
          description: 'The SKU of the Postgres server.',
          title: 'SKU name',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'fqdn', 'skuName', 'resourceId'],
      title: 'PostgresServerResource',
      type: 'object',
      additionalProperties: false,
    },
    ProductReport: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'ProductCatalogEntry',
          default: 'ProductCatalogEntry',
          enum: ['ProductCatalogEntry'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/Metadata',
          description: 'Basic metadata about the product.',
          title: 'Product metadata.',
        },
        identity: {
          $ref: '#/$defs/IdentityInfo',
          description:
            'Default identity to use for accessing product resources.',
          title: 'Product identity.',
        },
        k8sRelease: {
          anyOf: [
            {
              $ref: '#/$defs/K8sReleaseInfo',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description:
            'Basic information about K8s environment for the product.',
          title: 'K8s release info.',
        },
        resources: {
          description: 'Azure resources provisioned for the product.',
          items: {
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
            ],
          },
          title: 'Product resources.',
          type: 'array',
        },
        routing: {
          $ref: '#/$defs/RoutingReport',
          description: 'List of exposed services.',
          title: 'Product routing.',
        },
      },
      required: ['kind', 'metadata', 'identity', 'k8sRelease', 'resources'],
      title: 'ProductReport',
      type: 'object',
      additionalProperties: false,
    },
    PublicIpResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'public-ip',
          default: 'public-ip',
          enum: ['public-ip'],
          title: 'Kind',
          type: 'string',
        },
        publicIp: {
          description: 'Public IP address of the network.',
          title: 'Publicip',
          type: 'string',
        },
      },
      required: ['resourceId', 'publicIp'],
      title: 'PublicIpResource',
      type: 'object',
      additionalProperties: false,
    },
    ResourceGroupResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        location: {
          description: 'Azure region where the resource group is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        kind: {
          const: 'resource-group',
          default: 'resource-group',
          enum: ['resource-group'],
          title: 'Kind',
          type: 'string',
        },
      },
      required: ['resourceId', 'location'],
      title: 'ResourceGroupResource',
      type: 'object',
      additionalProperties: false,
    },
    ResourcesReport: {
      properties: {
        resources: {
          description: 'List of azure resources provisioned in the stack.',
          items: {
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
            ],
          },
          title: 'Azure resources',
          type: 'array',
        },
        resourceLocations: {
          additionalProperties: {
            type: 'string',
          },
          description:
            'Mapping of a node in the construct tree to the resource id.',
          title: 'Resource locations',
          type: 'object',
        },
      },
      required: ['resources', 'resourceLocations'],
      title: 'ResourcesReport',
      type: 'object',
      additionalProperties: false,
    },
    RoleAssignmentInfo: {
      properties: {
        roleDefinitionName: {
          title: 'Roledefinitionname',
          type: 'string',
        },
        roleDefinitionId: {
          title: 'Roledefinitionid',
          type: 'string',
        },
        resourceId: {
          description:
            'Azure Resource Manager ID of the resource this role applies to.',
          title: 'Resource id.',
          type: 'string',
        },
        principalId: {
          title: 'Principalid',
          type: 'string',
        },
        principalType: {
          title: 'Principaltype',
          type: 'string',
        },
        description: {
          description:
            'A short description on why the role assignment is required.',
          title: 'Motivation',
          type: 'string',
        },
      },
      required: [
        'roleDefinitionName',
        'roleDefinitionId',
        'resourceId',
        'principalId',
        'principalType',
        'description',
      ],
      title: 'RoleAssignmentInfo',
      type: 'object',
      additionalProperties: false,
    },
    RoutingReport: {
      properties: {
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage this routing report is for.',
          title: 'Logical stage',
        },
        services: {
          items: {
            $ref: '#/$defs/ExposedServiceEntry',
          },
          title: 'Services',
          type: 'array',
        },
      },
      required: ['logicalStage', 'services'],
      title: 'RoutingReport',
      type: 'object',
      additionalProperties: false,
    },
    StorageAccountResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'storage-account',
          default: 'storage-account',
          enum: ['storage-account'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the storage account is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        hnsEnabled: {
          title: 'Hnsenabled',
          type: 'boolean',
        },
        replicationType: {
          title: 'Replicationtype',
          type: 'string',
        },
      },
      required: [
        'kind',
        'location',
        'hnsEnabled',
        'replicationType',
        'resourceId',
      ],
      title: 'StorageAccountResource',
      type: 'object',
      additionalProperties: false,
    },
    StorageContainerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'storage-container',
          default: 'storage-container',
          enum: ['storage-container'],
          title: 'Kind',
          type: 'string',
        },
        url: {
          description: 'The URL of the storage container.',
          title: 'URL',
          type: 'string',
        },
      },
      required: ['kind', 'url', 'resourceId'],
      title: 'StorageContainerResource',
      type: 'object',
      additionalProperties: false,
    },
    TeamRole: {
      enum: ['developers', 'admins'],
      title: 'TeamRole',
      type: 'string',
    },
    WorkspaceReport: {
      properties: {
        apiVersion: {
          const: 'core.argus.ai/v1alpha1',
          default: 'core.argus.ai/v1alpha1',
          enum: ['core.argus.ai/v1alpha1'],
          title: 'Apiversion',
          type: 'string',
        },
        kind: {
          const: 'WorkspaceCatalogEntry',
          default: 'WorkspaceCatalogEntry',
          enum: ['WorkspaceCatalogEntry'],
          title: 'Kind',
          type: 'string',
        },
        metadata: {
          $ref: '#/$defs/ManifestMetadata',
          description: 'Basic metadata about the workspace.',
          title: 'Workspace metadata.',
        },
        identity: {
          $ref: '#/$defs/IdentityInfo',
          description: 'Identity information for the workspace.',
          title: 'Workspace identity.',
        },
        application: {
          $ref: '#/$defs/ApplicationInfo',
          description: 'Application information for the workspace.',
          title: 'Workspace application.',
        },
        devops: {
          anyOf: [
            {
              $ref: '#/$defs/DevOpsOutput',
            },
            {
              type: 'null',
            },
          ],
          default: null,
        },
        githubTeams: {
          description: 'GitHub teams associated with the workspace.',
          items: {
            $ref: '#/$defs/GitHubTeamInfo',
          },
          title: 'GitHub teams.',
          type: 'array',
        },
        resources: {
          description: 'Azure resources provisioned for the workspace.',
          items: {
            discriminator: {
              mapping: {
                acr: '#/$defs/AcrResource',
                'cognitive-deployment': '#/$defs/CognitiveDeploymentsResource',
                'cognitive-services': '#/$defs/CognitiveAccountResource',
                'key-vault': '#/$defs/KeyVaultResource',
                mysql: '#/$defs/MysqlServerResource',
                'mysql-db': '#/$defs/MysqlDatabaseResource',
                postgres: '#/$defs/PostgresServerResource',
                'postgres-db': '#/$defs/PostgresDatabaseResource',
                'resource-group': '#/$defs/ResourceGroupResource',
                'storage-account': '#/$defs/StorageAccountResource',
                'storage-container': '#/$defs/StorageContainerResource',
              },
              propertyName: 'kind',
            },
            oneOf: [
              {
                $ref: '#/$defs/AcrResource',
              },
              {
                $ref: '#/$defs/StorageAccountResource',
              },
              {
                $ref: '#/$defs/KeyVaultResource',
              },
              {
                $ref: '#/$defs/PostgresServerResource',
              },
              {
                $ref: '#/$defs/StorageContainerResource',
              },
              {
                $ref: '#/$defs/PostgresDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlDatabaseResource',
              },
              {
                $ref: '#/$defs/MysqlServerResource',
              },
              {
                $ref: '#/$defs/ResourceGroupResource',
              },
              {
                $ref: '#/$defs/CognitiveAccountResource',
              },
              {
                $ref: '#/$defs/CognitiveDeploymentsResource',
              },
            ],
          },
          title: 'Workspace resources.',
          type: 'array',
        },
      },
      required: ['kind', 'metadata', 'identity', 'application', 'resources'],
      title: 'WorkspaceReport',
      type: 'object',
      additionalProperties: false,
    },
  },
  properties: {
    config: {
      anyOf: [
        {
          $ref: '#/$defs/GitHubEnterpriseReport',
        },
        {
          $ref: '#/$defs/InfrastructureCellReport',
        },
        {
          $ref: '#/$defs/PolicyReport',
        },
        {
          $ref: '#/$defs/PlatformReport',
        },
        {
          $ref: '#/$defs/ProductReport',
        },
        {
          $ref: '#/$defs/WorkspaceReport',
        },
        {
          $ref: '#/$defs/GatewayReport',
        },
        {
          $ref: '#/$defs/RoutingReport',
        },
        {
          $ref: '#/$defs/ResourcesReport',
        },
      ],
      description: 'Any report type',
      title: 'AnyReport',
    },
  },
  required: ['config'],
  title: 'AnyReport',
  type: 'object',
});

const GitHubEnterpriseReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/GitHubEnterpriseReport',
});
export type GitHubEnterpriseReport = FromSchema<
  typeof GitHubEnterpriseReportSchema
>;

const InfrastructureCellReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/InfrastructureCellReport',
});
export type InfrastructureCellReport = FromSchema<
  typeof InfrastructureCellReportSchema
>;

const PolicyReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/PolicyReport',
});
export type PolicyReport = FromSchema<typeof PolicyReportSchema>;

const PlatformReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/PlatformReport',
});
export type PlatformReport = FromSchema<typeof PlatformReportSchema>;

const ProductReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ProductReport',
});
export type ProductReport = FromSchema<typeof ProductReportSchema>;

const WorkspaceReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/WorkspaceReport',
});
export type WorkspaceReport = FromSchema<typeof WorkspaceReportSchema>;

const GatewayReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/GatewayReport',
});
export type GatewayReport = FromSchema<typeof GatewayReportSchema>;

const RoutingReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/RoutingReport',
});
export type RoutingReport = FromSchema<typeof RoutingReportSchema>;

const ResourcesReportSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ResourcesReport',
});
export type ResourcesReport = FromSchema<typeof ResourcesReportSchema>;

export type AnyReport =
  | GitHubEnterpriseReport
  | InfrastructureCellReport
  | PolicyReport
  | PlatformReport
  | ProductReport
  | WorkspaceReport
  | GatewayReport
  | RoutingReport
  | ResourcesReport;

const AnyInstanceReportSchema = asConst({
  $defs: {
    AcrResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'acr',
          default: 'acr',
          enum: ['acr'],
          title: 'Kind',
          type: 'string',
        },
        location: {
          description: 'Azure region where the ACR is deployed.',
          title: 'Azure region',
          type: 'string',
        },
        sku: {
          description: 'The SKU of the ACR.',
          title: 'SKU',
          type: 'string',
        },
      },
      required: ['kind', 'location', 'sku', 'resourceId'],
      title: 'AcrResource',
      type: 'object',
      additionalProperties: false,
    },
    CellClusterReport: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        caCertificate: {
          description:
            'Kubernetes API server certificate authority (base64 encoded)',
          title: 'CA certificate',
          type: 'string',
        },
        oidcIssuerUrl: {
          title: 'OIDC issuer URL',
          type: 'string',
        },
        apiServerUrl: {
          description: 'Base URL of the Kubernetes control plane',
          title: 'API server Url',
          type: 'string',
        },
        kubernetesVersion: {
          description: 'Version of k8s running on the cluster',
          title: 'Kubernetes version',
          type: 'string',
        },
      },
      required: [
        'resourceId',
        'caCertificate',
        'oidcIssuerUrl',
        'apiServerUrl',
        'kubernetesVersion',
      ],
      title: 'CellClusterReport',
      type: 'object',
      additionalProperties: false,
    },
    DeployedInstanceLayout: {
      properties: {
        platformCell: {
          $ref: '#/$defs/InstanceCellRef',
          title: 'Platform cell',
        },
        productCells: {
          additionalProperties: {
            $ref: '#/$defs/InstanceCellRef',
          },
          title: 'Product cells',
          type: 'object',
        },
      },
      required: ['platformCell', 'productCells'],
      title: 'DeployedInstanceLayout',
      type: 'object',
      additionalProperties: false,
    },
    GroupInfo: {
      properties: {
        objectId: {
          title: 'Objectid',
          type: 'string',
        },
        displayName: {
          title: 'Displayname',
          type: 'string',
        },
      },
      required: ['objectId', 'displayName'],
      title: 'GroupInfo',
      type: 'object',
      additionalProperties: false,
    },
    InstanceCellKind: {
      enum: ['platform', 'product'],
      title: 'InstanceCellKind',
      type: 'string',
    },
    InstanceCellRef: {
      properties: {
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The kind of the cell.',
          title: 'Cell kind',
        },
        cellId: {
          description: 'The unique identifier of the cell.',
          title: 'Cell ID',
          type: 'string',
        },
      },
      required: ['kind', 'cellId'],
      title: 'InstanceCellRef',
      type: 'object',
      additionalProperties: false,
    },
    InstanceCellReport: {
      properties: {
        cellId: {
          description: 'The ID of the node in the instance.',
          title: 'Cell ID',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellKind',
          description: 'The role of the cell within the platform instance.',
          title: 'Role',
        },
        logicalStage: {
          $ref: '#/$defs/LogicalStage',
          description: 'The logical stage of the cell.',
          title: 'Logical stage',
        },
        subscriptions: {
          description: 'Subscriptions the cell is deployed to.',
          items: {
            $ref: '#/$defs/InstanceCellSubscription',
          },
          title: 'Subscriptions',
          type: 'array',
        },
        clusters: {
          description: 'Clusters deployed to insatnce cell.',
          items: {
            $ref: '#/$defs/CellClusterReport',
          },
          title: 'Cluster',
          type: 'array',
        },
      },
      required: ['cellId', 'kind', 'logicalStage', 'subscriptions', 'clusters'],
      title: 'InstanceCellReport',
      type: 'object',
      additionalProperties: false,
    },
    InstanceCellSubscription: {
      properties: {
        id: {
          title: 'Id',
          type: 'string',
        },
        name: {
          title: 'Name',
          type: 'string',
        },
        kind: {
          $ref: '#/$defs/InstanceCellSubscriptionKind',
          description: 'The kind of the subscription.',
          title: 'Subscription kind',
        },
      },
      required: ['id', 'name', 'kind'],
      title: 'InstanceCellSubscription',
      type: 'object',
      additionalProperties: false,
    },
    InstanceCellSubscriptionKind: {
      enum: ['dsp', 'appstore', 'argus'],
      title: 'InstanceCellSubscriptionKind',
      type: 'string',
    },
    InstanceGroups: {
      description:
        'Entry ID groups that are used across cells within a platform instance.',
      properties: {
        workspaces: {
          $ref: '#/$defs/GroupInfo',
        },
        workspacePrincipals: {
          $ref: '#/$defs/GroupInfo',
          title: 'Workspace principals',
        },
        products: {
          $ref: '#/$defs/GroupInfo',
        },
        productPrincipals: {
          $ref: '#/$defs/GroupInfo',
          title: 'Product principals',
        },
        kubelets: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: [
        'workspaces',
        'workspacePrincipals',
        'products',
        'productPrincipals',
        'kubelets',
      ],
      title: 'InstanceGroups',
      type: 'object',
      additionalProperties: false,
    },
    InstanceRegistryReport: {
      properties: {
        repository: {
          description: 'The name of the git repository.',
          title: 'Repository',
          type: 'string',
        },
        catalogRepository: {
          anyOf: [
            {
              type: 'string',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          description: 'The name of the git repository for the catalog.',
          title: 'Catalog repository',
        },
        agentsGroupObjectId: {
          description: 'The object ID of the service principal group.',
          title: 'Agents group object ID',
          type: 'string',
        },
        agents: {
          description:
            'The service principals acting as agents to deploy into instance cells.',
          items: {
            $ref: '#/$defs/RegistryAgentReport',
          },
          title: 'Agents',
          type: 'array',
        },
      },
      required: ['repository', 'agentsGroupObjectId', 'agents'],
      title: 'InstanceRegistryReport',
      type: 'object',
      additionalProperties: false,
    },
    InstanceResources: {
      properties: {
        ociRegistry: {
          $ref: '#/$defs/AcrResource',
          title: 'Container registry',
        },
        portal: {
          anyOf: [
            {
              $ref: '#/$defs/PortalResources',
            },
            {
              type: 'null',
            },
          ],
          default: null,
          title: 'Portal resources',
        },
        legacy: {
          $ref: '#/$defs/LegacyResources',
          title: 'Legacy resources',
        },
      },
      required: ['ociRegistry', 'legacy'],
      title: 'InstanceResources',
      type: 'object',
      additionalProperties: false,
    },
    LegacyResources: {
      properties: {
        shared_groups: {
          $ref: '#/$defs/SharedGroupsReport',
        },
      },
      required: ['shared_groups'],
      title: 'LegacyResources',
      type: 'object',
      additionalProperties: false,
    },
    LogicalStage: {
      enum: ['development', 'staging', 'production'],
      title: 'LogicalStage',
      type: 'string',
    },
    PortalResources: {
      properties: {
        techdocsContainer: {
          $ref: '#/$defs/StorageContainerResource',
          title: 'TechDocs container',
        },
        templatesContainer: {
          anyOf: [
            {
              $ref: '#/$defs/StorageContainerResource',
            },
            {
              type: 'null',
            },
          ],
          title: 'Templates container',
        },
      },
      required: ['techdocsContainer', 'templatesContainer'],
      title: 'PortalResources',
      type: 'object',
      additionalProperties: false,
    },
    RegistryAgentReport: {
      properties: {
        targetCell: {
          $ref: '#/$defs/InstanceCellRef',
          description: 'The cell the agent can deploy to.',
          title: 'Target cell',
        },
        objectId: {
          description: 'The object ID of the service principal.',
          title: 'Object ID',
          type: 'string',
        },
        repositories: {
          description: 'The repositories the service principal has access to.',
          items: {
            type: 'string',
          },
          title: 'Repositories',
          type: 'array',
        },
        environmentName: {
          description:
            'The name of the environment the service principal is used in.',
          title: 'Environment name',
          type: 'string',
        },
      },
      required: ['targetCell', 'objectId', 'environmentName'],
      title: 'RegistryAgentReport',
      type: 'object',
      additionalProperties: false,
    },
    SharedGroupsReport: {
      properties: {
        workspace_owner: {
          $ref: '#/$defs/GroupInfo',
        },
        product_owner: {
          $ref: '#/$defs/GroupInfo',
        },
        workspace_identities: {
          $ref: '#/$defs/GroupInfo',
        },
        platform_contributors: {
          $ref: '#/$defs/GroupInfo',
        },
        basf_global_members: {
          $ref: '#/$defs/GroupInfo',
        },
      },
      required: [
        'workspace_owner',
        'product_owner',
        'workspace_identities',
        'platform_contributors',
        'basf_global_members',
      ],
      title: 'SharedGroupsReport',
      type: 'object',
      additionalProperties: false,
    },
    StorageContainerResource: {
      properties: {
        resourceId: {
          description: 'Canonical reference to resource.',
          title: 'Azure resource id.',
          type: 'string',
        },
        kind: {
          const: 'storage-container',
          default: 'storage-container',
          enum: ['storage-container'],
          title: 'Kind',
          type: 'string',
        },
        url: {
          description: 'The URL of the storage container.',
          title: 'URL',
          type: 'string',
        },
      },
      required: ['kind', 'url', 'resourceId'],
      title: 'StorageContainerResource',
      type: 'object',
      additionalProperties: false,
    },
  },
  properties: {
    config: {
      anyOf: [
        {
          $ref: '#/$defs/InstanceRegistryReport',
        },
        {
          $ref: '#/$defs/RegistryAgentReport',
        },
        {
          $ref: '#/$defs/CellClusterReport',
        },
        {
          $ref: '#/$defs/InstanceCellReport',
        },
        {
          $ref: '#/$defs/DeployedInstanceLayout',
        },
        {
          $ref: '#/$defs/InstanceResources',
        },
        {
          $ref: '#/$defs/InstanceGroups',
        },
      ],
      description: 'Any instance report type',
      title: 'AnyInstanceReport',
    },
  },
  required: ['config'],
  title: 'AnyInstanceReport',
  type: 'object',
});

const InstanceRegistryReportSchema = asConst({
  $defs: AnyInstanceReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/InstanceRegistryReport',
});
export type InstanceRegistryReport = FromSchema<
  typeof InstanceRegistryReportSchema
>;

const RegistryAgentReportSchema = asConst({
  $defs: AnyInstanceReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/RegistryAgentReport',
});
export type RegistryAgentReport = FromSchema<typeof RegistryAgentReportSchema>;

const CellClusterReportSchema = asConst({
  $defs: AnyInstanceReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/CellClusterReport',
});
export type CellClusterReport = FromSchema<typeof CellClusterReportSchema>;

const InstanceCellReportSchema = asConst({
  $defs: AnyInstanceReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/InstanceCellReport',
});
export type InstanceCellReport = FromSchema<typeof InstanceCellReportSchema>;

const DeployedInstanceLayoutSchema = asConst({
  $defs: AnyInstanceReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/DeployedInstanceLayout',
});
export type DeployedInstanceLayout = FromSchema<
  typeof DeployedInstanceLayoutSchema
>;

const InstanceResourcesSchema = asConst({
  $defs: AnyInstanceReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/InstanceResources',
});
export type InstanceResources = FromSchema<typeof InstanceResourcesSchema>;

const InstanceGroupsSchema = asConst({
  $defs: AnyInstanceReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/InstanceGroups',
});
export type InstanceGroups = FromSchema<typeof InstanceGroupsSchema>;

const AcrResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/AcrResource',
});
export type AcrResource = FromSchema<typeof AcrResourceSchema>;

const StorageAccountResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/StorageAccountResource',
});
export type StorageAccountResource = FromSchema<
  typeof StorageAccountResourceSchema
>;

const KeyVaultResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/KeyVaultResource',
});
export type KeyVaultResource = FromSchema<typeof KeyVaultResourceSchema>;

const PostgresServerResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/PostgresServerResource',
});
export type PostgresServerResource = FromSchema<
  typeof PostgresServerResourceSchema
>;

const StorageContainerResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/StorageContainerResource',
});
export type StorageContainerResource = FromSchema<
  typeof StorageContainerResourceSchema
>;

const PostgresDatabaseResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/PostgresDatabaseResource',
});
export type PostgresDatabaseResource = FromSchema<
  typeof PostgresDatabaseResourceSchema
>;

const MysqlDatabaseResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/MysqlDatabaseResource',
});
export type MysqlDatabaseResource = FromSchema<
  typeof MysqlDatabaseResourceSchema
>;

const MysqlServerResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/MysqlServerResource',
});
export type MysqlServerResource = FromSchema<typeof MysqlServerResourceSchema>;

const ResourceGroupResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ResourceGroupResource',
});
export type ResourceGroupResource = FromSchema<
  typeof ResourceGroupResourceSchema
>;

const CognitiveAccountResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/CognitiveAccountResource',
});
export type CognitiveAccountResource = FromSchema<
  typeof CognitiveAccountResourceSchema
>;

const CognitiveDeploymentsResourceSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/CognitiveDeploymentsResource',
});
export type CognitiveDeploymentsResource = FromSchema<
  typeof CognitiveDeploymentsResourceSchema
>;

const ExposedServiceUrlSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ExposedServiceUrl',
});
export type ExposedServiceUrl = FromSchema<typeof ExposedServiceUrlSchema>;

const ExposedServiceEntrySchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ExposedServiceEntry',
});
export type ExposedServiceEntry = FromSchema<typeof ExposedServiceEntrySchema>;

const IdentityInfoSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/IdentityInfo',
});
export type IdentityInfo = FromSchema<typeof IdentityInfoSchema>;

const K8sReleaseInfoSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/K8sReleaseInfo',
});
export type K8sReleaseInfo = FromSchema<typeof K8sReleaseInfoSchema>;

const ApplicationInfoSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/ApplicationInfo',
});
export type ApplicationInfo = FromSchema<typeof ApplicationInfoSchema>;

const DevOpsOutputSchema = asConst({
  $defs: AnyReportSchema.$defs,
  type: 'object',
  $ref: '#/$defs/DevOpsOutput',
});
export type DevOpsOutput = FromSchema<typeof DevOpsOutputSchema>;

export type AnyResource =
  | AcrResource
  | StorageAccountResource
  | KeyVaultResource
  | PostgresServerResource
  | StorageContainerResource
  | PostgresDatabaseResource
  | MysqlDatabaseResource
  | MysqlServerResource
  | ResourceGroupResource
  | CognitiveAccountResource
  | CognitiveDeploymentsResource;
