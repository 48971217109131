import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  controls: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  editorControls: {
    display: 'flex',
    columnGap: 25,
    justifyContent: 'end',
  },
  configuredControls: {
    display: 'flex',
    columnGap: 25,
    justifyContent: 'start',
  },
  showContent: {
    flexGrow: 1,
  },
  hideContent: {
    display: 'none',
  },
  messages: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  editorEditing: {
    border: `1px solid #21a0d2`,
    boxSizing: 'border-box',
  },
});
