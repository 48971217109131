import { createPermission } from '@backstage/plugin-permission-common';
import { RESOURCE_TYPE_CATALOG_ENTITY } from '@backstage/plugin-catalog-common/alpha';

/**
 * @public
 */
export const msgraphGroupsReadAllPermission = createPermission({
  name: 'msgraph.groups.read',
  attributes: { action: 'read' },
});

/**
 * @public
 */
export const catalogCostReadPermission = createPermission({
  name: 'catalog.cost.read',
  attributes: { action: 'read' },
});

/**
 * @public
 */
export const provisioningManageAllPermission = createPermission({
  name: 'provisioning.manage.all',
  attributes: { action: 'read' },
});

/**
 * @public
 */
export const provisioningManageEntityPermission = createPermission({
  name: 'provisioning.manage.entity',
  attributes: { action: 'update' },
  resourceType: RESOURCE_TYPE_CATALOG_ENTITY,
});

/**
 * @public
 */
export const endUserPortalPermission = createPermission({
  name: 'enduserportal.read',
  attributes: { action: 'read' },
});

/**
 * @public
 */
export const catalogApisReadPermission = createPermission({
  name: 'catalog.apis.all',
  attributes: { action: 'read' },
});

/**
 * @public
 */
export const argusPermissions = [
  msgraphGroupsReadAllPermission,
  catalogCostReadPermission,
  provisioningManageAllPermission,
  provisioningManageEntityPermission,
  endUserPortalPermission,
  catalogApisReadPermission,
];
