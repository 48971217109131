import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef, rootCatalogProvisioningRouteRef } from './routes';

export const provisioningPlugin = createPlugin({
  id: 'provisioning',
  routes: {
    root: rootRouteRef,
    entityContent: rootCatalogProvisioningRouteRef,
  },
});

export const ProvisioningPage = provisioningPlugin.provide(
  createRoutableExtension({
    name: 'ProvisioningPage',
    component: () =>
      import('./components/ProvisioningPage').then(m => m.ProvisioningPage),
    mountPoint: rootRouteRef,
  }),
);

export const EntityProvisioningContent: () => JSX.Element =
  provisioningPlugin.provide(
    createRoutableExtension({
      name: 'ProvisioningContent',
      component: () => import('./Router').then(m => m.Router),
      mountPoint: rootCatalogProvisioningRouteRef,
    }),
  );
