import {
  ProvisioningRegistryApi,
  ProvisioningCatalogApi,
} from '@provisioning/common';
import { createApiRef } from '@backstage/core-plugin-api';

/**
 * The API reference for the {@link @provisioning/common#ProvisioningRegistryApi}.
 * @public
 */
export const provisioningRegistryApiRef = createApiRef<ProvisioningRegistryApi>(
  {
    id: 'plugin.provisioning.registry',
  },
);

/**
 * The API reference for the {@link @provisioning/common#ProvisioningCatalogApi}.
 * @public
 */
export const provisioningCatalogApiRef = createApiRef<ProvisioningCatalogApi>({
  id: 'plugin.provisioning.catalog',
});
