import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const metricsPlugin = createPlugin({
  id: 'cost-overview',
  routes: {
    root: rootRouteRef,
  },
});

export const CostOverviewPage = metricsPlugin.provide(
  createRoutableExtension({
    name: 'CostOverviewPage',
    component: () =>
      import('./components/CostOverview').then(m => m.CostOverview),
    mountPoint: rootRouteRef,
  }),
);
