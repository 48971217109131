import { alertApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import { KEY_DARK_THEME, useTheme } from '@internal/plugin-argus-react';
import {
  BeforeMount,
  DiffOnMount,
  Monaco,
  MonacoDiffEditor,
  OnMount,
  useMonaco,
} from '@monaco-editor/react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SpeedDial from '@mui/material/SpeedDial';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTheme as useMuiTheme } from '@mui/material/styles';
import {
  ManifestHandler,
  RegistryManifest,
  registrySchema,
} from '@provisioning/common';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash';
import { MarkerSeverity, editor as me } from 'monaco-editor';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from 'react';
import * as yaml from 'yaml';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Fab from '@mui/material/Fab';

export enum State {
  view = 'config',
  edit = 'editable',
  review = 'diff',
}

type EditorHanlderProps = {
  manifest: RegistryManifest;
  update: ReturnType<typeof useMutation<void, Error, RegistryManifest>>;
  queue: ReturnType<typeof useMutation<{ status: string }, Error>>;
  openDialog: () => void;
};

export function useHandlers({
  manifest,
  queue,
  update,
  openDialog,
}: EditorHanlderProps) {
  const monaco = useMonaco();
  const errorApi = useApi(errorApiRef);
  const alertApi = useApi(alertApiRef);
  const [editorActions, setEditorActions] = useState<ReactNode>(undefined);
  const [view, setView] = useState(State.view);

  const {
    editorRef,
    diffEditorRef,
    formatContent,
    parseContent,
    handleOnMount,
    handleBeforeMount,
    handleDiffEditorOnMount,
  } = useEditors();

  const handleEdit = useCallback(() => {
    switch (view) {
      case State.view:
        setView(State.edit);
        break;
      case State.review:
        if (editorRef.current && diffEditorRef.current) {
          // Write the updated diff editor content back to the editor.
          // THis contains changes made during the review process, i.e.
          // automatic changes made by the manifest handler:
          // - Add missing fields with default values
          // - Add missing capabilities for all modules
          editorRef.current.setValue(
            diffEditorRef.current.getModifiedEditor().getValue(),
          );
        }
        setView(State.edit);
        break;
      default:
        break;
    }
    setView(State.edit);
  }, [view, editorRef, diffEditorRef]);

  const handleReview = useCallback(async () => {
    if (monaco && editorRef.current && diffEditorRef.current) {
      // Error if there are any validation errors in the editor state
      const markers = monaco?.editor
        .getModelMarkers({ owner: 'json' })
        .filter(m => m.severity === MarkerSeverity.Error);
      if ((markers?.length || 0) > 0) {
        errorApi.post({
          message:
            'Cannot commit manifest with errors. Please fix them first or cancel.',
          name: 'ValidationError',
        });
        return;
      }

      // Reconcile the edited config with by applying all automatic changes
      const editedConfig = parseContent(editorRef.current.getValue());
      if (_.isEqual(editedConfig, manifest)) {
        alertApi.post({
          message: 'Manifest does not contain changes.',
          severity: 'info',
          display: 'transient',
        });
        setView(State.view);
        return;
      }

      let handler: ManifestHandler<RegistryManifest>;
      try {
        // initalization will fail if this is not a valid manifest
        handler = ManifestHandler.fromManifest(_.cloneDeep(editedConfig));
      } catch (e) {
        errorApi.post({
          message: 'Manifest is not valid.',
          name: 'ValidationError',
        });
        setView(State.edit);
        return;
      }

      handler.reconcile();
      if (!_.isEqual(handler.manifest, editedConfig)) {
        alertApi.post({
          message: 'New capabilities have been added to the manifest.',
          severity: 'info',
          display: 'transient',
        });
      }

      try {
        const currentConfig = formatContent(manifest);
        const newConfig = formatContent(handler.manifest);
        diffEditorRef.current.getOriginalEditor().setValue(currentConfig);
        diffEditorRef.current.getModifiedEditor().setValue(newConfig);
        setView(State.review);
      } catch (e) {
        errorApi.post({
          message: 'Config is not valid JSON.',
          name: 'ValidationError',
        });
        setView(State.view);
      }
    }
  }, [
    editorRef,
    diffEditorRef,
    monaco,
    alertApi,
    errorApi,
    setView,
    parseContent,
    formatContent,
    manifest,
  ]);

  const handleCommit = useCallback(() => {
    if (editorRef.current && diffEditorRef.current) {
      update.mutate(
        JSON.parse(diffEditorRef.current.getModifiedEditor().getValue()),
        {
          onSettled() {
            setView(State.view);
          },
        },
      );
    }
  }, [editorRef, diffEditorRef, update, setView]);

  const handleCancel = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.setValue(formatContent(manifest));
      setView(State.view);
    }
  }, [editorRef, manifest, setView, formatContent]);

  const getEditorValue = useCallback(() => {
    if (editorRef.current) {
      return parseContent(editorRef.current.getValue()) as RegistryManifest;
    }
    return manifest;
  }, [editorRef, parseContent, manifest]);

  const updateEditorValue = useCallback(
    (data: RegistryManifest) => {
      if (editorRef.current) {
        editorRef.current.setValue(formatContent(data));
        if (view !== State.edit) {
          setView(State.edit);
        }
      }
    },
    [editorRef, formatContent, view],
  );

  useEffect(() => {
    const actions = {
      editManifest: {
        icon: <EditNoteIcon />,
        name: 'Edit manifest',
        action: handleEdit,
        disabled: update.isPending,
      },
      cancel: {
        icon: <CancelIcon />,
        name: 'Cancel',
        action: handleCancel,
        disabled: update.isPending,
      },
      review: {
        icon: <ChecklistIcon />,
        name: 'Review changes',
        action: handleReview,
        disabled: update.isPending,
      },
      commit: {
        icon: <CheckIcon />,
        name: 'Commit changes',
        action: handleCommit,
        disabled: update.isPending,
      },
      queue: {
        icon: <PlayArrowIcon />,
        name: 'Queue build',
        action: () => queue.mutate(),
        disabled: queue.isPending || update.isPending,
      },
      manage: {
        icon: <SettingsIcon />,
        name: 'Manage manifest',
        action: openDialog,
        disabled: update.isPending,
      },
    };
    const btns = [];
    switch (view) {
      case State.view:
        btns.push(actions.editManifest);
        btns.push(actions.manage);
        btns.push(actions.queue);
        break;
      case State.edit:
        btns.push(actions.cancel);
        btns.push(actions.manage);
        btns.push(actions.review);
        break;
      case State.review:
        btns.push(actions.cancel);
        btns.push(actions.editManifest);
        btns.push(actions.commit);
        break;
      default:
        break;
    }

    // @ts-expect-error - keeping this around to evaluate which we like best.
    const fabButtons = (
      <Stack
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        direction="row"
        spacing={2}
      >
        {btns.map(btn => (
          <Tooltip key={btn.name} title={btn.name} placement="top">
            <Fab
              color="primary"
              aria-label={btn.name}
              onClick={btn.action}
              disabled={btn.disabled}
            >
              {btn.icon}
            </Fab>
          </Tooltip>
        ))}
      </Stack>
    );

    const dialButtons = (
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        {btns.map(btn => (
          <SpeedDialAction
            key={btn.name}
            icon={btn.icon}
            tooltipTitle={btn.name}
            onClick={btn.action}
            FabProps={{ disabled: btn.disabled }}
          />
        ))}
      </SpeedDial>
    );

    setEditorActions(dialButtons);
  }, [
    setEditorActions,
    handleEdit,
    handleReview,
    handleCancel,
    handleCommit,
    queue,
    update,
    view,
    openDialog,
  ]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setValue(formatContent(manifest));
    }
  }, [manifest, editorRef, formatContent]);

  return {
    view,
    handleBeforeMount,
    handleOnMount,
    handleDiffEditorOnMount,
    editorActions,
    formatContent,
    updateEditorValue,
    getEditorValue,
  };
}

function useEditors() {
  const editorRef = useRef<me.IStandaloneCodeEditor | null>(null);
  const diffEditorRef = useRef<MonacoDiffEditor | null>(null);
  const currTheme = useTheme();
  const muiTheme = useMuiTheme();

  const [dataFormat, setDataFormat] = useState('json');

  const formatContent = useCallback(
    (content: any) =>
      dataFormat === 'json'
        ? JSON.stringify(content, undefined, 2)
        : yaml.stringify(content, null, { indent: 2, indentSeq: true }),
    [dataFormat],
  );

  const parseContent = useCallback(
    (content: string) =>
      dataFormat === 'json' ? JSON.parse(content) : yaml.parse(content),
    [dataFormat],
  );

  const handleBeforeMount: BeforeMount = useCallback(
    monaco_ => {
      setMonacoDefaults(monaco_, muiTheme.palette.background.paper);
    },
    [muiTheme],
  );

  const handleOnMount: OnMount = useCallback(
    (editor, monaco) => {
      editorRef.current = editor;
      monaco.editor.setTheme(
        currTheme === KEY_DARK_THEME ? 'dark-theme' : 'light',
      );
    },
    [editorRef, currTheme],
  );

  const handleDiffEditorOnMount: DiffOnMount = useCallback(
    (diffEditor, _monaco) => {
      diffEditorRef.current = diffEditor;
    },
    [diffEditorRef],
  );

  return {
    editorRef,
    diffEditorRef,
    dataFormat,
    setDataFormat,
    formatContent,
    parseContent,
    handleBeforeMount,
    handleOnMount,
    handleDiffEditorOnMount,
  };
}

function setMonacoDefaults(monaco: Monaco, editorBgColor: string): void {
  monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
    validate: true,
    schemaValidation: 'error',
    enableSchemaRequest: true,
    schemaRequest: 'error',
    schemas: [
      {
        uri: `${window.location.origin}/registry.schema.json`,
        schema: registrySchema,
        fileMatch: ['*'],
      },
    ],
  });

  monaco.editor.defineTheme('dark-theme', {
    base: 'vs-dark',
    inherit: true,
    rules: [],
    colors: {
      // You can find a comprehensive list of other color variables to override here:
      // https://microsoft.github.io/monaco-editor/playground.html?source=v0.46.0#example-customizing-the-appearence-exposed-colors
      'editor.background': editorBgColor,
    },
  });
}
