import { MetricsApi } from '@internal/plugin-metrics-common';
import { createApiRef } from '@backstage/core-plugin-api';

/**
 * The API reference for the {@link @internal/metrics-common#MetricsApi}.
 * @public
 */
export const metricsApiRef = createApiRef<MetricsApi>({
  id: 'plugin.metrics.service',
});
