import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  FieldExtensionComponentProps,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder-react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldValidation } from '@rjsf/utils';
import React from 'react';
import { AzureUserSearch } from '../components/AzureSearch';
import { MsGraphFormData } from '../components/AzureSearch/types';

const AzureUserSearchForm = (
  props: FieldExtensionComponentProps<MsGraphFormData>,
) => {
  const {
    onChange,
    schema: { title = 'Azure AD User', description = 'A user from Azure AD.' },
    rawErrors,
    required,
    formData,
  } = props;

  return (
    <FormControl
      margin="normal"
      required={required}
      error={(rawErrors?.length || 0) > 0 && !formData}
    >
      <AzureUserSearch
        title={title}
        onChange={onChange}
        required={required || false}
        formData={formData}
      />
      <FormHelperText id="entityName">{description}</FormHelperText>
    </FormControl>
  );
};

const azureUserSearchExtensionValidation = (
  value: MsGraphFormData,
  validation: FieldValidation,
) => {
  const uuid4 =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const validUUID = uuid4.test(value.id!);

  if (validUUID === false) {
    validation.addError(`Please select a valid Azure AD user.`);
  }
};

export const AzureUserSearchExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureUserSearch',
    component: AzureUserSearchForm,
    validation: azureUserSearchExtensionValidation,
  }),
);
