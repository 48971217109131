import {
  Entity,
  getCompoundEntityRef,
  parseEntityRef,
} from '@backstage/catalog-model';
import { ConflictError } from '@backstage/errors';
import yaml from 'yaml';
import { Annotations, Labels, WorkspaceInfo, ProductInfo } from './models';
import {
  CatalogOutput,
  RegistryManifest,
  isCatalogEntry,
  isRegistryManifest,
  isWorkspaceEntry,
  isProductEntry,
  ProductEntity,
  WorkspaceEntity,
  workspaceEntityRef,
} from './models';

export function parseRegistryManifest(data: string): RegistryManifest {
  const parsed = yaml.parse(data);
  if (!isRegistryManifest(parsed)) {
    throw new ConflictError('Invalid registry manifest');
  }
  return pruneManifest(parsed);
}

export function parseCatalogOutput(data: string): CatalogOutput {
  const parsed = JSON.parse(data);
  if (!isCatalogEntry(parsed)) {
    throw new ConflictError('Invalid catalog output');
  }
  if (isProductEntry(parsed) || isWorkspaceEntry(parsed)) {
    parsed.input = pruneManifest(parsed.input);
  }
  return parsed;
}

export function pruneManifest(manifest: RegistryManifest): RegistryManifest {
  if (!isRegistryManifest(manifest)) {
    throw new ConflictError('Invalid registry manifest');
  }
  return manifest;
}

export function pruneName(name: string): string {
  return name.toLowerCase().replace(/[^a-z0-9-]/g, '-');
}

export function getPipelineId(entity: Entity): number | undefined {
  if (Annotations.AdoPipelineId in (entity.metadata.annotations || {})) {
    return Number(entity.metadata.annotations![Annotations.AdoPipelineId]);
  }
  return undefined;
}

export function getPipelineName(entity: Entity): string | undefined {
  if (Annotations.AzureBuild in (entity.metadata.annotations || {})) {
    return entity.metadata.annotations![Annotations.AzureBuild];
  }
  return undefined;
}

export function getConfigLocation(
  entity: Entity,
): { path: string; repository: string } | undefined {
  if (Annotations.LinkConfig in (entity.metadata.annotations || {})) {
    const locationUrl = entity.metadata.annotations![Annotations.LinkConfig];
    const url = new URL(locationUrl);
    const segments = url.pathname.split('/');
    const path = url.searchParams.get('path');
    if (path)
      return {
        repository: segments[segments.length - 1],
        path,
      };
  }
  return undefined;
}

export function getWorkspaceInfo(entity: WorkspaceEntity): WorkspaceInfo {
  const configLocation = getConfigLocation(entity);

  const ownerRef = entity.spec?.owner
    ? parseEntityRef(entity.spec?.owner.toString())
    : undefined;

  return {
    name: entity.metadata.name,
    title: entity.metadata.title ?? entity.metadata.name,
    description: entity.metadata.description,
    entityRef: getCompoundEntityRef(entity),
    configLocation,
    ownerRef,
  };
}

export function getProductInfo(entity: ProductEntity): ProductInfo {
  const configLocation = getConfigLocation(entity);
  const ownerRef = entity.spec?.owner
    ? parseEntityRef(entity.spec?.owner.toString())
    : undefined;
  const workspaceName = entity.metadata.labels[Labels.Workspace];
  const workspace = parseEntityRef(workspaceEntityRef(workspaceName));

  return {
    name: entity.metadata.name,
    title: entity.metadata.title ?? entity.metadata.name,
    description: entity.metadata.description,
    entityRef: getCompoundEntityRef(entity),
    workspace,
    configLocation,
    ownerRef,
  };
}
