import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import crossFetch from 'cross-fetch';

export interface RequestOptions {
  token?: string;
}

/**
 * Base client to handle API discovery and authorization.
 *
 * @public
 */
export class BaseClient {
  private readonly pluginId: string;
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    pluginId: string;
    discoveryApi: { getBaseUrl(pluginId: string): Promise<string> };
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.pluginId = options.pluginId;
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }

  async requestRequired<T = any>(
    method: string,
    path: string,
    options?: RequestOptions,
    body?: BodyInit,
  ): Promise<T> {
    const url = `${await this.discoveryApi.getBaseUrl(this.pluginId)}${path}`;
    const headers: Record<string, string> = options?.token
      ? { Authorization: `Bearer ${options.token}` }
      : {};
    if (body) {
      headers['Content-Type'] = 'application/json';
    }
    const response = await this.fetchApi.fetch(url, { method, headers, body });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async requestOptional<T = any>(
    method: string,
    path: string,
    options?: RequestOptions,
    body?: BodyInit,
  ): Promise<T | undefined> {
    const url = `${await this.discoveryApi.getBaseUrl(this.pluginId)}${path}`;
    const headers: Record<string, string> = options?.token
      ? { Authorization: `Bearer ${options.token}` }
      : {};
    if (body) {
      headers['Content-Type'] = 'application/json';
    }
    const response = await this.fetchApi.fetch(url, { method, headers, body });

    if (!response.ok) {
      if (response.status === 404) {
        return undefined;
      }
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }
}
