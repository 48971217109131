import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAzurePipelinesContent,
  isAzurePipelinesAvailable,
} from '@backstage-community/plugin-azure-devops';
import {
  EntityAboutCard,
  EntityDependencyOfComponentsCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasSubcomponentsCard,
  EntityLayout,
  EntityLinksCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntityRelationWarning,
  EntitySwitch,
  hasCatalogProcessingErrors,
  hasRelationWarnings,
  isComponentType,
  isOrphan,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityKubernetesContent,
  isKubernetesAvailable,
} from '@backstage/plugin-kubernetes';
import {
  EntityTechdocsContent,
  isTechDocsAvailable,
} from '@backstage/plugin-techdocs';
import {
  LightBox,
  ReportIssue,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { EntityTodoContent } from '@backstage-community/plugin-todo';
import Grid from '@mui/material/Grid';
import { RequireOwnership } from '@provisioning/react';
import React from 'react';
import { hasLinks } from './util';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { isProductEntity, isWorkspaceEntity } from '@provisioning/common';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage-community/plugin-github-actions';

export const dependencies = (
  <EntityLayout.Route path="/dependencies" title="Dependencies">
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={6} xs={12}>
        <EntityDependsOnComponentsCard variant="gridItem" />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityDependsOnResourcesCard variant="gridItem" />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityDependencyOfComponentsCard variant="gridItem" />
      </Grid>
    </Grid>
  </EntityLayout.Route>
);

export const api = (
  <EntityLayout.Route if={isComponentType('service')} path="/api" title="API">
    <Grid container spacing={3} alignItems="stretch">
      <Grid item md={6} xs={12}>
        <EntityProvidedApisCard />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityConsumedApisCard />
      </Grid>
    </Grid>
  </EntityLayout.Route>
);

export const warning = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

export const pipelines = (
  <EntityLayout.Route
    if={isAzurePipelinesAvailable}
    path="/pipelines"
    title="Pipelines"
  >
    <RequireOwnership>
      <EntityAzurePipelinesContent defaultLimit={25} />
    </RequireOwnership>
  </EntityLayout.Route>
);

export const k8s = (
  <EntityLayout.Route
    if={isKubernetesAvailable}
    path="/kubernetes"
    title="Kubernetes"
  >
    <RequireOwnership>
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </RequireOwnership>
  </EntityLayout.Route>
);

export const docs = (
  <>
    <EntityLayout.Route if={isTechDocsAvailable} path="/docs" title="Docs">
      <EntityTechdocsContent>
        <TechDocsAddons>
          <ReportIssue />
          <LightBox />
          <TextSize />
          <Mermaid
            config={{
              theme: 'forest',
              themeVariables: { lineColor: '#000000' },
            }}
          />
        </TechDocsAddons>
      </EntityTechdocsContent>
    </EntityLayout.Route>
    <EntityLayout.Route
      if={entity => {
        return !isTechDocsAvailable(entity) && isProductEntity(entity);
      }}
      path="/docs"
      title="Docs"
    >
      <Button
        className="backstage-btn"
        component={Link}
        to="/templates/templates/argus/product-techdocs"
      >
        Add Documentation
      </Button>
    </EntityLayout.Route>
    <EntityLayout.Route
      if={entity => {
        return !isTechDocsAvailable(entity) && isWorkspaceEntity(entity);
      }}
      path="/docs"
      title="Docs"
    >
      <Button
        className="backstage-btn"
        component={Link}
        to="/templates/templates/argus/workspace-techdocs"
      >
        Add Documentation
      </Button>
    </EntityLayout.Route>
  </>
);

export const adrs = (
  <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
    <EntityAdrContent />
  </EntityLayout.Route>
);

export const todo = (
  <EntityLayout.Route path="/todo" title="Todo">
    <EntityTodoContent />
  </EntityLayout.Route>
);

export const githubActions = (
  <EntityLayout.Route
    if={isGithubActionsAvailable}
    path="/github-actions"
    title="GitHub Actions"
  >
    <EntityGithubActionsContent />
  </EntityLayout.Route>
);

export const overview = (
  <EntityLayout.Route path="/" title="Overview">
    <Grid container spacing={3} alignItems="stretch">
      {warning}
      <Grid item md={6} xs={12}>
        <EntityAboutCard variant="gridItem" />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityCatalogGraphCard variant="gridItem" height={400} />
      </Grid>
      <Grid item md={6} xs={12}>
        <EntityHasSubcomponentsCard variant="gridItem" />
      </Grid>
      <EntitySwitch>
        <EntitySwitch.Case if={hasLinks}>
          <Grid item md={6} xs={12}>
            <EntityLinksCard />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>
  </EntityLayout.Route>
);
