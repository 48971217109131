import { GroupEntity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { provisioningCatalogApiRef } from '../api';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

interface UseCatalogGroupProps {
  groupId: string;
}

interface UseCatalogGroupOutput {
  data: { group?: GroupEntity };
  error: boolean;
}

export function useCatalogGroup({
  groupId,
}: UseCatalogGroupProps): UseCatalogGroupOutput {
  const catalogApi = useApi(provisioningCatalogApiRef);

  const queryFn = useCallback(async () => {
    if (!groupId) return {};
    return catalogApi.getGroup({ groupId });
  }, [catalogApi, groupId]);

  const query = useSuspenseQuery({
    queryKey: ['group', groupId],
    queryFn,
    refetchInterval: 10000,
  });

  return {
    data: query.data,
    error: query.isError,
  };
}
