import { useApi } from '@backstage/core-plugin-api';
import { provisioningRegistryApiRef } from '../../api';
import React from 'react';
import { AADSearch } from './AADSearch';
import { MsGraphFormData } from './types';

export const AzureUserSearch = ({
  title,
  onChange,
  required,
  formData,
}: {
  title: string;
  onChange: Function;
  required: boolean;
  formData: MsGraphFormData | undefined;
}) => {
  const provisioningApi = useApi(provisioningRegistryApiRef);

  const fetchData = (input: string | undefined) =>
    provisioningApi.getMsgraphUsers(
      input
        ? {
            search: `"displayName:${input}"`,
          }
        : {},
    );

  return (
    <AADSearch
      fetchData={fetchData}
      title={title}
      onChange={onChange}
      required={required}
      formData={formData}
    />
  );
};
