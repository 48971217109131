import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  FieldExtensionComponentProps,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder-react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { FieldValidation } from '@rjsf/utils';
import React from 'react';
import { AzureGroupSearch } from '../components/AzureSearch';
import { MsGraphFormData } from '../components/AzureSearch/types';

const uuid4 =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const AzureGroupSearchForm = (
  props:
    | FieldExtensionComponentProps<MsGraphFormData>
    | FieldExtensionComponentProps<MsGraphFormData[]>,
) => {
  const {
    onChange,
    schema: {
      title = 'Azure AD Group',
      description = 'A group from Azure AD.',
    },
    rawErrors,
    required,
    formData,
    multiple = false,
  } = props;
  return (
    <FormControl
      margin="normal"
      required={required}
      error={(rawErrors?.length || 0) > 0 && !formData}
    >
      <AzureGroupSearch
        title={title}
        onChange={onChange}
        required={required || false}
        formData={formData}
        multiple={multiple}
      />
      <FormHelperText id="entityName">{description}</FormHelperText>
    </FormControl>
  );
};

const AzureGroupSearchMultipleForm = (
  props: FieldExtensionComponentProps<MsGraphFormData[]>,
  // eslint-disable-next-line
) => AzureGroupSearchForm({ ...props, multiple: true });

const azureGroupSearchExtensionValidation = (
  value: MsGraphFormData,
  validation: FieldValidation,
) => {
  const validUUID = uuid4.test(value.id!);

  if (validUUID === false) {
    validation.addError(`Please select a valid Azure AD group.`);
  }
};

const azureGroupSearchMultipleExtensionValidation = (
  value: MsGraphFormData[],
  validation: FieldValidation,
) => {
  const validUUIDs = value.every(val => uuid4.test(val.id!));

  if (validUUIDs === false) {
    const errors = value.filter(val => !uuid4.test(val.id!)) ?? [];
    const errorNames: string = errors.map(e => e.name).toString();
    validation.addError(
      `These selected options are not valid Azure AD group(s): ${errorNames}`,
    );
  }
};

export const AzureGroupSearchExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureGroupSearch',
    component: AzureGroupSearchForm,
    validation: azureGroupSearchExtensionValidation,
  }),
);

export const AzureGroupSearchMultipleExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureGroupSearchMultiple',
    component: AzureGroupSearchMultipleForm,
    validation: azureGroupSearchMultipleExtensionValidation,
  }),
);
